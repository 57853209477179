import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Switch from 'react-switch';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  ClassroomCombineTypes,
  CourseFormInitialValues,
  ExamPeriodCourseTermLectureLocations,
  Grades,
  ExamTypes,
  ExamEnvironments,
  ExamOverlapTypes,
  InvigilatorStatuses,
} from '../../store/constants/course-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import { CombineExamFormValidation } from './validations/combine-exams-form-val';
import Translator from '../../services/translate-factory';
import { UncontrolledTooltip } from 'reactstrap';
import MultiSelectionTable from './components/multi-selection-table';
import { actions } from '../../store/constants/general';
import { SectionTypes } from '../../store/constants/enums';
import { filterClassroomTypesForEventModuleStatus } from '../../util/sort';
import { localizeSelectOptions } from '../../util/language';
import { ClassroomTypes } from '../../store/constants/setting-const';

const T = Translator.create();
const equal = require('deep-equal');
const Logger = Log.create('CombineExamsModal');

let multiSelectedRow: boolean = false;
let saveButtonClicked: boolean = false;
let combined_values_selected: Array<any> = new Array<any>();

class CombineExamsModal extends Component<Types.ICombineModalProps, Types.ICombineExamsDataModel> {
  state: Types.ICombineExamsDataModel = {
    // dataModel: Object.assign({}, CombineExamsDataModel),
    term_id: -1,
    is_student_course_overlap_exist: undefined,
    approval_overlap: false,
    combined_courses: [],
    formValues: {
      course_code: '',
      conjugate_course_code: '',
      name: '',
      student_count: -1,
      lecture_staff: [],
      lecture_staff_ids: [],
      assistant_staff: [],
      assistant_staff_ids: [],
      programs: [],
      program_ids: [],
      campus: undefined,
      campus_id: 0,
      faculty: undefined,
      faculty_id: 0,
      exam_duration: undefined,
      class: undefined,
      section: '',
      exam_session: undefined,
      invigilator_gap_before_exam: 0,
      invigilator_gap_after_exam: 0,
      exam_environment_select: undefined,
      exam_environment: 0,
      exam_overlap_type_select: undefined,
      exam_overlap_type: 0,
      exam_type_select: undefined,
      exam_type: 0,
      classroom_combine_status: -1,
      invigilator_status_select: undefined,
      invigilator_status: 1,
      description: ''
    },
    isAdded: false,
    alreadyInTheList: false
  };
  componentRef: any;

  constructor(props: any) {
    super(props);
    this.componentRef = React.createRef();
  }

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/);
    let id = regexObject ? parseInt(regexObject[0], 10) : -1;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.term_id = id;
    this.checkStudentCourseOverlaps();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    combined_values_selected && combined_values_selected.splice(0, combined_values_selected.length);
  }

  checkStudentCourseOverlaps() {
    const model = {
      termId: this.state.term_id,
      course_ids: this.props.courseList ? this.props.courseList.map((obj: any) => obj.item.course_id) : []
    };
    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_COURSE_OVERLAP_STUDENTS, model, 'ep-course-list',
        (result: boolean, status: number) => {
          if (status === 200 || status === 201) {
            this.state.is_student_course_overlap_exist = result;
            this.setState(this.state);
          } else {
            this.setCloseModal();
          }
        })
    );
  }

  putToTable = (values: any, plusButtonClicked: boolean) => {
    values.section = values.section == '' ? '1' : values.section;

    combined_values_selected = values.faculty_ids && values.faculty_ids.map((element: any, index: number) => (
      {
        faculty_id: values.faculty_ids[index],
        faculty: values.faculties[index],
        program_id: values.program_ids[index],
        program: values.programs[index],
        class_id: values.class_ids[index],
        grade: values.classes[index],
        section: values.sections[index],
        instructor_id: values.instructor_ids[index],
        instructor: values.instructors[index],
        student_count: values.student_counts[index],
        index: [...Array(values.faculty_ids && values.faculty_ids.length)].map((element: any, index: number) => index + 1)[index],
      }));

    this.setState({
      isAdded: true
    })
    this.state.isAdded = true;

    plusButtonClicked = !plusButtonClicked;
  };

  multiSelecteds(
    combined_values: any
  ) {
    multiSelectedRow = true;
    combined_values_selected = combined_values
  }

  createFieldValues = (): Types.ICopyCourseFormValues => {
    let fields: Types.ICopyCourseFormValues = {
      course_code: '',
      conjugate_course_code: '',
      name: '',
      student_counts: [],
      instructor_ids: [],
      assistant_staff: [],
      assistant_staff_ids: [],
      programs: [],
      program_ids: [],
      campus: undefined,
      campus_id: 0,
      faculty: undefined,
      exam_duration: undefined,
      class: undefined,
      section: '',
      section_ids: [],
      sections: [],
      exam_session: undefined,
      description: '',
      instructor_id: 0,
      class_id: 0,
      program_id: 0,
      faculty_id: 0,
    };

    if (this.props.courseObject && this.props.courseObject.courseInfo) {
      let courseInfo: Types.ICourseItem = this.props.courseObject.courseInfo;

      const _student_count = courseInfo.student_counts ? courseInfo.student_counts : [];
      const student_count = _student_count.reduce((a, b) => a + b, 0);

      fields = {
        course_code: courseInfo.course_code ? courseInfo.course_code : '',
        conjugate_course_code: courseInfo.conjugate_course_code ? courseInfo.conjugate_course_code : '',
        name: courseInfo.name ? courseInfo.name : '',
        campus: courseInfo.campuses && courseInfo.campuses.every((val, i, arr) => val.value === arr[0].value) ? courseInfo.campuses[0] : undefined,
        campus_id: courseInfo.campuses && courseInfo.campuses.every((val, i, arr) => val.value === arr[0].value) ? courseInfo.campuses[0].value : undefined,
        faculties: courseInfo.faculties ? courseInfo.faculties : undefined,
        faculty_ids: courseInfo.faculty_ids ? courseInfo.faculty_ids : undefined,
        student_count_total: student_count,
        student_counts: courseInfo && courseInfo.student_counts ? courseInfo.student_counts : [],
        invigilator_gap_before_exam: courseInfo.invigilator_gap_before_exam ? courseInfo.invigilator_gap_before_exam : 0,
        invigilator_gap_after_exam: courseInfo.invigilator_gap_after_exam ? courseInfo.invigilator_gap_after_exam : 0,
        instructors: courseInfo.instructors ? courseInfo.instructors : [],
        instructor_ids: courseInfo.instructor_ids ? courseInfo.instructor_ids : [],
        assistant_staff: courseInfo.assistant_staff ? courseInfo.assistant_staff : [],
        assistant_staff_ids: courseInfo.assistant_staff_ids ? courseInfo.assistant_staff_ids : [],
        programs: courseInfo.programs ? courseInfo.programs : [],
        program_ids: courseInfo.program_ids ? courseInfo.program_ids : [],
        class_ids: courseInfo && courseInfo.class_ids ? courseInfo.class_ids : [],
        classes: courseInfo.classes ? courseInfo.classes : undefined,
        sections: courseInfo.sections ? courseInfo.sections : undefined,
        exam_duration: courseInfo.exam_duration ? courseInfo.exam_duration : undefined,
        exam_session: courseInfo.exam_session ? courseInfo.exam_session : undefined,
        exam_environment: courseInfo.exam_environment,
        exam_overlap_type: courseInfo.exam_overlap_type,
        exam_type: courseInfo.exam_type,
        classroom_combine_status: courseInfo.classroom_combine_status,
        invigilator_status: courseInfo.invigilator_status,
        description: courseInfo.description ? courseInfo.description : undefined,
      };
    }
    return fields;
  };

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  showStudentCourseOverlapInfoModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_download_student_course_overlap_results_title'),
          body: T.t('gen_download_student_course_overlap_results_body'),
          name: 'download_student_course_overlap_results',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const model: { termId: number; sectionType: number; parameters?: Array<string> } = {
              termId: this.state.term_id,
              sectionType: SectionTypes.StudentCourseOverlaps,
              parameters: this.props.courseList ? this.props.courseList.map((obj: any) => obj.item.course_id) : [],
            };

            this.props.dispatch(Actions.ApiRequest(Constants.building.DOWNLOAD_ALL_DATA, model, ''));
          }
        })
      );
    }
  };

  onFormSave = (model: Types.ICourseItem, FormActions: FormikActions<Types.ICourseItem>, warningCourseCode?: boolean) => {
    if (this.state.is_student_course_overlap_exist == undefined || ((this.state.is_student_course_overlap_exist && !this.state.approval_overlap))) {
      saveButtonClicked = false;
      FormActions.setSubmitting(false);
      return;
    }

    if (saveButtonClicked) {
      let combinedCoursesArray: any = this.props.courseList && this.props.courseList.map(
        (obj: any) => obj.item.course_id
      );

      let course_programs: any;
      course_programs = combined_values_selected.map((item: any, index: any) => (
        {
          faculty_id: item.faculty_id,
          campus_id: model.campus_id,
          program_id: item.program_id,
          class: item.class_id,
          section: item.section,
          instructor_id: item.instructor_id,
          student_count: item.student_count
        }));

      if (combined_values_selected == undefined || combined_values_selected.length == 0) {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t("gen_cannot_leave_empty"),
            body: T.t("gen_list_isnot_left_blank"),
            name: 'combining_failed',
            icon: 'error_outline',
            iconColor: 'red'
          }));
      } else {
        if (multiSelectedRow) {
          this.state.formValues.course_programs = course_programs;
          this.state.combined_courses = combinedCoursesArray;
          this.state.formValues.course_code = model.course_code;
          this.state.formValues.name = model.name;
          this.state.formValues.conjugate_course_code = model.conjugate_course_code;
          this.state.formValues.exam_duration = model.exam_duration ? model.exam_duration : 1;
          this.state.formValues.faculty_id = model.faculty_id ? model.faculty_id : -1;
          this.state.formValues.campus_id = model.campus_id ? model.campus_id : -1;
          this.state.formValues.class = model.class;
          this.state.formValues.section = model.section;
          this.state.formValues.lecture_staff_ids = model.lecture_staff ? model.lecture_staff.map((a) => a.value) : [];
          this.state.formValues.assistant_staff_ids = model.assistant_staff ? model.assistant_staff.map((a) => a.value) : [];
          this.state.formValues.exam_environment = model.exam_environment ? model.exam_environment : -1;
          this.state.formValues.course_language = model.course_language_select ? model.course_language_select.value : -1;
          this.state.formValues.classroom_type = model.classroom_type_select ? model.classroom_type_select.value : -1;
          this.state.formValues.exam_overlap_type = model.exam_overlap_type ? model.exam_overlap_type : 0;
          this.state.formValues.exam_type = model.exam_type ? model.exam_type : -1;
          this.state.formValues.classroom_combine_status = model.classroom_combine_status;
          this.state.formValues.invigilator_status = model.invigilator_status;
          this.state.formValues.exam_session = model.exam_session;
          this.state.formValues.invigilator_gap_before_exam = model.invigilator_gap_before_exam;
          this.state.formValues.invigilator_gap_after_exam = model.invigilator_gap_after_exam;
          this.state.formValues.description = model.description;

          multiSelectedRow = false;
        }

        let warningBody = '';
        if (warningCourseCode) warningBody += '_course_code';

        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_exams_will_be_combined'),
            body: T.t('gen_exams_combine_question' + warningBody),
            name: 'examPeriod_courses_delete',
            icon: 'info',
            iconColor: 'blue',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_cancel'),
            onConfirm: () => {
              const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
                if (result && result.code) {
                  let errors: any = {};
                  if (result.details) {
                    const validations: Array<Types.IValidationResponse> = result.details;
                    validations.forEach((m: Types.IValidationResponse) => {
                      errors[m.field] = m.message[0];
                    });
                  }
                }
                if (status === 200 || status === 201) {
                  this.setCloseModal();
                  this.onUpdateListFromModal();
                }
                else if (status === 406) {
                  this.props.dispatch(
                    Actions.ShowModal({
                      title: T.t('gen_error'),
                      body: result,
                      name: 'combining_failed',
                      icon: 'warning',
                      iconColor: 'red',
                      cancel: T.t('gen_ok'),
                    })
                  );
                  this.setCloseModal();
                  this.onUpdateListFromModal();
                }
              };

              this.state.course_id = this.props.courseObject ? this.props.courseObject.course_id : -1;

              if (this.props.showCoursesOnly) {
                this.props.dispatch(
                  Actions.ApiRequest(
                    Constants.exam_period.EXAM_PERIOD_UPDATE_COMBINE_EXAMS,
                    this.state,
                    'ep-course-list',
                    resultCallback
                  )
                );
              } else {
                this.props.dispatch(
                  Actions.ApiRequest(
                    Constants.exam_period.EXAM_PERIOD_CREATE_COMBINE_EXAMS,
                    this.state,
                    'ep-course-list',
                    resultCallback
                  )
                );
              }
            },
            onCancel: () => {
              this.setCloseModal();
              this.onUpdateListFromModal();
              window.location.reload();
            }
          })
        );

        saveButtonClicked = false;
      }
    }
  };

  static getDerivedStateFromProps(props: Types.ICombineModalProps, state: Types.ICoursePageState) {
    let hasNewState: boolean = false;

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  getCombinedExamsFieldsIfEqual = (): Types.ICombineExamsFormFields => {
    let fields: Types.ICombineExamsFormFields = Object.assign({}, this.state.formValues);
    if (this.props.courseList && this.props.courseList.length) {
      const faculty_ids_selected = this.props.courseList.map((course: any) => course.item.courseInfo.faculty_ids);

      let course_codes: Array<any> = [];
      let names: Array<any> = [];
      let descriptions: Array<any> = [];
      faculty_ids_selected.forEach((facultyIds: any, index_ids: any) => {
        facultyIds.forEach((facultyId: any, index_id: any) => {
          course_codes.push(this.props.courseList && this.props.courseList[index_ids].item.courseInfo.course_code.includes(';') ?
            this.props.courseList && this.props.courseList[index_ids].item.courseInfo.course_code.split(';')[index_id] :
            this.props.courseList && this.props.courseList[index_ids].item.courseInfo.course_code);

          names.push(this.props.courseList && this.props.courseList[index_ids].item.courseInfo.name.includes(';') ?
            this.props.courseList && this.props.courseList[index_ids].item.courseInfo.name.split(';')[index_id] :
            this.props.courseList && this.props.courseList[index_ids].item.courseInfo.name);

          if (this.props.courseList && this.props.courseList[index_ids].item.courseInfo.description != undefined) {
            descriptions.push(
              this.props.courseList && this.props.courseList[index_ids].item.courseInfo.description
            );
          }
        });
      });
      let warningCourseCode: boolean;
      let courseCodesDistinct = JSON.parse(JSON.stringify(course_codes));
      warningCourseCode = GT.DistinctTextArray(courseCodesDistinct).length > 1 ? true : false

      const course_code = course_codes.join(';');
      const name = names.join(';');
      const description = GT.isAllEqual(descriptions) ? descriptions[0] : GT.DistinctTextArray(descriptions).join(';');

      let conjugate_course_codes = this.props.courseList.map((course: any) => course.item.courseInfo.conjugate_course_code);
      for (let i = conjugate_course_codes.length; i >= 0; i--) {
        if (conjugate_course_codes[i] == undefined || conjugate_course_codes[i] == '') {
          conjugate_course_codes.splice(i, 1);
        }
      }
      let conjugate_course_code = !conjugate_course_codes.includes(undefined) || !conjugate_course_codes.includes('') || conjugate_course_codes.length != 1 ? conjugate_course_codes.join(';') : '';

      const _classes = this.props.courseList.map((course: any) => course.item.courseInfo.classes);
      const classes = _classes.flat();
      const class_ids = classes.map((staff) => staff.value);

      const _student_count_total = this.props.courseList.map((course: any) => course.item.courseInfo.student_counts);
      let studentCountsMerged: number[] = [];
      _student_count_total.forEach(element => {
        studentCountsMerged = studentCountsMerged.concat(element);
      });
      const student_count_total = studentCountsMerged.reduce((a, b) => a + b, 0);

      const _instructors = this.props.courseList.map((course: any) => course.item.courseInfo.instructors);
      const instructors = _instructors.flat();
      const instructor_ids = instructors.map((staff) => staff.value);

      let hasDifferentValues = (arr: any) =>
        !arr.every((item: any, i: any, ref: any) =>
          JSON.stringify([...ref].shift()).includes(JSON.stringify(item)));

      let campus_id;
      let campus;
      let courseLocationNew = this.props.courseList.map((i: any) => i.item.courseInfo.campus_ids).reduce(function (a, b) { return a.concat(b); }, []);
      if (hasDifferentValues(courseLocationNew)) {
        campus_id = -1;
        campus = {
          label: T.t("gen_multi_campus"),
          value: -1
        };
      } else {
        campus_id = this.props.courseList[0].item.courseInfo.campus_ids[0];
        campus = {
          label: this.props.courseList[0].item.courseInfo.campuses[0].label,
          value: this.props.courseList[0].item.courseInfo.campuses[0].value
        };
      }

      let exam_environment;
      let exam_environment_select;
      let examEnvironmentNew = this.props.courseList.map((i: any) => i.item.courseInfo.exam_environment)
      if (hasDifferentValues(examEnvironmentNew)) {
        exam_environment = null;
        exam_environment_select = undefined;
      } else {
        exam_environment = this.props.courseList[0].item.courseInfo.exam_environment;
        exam_environment_select = ExamEnvironments(T)[examEnvironmentNew[0] - 1];
      }

      let exam_overlap_type;
      let exam_overlap_type_select;
      let examOverlapTypeNew = this.props.courseList.map((i: any) => i.item.courseInfo.exam_overlap_type)
      if (hasDifferentValues(examOverlapTypeNew)) {
        exam_overlap_type = null;
        exam_overlap_type_select = undefined;
      } else {
        exam_overlap_type = this.props.courseList[0].item.courseInfo.exam_overlap_type;
        exam_overlap_type_select = ExamOverlapTypes(T)[examOverlapTypeNew[0] - 1];
      }

      let exam_type;
      let exam_type_select;
      let examTypeNew = this.props.courseList.map((i: any) => i.item.courseInfo.exam_type)
      if (hasDifferentValues(examTypeNew)) {
        exam_type = null;
        exam_type_select = undefined;
      } else {
        exam_type = this.props.courseList[0].item.courseInfo.exam_type;
        exam_type_select = ExamTypes(T)[examTypeNew[0] - 1];
      }

      const _faculties = this.props.courseList.map((course: any) => course.item.courseInfo.faculties);
      const faculties = _faculties.flat();
      const faculty_ids = faculties.map((faculty: any) => faculty && faculty.value);

      const _campuses = this.props.courseList.map((course: any) => course.item.courseInfo.campuses);
      const campuses = _campuses.flat();
      const campus_ids = campuses.map((campus: any) => campus && campus.value);

      const _programs = this.props.courseList.map((course: any) => course.item.courseInfo.programs);
      const programs = _programs.flat();
      const program_ids = programs.map((program: any) => program && program.value);

      const _sections = this.props.courseList.map((course: any) => course.item.courseInfo.sections);
      const sections = _sections.flat();

      const _student_counts = this.props.courseList.map((course: any) => course.item.courseInfo.student_counts);
      const student_counts = _student_counts.flat();

      const _assistant_staff = this.props.courseList.map((course: any) => course.item.courseInfo.assistant_staff);
      const flattedAssistantStaff = _assistant_staff[0] != undefined ? _assistant_staff.flat() : [];
      const assistant_staff = flattedAssistantStaff[0] != undefined ? flattedAssistantStaff.filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i) : [];
      const assistant_staff_ids = assistant_staff.map((staff) => staff.value);

      const _exam_duration = this.props.courseList.map((course: any) => course.item.courseInfo.exam_duration);
      const exam_duration = Math.max(..._exam_duration);

      const _exam_session = this.props.courseList.map((course: any) => course.item.courseInfo.exam_session);
      const exam_session = Math.max(..._exam_session);

      let invigilator_gap_before_exam;
      let invigilator_gap_before_exams = this.props.courseList.map((i: any) => i.item.courseInfo.invigilator_gap_before_exam)
      if (hasDifferentValues(invigilator_gap_before_exams)) {
        invigilator_gap_before_exam = 0;
      } else {
        invigilator_gap_before_exam = this.props.courseList[0].item.courseInfo.invigilator_gap_before_exam;
      }

      let invigilator_gap_after_exam;
      let invigilator_gap_after_exams = this.props.courseList.map((i: any) => i.item.courseInfo.invigilator_gap_after_exam)
      if (hasDifferentValues(invigilator_gap_after_exams)) {
        invigilator_gap_after_exam = 0;
      } else {
        invigilator_gap_after_exam = this.props.courseList[0].item.courseInfo.invigilator_gap_before_exam;
      }

      let classroom_type;
      let classroom_type_select;
      let classroomTypeNew = this.props.courseList.map((i: any) => i.item.courseInfo.classroom_type)
      if (!classroomTypeNew.includes(undefined)) {
        if (hasDifferentValues(classroomTypeNew)) {
          classroom_type = null;
          classroom_type_select = undefined;
        } else {
          classroom_type = this.props.courseList[0].item.courseInfo.classroom_type;
          classroom_type_select = this.props.courseList[0].item.courseInfo.classroom_type_select;
        }
      }

      let course_language;
      let course_language_select;
      let courseLanguageNew = this.props.courseList.map((i: any) => i.item.courseInfo.course_language)
      if (!courseLanguageNew.includes(undefined)) {
        if (hasDifferentValues(courseLanguageNew)) {
          course_language = null;
          course_language_select = undefined;
        } else {
          course_language = this.props.courseList[0].item.courseInfo.course_language;
          course_language_select = this.props.courseList[0].item.courseInfo.course_language_select;
        }
      }

      let classroomCombineStatusNew = this.props.courseList.map((i: any) => i.item.courseInfo.classroom_combine_status)
      let classroomCombineStatusArray = [this.props.courseList[0].item.courseInfo.classroom_combine_status];
      const classroom_combine_status = ClassroomCombineTypes(T).find((item) => classroomCombineStatusNew.includes(item.value));

      let invigilator_status;
      let invigilator_status_select;
      let invigilator_statusNew = this.props.courseList.map((i: any) => i.item.courseInfo.invigilator_status)
      if (hasDifferentValues(invigilator_statusNew)) {
        invigilator_status = undefined;
        invigilator_status_select = undefined;
      } else {
        invigilator_status = this.props.courseList[0].item.courseInfo.invigilator_status;
        invigilator_status_select = InvigilatorStatuses(T).find((option) => option.value === invigilator_statusNew[0]);
      }

      fields = {
        course_code,
        name,
        conjugate_course_code,
        student_count_total,
        exam_duration,
        instructor_ids,
        instructors,
        assistant_staff_ids,
        assistant_staff,
        programs,
        program_ids,
        campus_id,
        campus,
        faculty_ids,
        faculties,
        campus_ids,
        campuses,
        classes,
        class_ids,
        sections,
        student_counts,
        exam_session,
        invigilator_gap_before_exam,
        invigilator_gap_after_exam,
        description,
        exam_environment: exam_environment ? exam_environment : undefined,
        exam_environment_select: exam_environment_select ? exam_environment_select : undefined,
        course_language,
        course_language_select,
        classroom_type,
        classroom_type_select,
        exam_overlap_type: exam_overlap_type ? exam_overlap_type : undefined,
        exam_overlap_type_select: exam_overlap_type_select ? exam_overlap_type_select : undefined,
        exam_type: exam_type ? exam_type : undefined,
        exam_type_select: exam_type_select ? exam_type_select : undefined,
        classroom_combine_status: classroom_combine_status ? classroom_combine_status.value : undefined,
        invigilator_status: invigilator_status,
        invigilator_status_select: invigilator_status_select,
        warningCourseCode
      };
    }
    return fields;
  };

  render() {
    if (!!!this.props.courseObject) {
      if (combined_values_selected.length == 0 || combined_values_selected == undefined) {
        this.putToTable(this.getCombinedExamsFieldsIfEqual(), false);
      }
    } else {
      if (combined_values_selected.length == 0 || combined_values_selected == undefined) {
        this.putToTable(this.createFieldValues(), false);
      }
    }

    let courseList = this.props.showCoursesOnly ? this.props.combinedDataList : this.props.courseList;
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const CampusSelectOptions = [...ExamPeriodCourseTermLectureLocations(T), ...campusOptions];

    let fields = this.getCombinedExamsFieldsIfEqual();
    let formInitialValues = Object.assign({}, CourseFormInitialValues, fields);

    const scrollToTop = () => {
      this.componentRef.current.scrollIntoView({ block: 'nearest' });
    }
    let classroomTypes: Types.ISelectOption[] = [];
    if (this.props.selectOptions) {
      classroomTypes = localizeSelectOptions(this.props.selectOptions.classroom_types_active, ClassroomTypes(T));
    }

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div ref={this.componentRef}></div>
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t("gen_exam_merge")}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div
                  className="white-container collapse show"
                  id="advance-search"
                  style={{ boxShadow: 'none' }}
                >
                  <Formik
                    initialValues={
                      this.props.showCoursesOnly
                        ? this.props.courseObject && this.props.courseObject.courseInfo
                          ? this.props.courseObject.courseInfo
                          : Object.assign({}, formInitialValues)
                        : Object.assign({}, formInitialValues)
                    }
                    enableReinitialize={true}
                    validationSchema={CombineExamFormValidation(T)}
                    onSubmit={(values, actions) => {
                      this.onFormSave(values, actions, fields.warningCourseCode);
                    }}
                  >
                    {(props: FormikProps<Types.ICourseItem>) => {
                      const { values, handleChange, errors, handleBlur, handleSubmit, isSubmitting } = props;
                      if (props.values.campuses && props.values.campuses.every((val, i, arr) => val.value === arr[0].value)) {
                        props.values.campus = props.values.campuses[0];
                        props.values.campus_id = props.values.campuses[0] && props.values.campuses[0].value;
                      }

                      return (
                        <form onSubmit={handleSubmit}>
                          {this.state.is_student_course_overlap_exist != undefined ?
                            <div className="row-highlighted">
                              <div className="col-md-3 form-input form-group with-icon mt-3">
                                <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
                                  {T.t("gen_students_course_overlap_status")}
                                </h6>
                              </div>
                              <div className="col-md-3 form-input form-group with-icon mt-3">
                                <td className="examp-compt-select">
                                  {!this.state.is_student_course_overlap_exist ? (
                                    <i className="material-icons" style={{ color: '#33c900c4' }}>
                                      check_circle
                                    </i>
                                  ) : (
                                    <i className="material-icons" style={{ color: '#ff0000c4' }}>
                                      cancel
                                    </i>
                                  )}
                                </td>
                              </div>
                              <div className="col-md-6 text-right mt-3">
                                {this.state.is_student_course_overlap_exist && !this.state.approval_overlap ? (
                                  <button className="category-tag-square tag-blue mr-2" onClick={this.showStudentCourseOverlapInfoModal}>
                                    <i className="material-icons mr-2" style={{ color: 'white' }}>cloud_download</i>
                                    <span>{T.t('gen_download_student_course_overlap_results')}</span>
                                  </button>
                                ) : null}
                              </div>
                            </div>
                            : null}
                          <div className="row">
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="course_code"
                                name="course_code"
                                value={values.course_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={true}
                                type="text"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="course_code">{T.t('gen_code')}</label>
                              {errors && errors.course_code && props.submitCount > 0 && (
                                <div className="error">{errors && errors.course_code}</div>
                              )}
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                disabled={true}
                                type="text"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="name">{T.t('gen_name')}</label>
                              {errors && errors.name && props.submitCount > 0 && (
                                <div className="error">{errors && errors.name}</div>
                              )}
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="conjugate_course_code"
                                name="conjugate_course_code"
                                value={values.conjugate_course_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="conjugate_course_code">{T.t('gen_conjugate_course_code')}</label>
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="student_count_total"
                                name="student_count_total"
                                value={values.student_count_total}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                disabled={true}
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="student_count_total">{T.t('gen_total_student_count')}</label>
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="exam_duration"
                                name="exam_duration"
                                value={values.exam_duration}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="exam_duration">{T.t('gen_exam_duration')}</label>
                              {errors && errors.exam_duration && props.submitCount > 0 && (
                                <div className="error">{errors && errors.exam_duration}</div>
                              )}
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="exam_session"
                                name="exam_session"
                                value={values.exam_session}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="exam_session">{T.t('gen_session_count')}</label>
                              {errors && errors.exam_session && props.submitCount > 0 && (
                                <div className="error">{errors && errors.exam_session}</div>
                              )}
                            </div>
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="invigilator_gap_before_exam"
                                name="invigilator_gap_before_exam"
                                value={values.invigilator_gap_before_exam}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="invigilator_gap_before_exam">{T.t('gen_invigilator_gap_before_exam')}</label>
                              {errors && errors.invigilator_gap_before_exam && props.submitCount > 0 && (
                                <div className="error">{errors && errors.invigilator_gap_before_exam}</div>
                              )}
                            </div>
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="invigilator_gap_after_exam"
                                name="invigilator_gap_after_exam"
                                value={values.invigilator_gap_after_exam}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="invigilator_gap_after_exam">{T.t('gen_invigilator_gap_after_exam')}</label>
                              {errors && errors.invigilator_gap_after_exam && props.submitCount > 0 && (
                                <div className="error">{errors && errors.invigilator_gap_after_exam}</div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <><label id="select_campus_for_combined_exam">{T.t('gen_campus')} <i className="material-icons">info_outlined</i></label>
                                    <UncontrolledTooltip placement="bottom" target="select_campus_for_combined_exam">
                                      {T.t('gen_select_campus_for_combined_exam')}
                                    </UncontrolledTooltip></>
                                  <Select
                                    id='select_campus'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={CampusSelectOptions}
                                    placeholder={T.t('gen_select_location')}
                                    value={props.values.campus ? props.values.campus : null}
                                    onChange={(option: any) => {
                                      props.setFieldValue('campus', option);
                                      props.setFieldValue('campus_id', option && option.value);
                                      if (option.value == ExamPeriodCourseTermLectureLocations(T)[0].value) {
                                        values.exam_environment = ExamEnvironments(T)[1].value;
                                        values.exam_environment_select = ExamEnvironments(T)[1];
                                        values.classroom_combine_status = ClassroomCombineTypes(T)[0].value;
                                      } else {
                                        if (values.exam_environment == ExamEnvironments(T)[1].value) {
                                          values.exam_environment = undefined;
                                          values.exam_environment_select = undefined;
                                        }
                                      }
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_exam_type')}</label>
                                  <Select
                                    id='select_exam_type'
                                    className="react-select"
                                    isMulti={false}
                                    isClearable={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={ExamTypes(T)}
                                    placeholder={T.t('gen_select_exam_type')}
                                    value={
                                      props.values.exam_type != undefined
                                        ? ExamTypes(T).find(
                                          (option) => option.value === props.values.exam_type
                                        )
                                        : null
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('exam_type_select', option)
                                      props.setFieldValue('exam_type', option && option.value)
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_exam_type')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_exam_environment')}</label>
                                  <Select
                                    id='select_exam_environment'
                                    className="react-select"
                                    isMulti={false}
                                    isClearable={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={ExamEnvironments(T)}
                                    placeholder={T.t('gen_select_exam_environment')}
                                    value={
                                      props.values.exam_environment != undefined
                                        ? ExamEnvironments(T).find(
                                          (option) => option.value === props.values.exam_environment
                                        )
                                        : null
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('exam_environment', option && option.value)
                                      props.setFieldValue('exam_environment_select', option);
                                      if (option && option.value == ExamEnvironments(T)[1].value) {
                                        values.campus_id = ExamPeriodCourseTermLectureLocations(T)[0].value;
                                        values.campus = ExamPeriodCourseTermLectureLocations(T)[0];
                                        values.classroom_combine_status = ClassroomCombineTypes(T)[0].value;
                                      } else {
                                        if (values.campus_id == ExamPeriodCourseTermLectureLocations(T)[0].value) {
                                          values.campus_id = undefined;
                                          values.campus = undefined;
                                        }
                                      }
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_exam_environment')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_course_language')}</label>
                                  <Select
                                    id='select_course_language'
                                    className="react-select"
                                    isMulti={false}
                                    isClearable={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.course_languages_active
                                        ? this.props.selectOptions.course_languages_active
                                        : []
                                    }
                                    placeholder={T.t('gen_select_course_language')}
                                    value={props.values.course_language_select}
                                    onChange={(option: any) => {
                                      props.setFieldValue('course_language', option && option.value);
                                      props.setFieldValue('course_language_select', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_course_language')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_classroom_type')}</label>
                                  <Select
                                    id='classroom_type'
                                    className="react-select"
                                    isMulti={false}
                                    isClearable={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={classroomTypes.filter(filterClassroomTypesForEventModuleStatus(this.props.general_settings))}
                                    placeholder={T.t('gen_select_classroom_type')}
                                    value={classroomTypes.find((option) => option.value == values.classroom_type)}
                                    onChange={(option: any) => {
                                      props.setFieldValue('classroom_type', option && option.value);
                                      props.setFieldValue('classroom_type_select', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_exam_overlap_type')}</label>
                                  <Select
                                    id='select_exam_overlap_type'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={ExamOverlapTypes(T)}
                                    placeholder={T.t('gen_select_exam_overlap_type')}
                                    value={
                                      props.values.exam_overlap_type != undefined
                                        ? ExamOverlapTypes(T).find(
                                          (option) => option.value === props.values.exam_overlap_type
                                        )
                                        : ExamOverlapTypes(T)[0]
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('exam_overlap_type', option.value)
                                      props.setFieldValue('exam_overlap_type_select', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_exam_overlap_type')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_classroom_combine_status')}</label>
                                  <Select
                                    id='select_status'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={ClassroomCombineTypes(T)}
                                    placeholder={T.t('gen_select_status')}
                                    value={
                                      values.classroom_combine_status != undefined
                                        ? ClassroomCombineTypes(T).find(
                                          (option) => option.value === values.classroom_combine_status
                                        )
                                        : null
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('classroom_combine_status', option.value)
                                      if (option.value != ClassroomCombineTypes(T)[0] && values.campus_id == ExamPeriodCourseTermLectureLocations(T)[0].value) {
                                        values.campus_id = undefined;
                                        values.campus = undefined;
                                      }
                                      if (option.value != ClassroomCombineTypes(T)[0] && values.exam_environment == ExamEnvironments(T)[1].value) {
                                        values.exam_environment = undefined;
                                        values.exam_environment_select = undefined;
                                      }
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_status')}
                                  />
                                </div>
                                {errors && errors.classroom_combine_status && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.classroom_combine_status}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_invigilator_status')}</label>
                                  <Select
                                    id='select_invigilator_status'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={InvigilatorStatuses(T)}
                                    placeholder={T.t('gen_select_invigilator_status')}
                                    value={
                                      props.values.invigilator_status_select
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('invigilator_status', option.value)
                                      props.setFieldValue('invigilator_status_select', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_invigilator_status')}
                                  />
                                </div>
                                {errors && errors.invigilator_status && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.invigilator_status}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_course_assistants')}</label>
                                  <Select
                                    id='select_assistant'
                                    className="react-select"
                                    isMulti={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={false}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.instructors
                                        ? this.props.selectOptions.instructors
                                        : []
                                    }
                                    placeholder={T.t('gen_select_assistant')}
                                    value={values.assistant_staff}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('assistant_staff', list);
                                      props.setFieldValue(
                                        'assistant_staff_ids',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_assistan')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 form-input form-group with-icon mt-3">
                              <textarea
                                name="description"
                                className="form-input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                rows={2}
                                id="description"
                                value={values.description}
                                placeholder={T.t('gen_description')}
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="description" />
                              <ErrorMessage component="div" className="error" name="description" />
                            </div>
                            <MultiSelectionTable
                              combined_values={combined_values_selected}
                              multiSelectionObjectFunction={this.multiSelecteds}
                            />
                          </div>
                          <div className="row mt-3">
                            <div className="col-2 text-left">
                              {this.state.is_student_course_overlap_exist ? (
                                <div className="modal-title d-inline-flex align-items-center mt-2">
                                  <h6 style={{ color: "red" }}>{T.t('gen_student_course_overlaps_ignore')}</h6>
                                </div>
                              ) : null}
                            </div>
                            <div className="col-1 text-left">
                              {this.state.is_student_course_overlap_exist ? (
                                <div className="react-switch-container mt-1">
                                  <Switch
                                    id="approval_overlap"
                                    className="react-switch"
                                    onChange={(checked: boolean) => {
                                      this.state.approval_overlap = checked;
                                      this.setState(this.state);
                                    }}
                                    checked={this.state.approval_overlap}
                                  />
                                </div>
                              ) : null}
                            </div>
                            <div className="col-5"></div>
                            <div className="col-4 text-right">
                              <button
                                id='button_save'
                                type="button"
                                className="btn btn-green mt-md-0 mt-2 mb-md-0 mb-2"
                                disabled={this.state.is_student_course_overlap_exist == undefined || ((this.state.is_student_course_overlap_exist && !this.state.approval_overlap))}
                                onClick={() => {
                                  saveButtonClicked = true;
                                  props.handleSubmit();
                                  scrollToTop();
                                }}
                              >
                                {T.t('gen_save')}
                              </button>
                            </div>
                          </div>
                          <hr />
                          <div className="col-12">
                            <h6>{T.t("gen_merged_exam")}</h6>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_status')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_course_no')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_code')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_conjugate_course_code')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_name')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_student_count')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_total_student_count')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_grade')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_section')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_faculty')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_campus')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_exam_duration')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_session_count')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_classroom_combine_status')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_exam_type')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_exam_environment')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_exam_overlap_type')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_invigilator_status')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_connected_programs')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_instructors')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_assistants')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_description')}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseList &&
                        courseList.length ?
                        courseList.map((courseObject: { id: number; item: Types.IExamPeriod_CourseItem }, index: any) => {

                          let courseItem = courseObject.item.courseInfo;

                          let ClassroomCombineStatusObject: any = {};
                          let combineStatus = courseItem && courseItem.classroom_combine_status;
                          ClassroomCombineStatusObject = ClassroomCombineTypes(T).find(
                            (object) => object.value == combineStatus
                          );

                          let examType = courseItem && courseItem.exam_type;
                          let ExamTypesObject = ExamTypes(T).find(
                            (object) => object.value == examType
                          );

                          let examEnvironment = courseItem && courseItem.exam_environment;
                          let ExamEnvironmentsObject = ExamEnvironments(T).find(
                            (object) => object.value == examEnvironment
                          );

                          let examOverlapType = courseItem && courseItem.exam_overlap_type;
                          let examOverlapTypesObject = ExamOverlapTypes(T).find(
                            (object) => object.value == examOverlapType
                          );

                          let invigilatorStatus = courseItem && courseItem.invigilator_status;
                          let invigilatorStatusesObject = InvigilatorStatuses(T).find(
                            (object) => object.value == invigilatorStatus
                          );

                          return (
                            <tr
                              key={index}
                              data-title={courseItem && courseItem.name}
                            >
                              <td scope="row" data-label={T.t('gen_status')}>
                                <div className="tags ml-1 mr-4">
                                  <button
                                    id='button_status'
                                    className={
                                      `small-tag text-uppercase` +
                                      (courseItem && courseItem.status == 1 ? ` tag-green` : ` tag-red`)
                                    }
                                  >
                                    {GT.GetActiveStatus(courseItem && courseItem.status)}
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_course_no')} className="text-center">
                                {courseItem && courseItem.course_id}
                              </td>
                              <td scope="row" data-label={T.t('gen_code')} className="text-center">
                                <div className="table-scrollable-td">{courseItem && courseItem.course_code ? courseItem.course_code.split(';').join(', ') : '-'}</div>
                              </td>
                              <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                <div className="table-scrollable-td">{courseItem && courseItem.conjugate_course_code ? courseItem.conjugate_course_code.split(';').join(', ') : '-'}</div>
                              </td>
                              <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                <div className="table-scrollable-td">{courseItem && courseItem.name ? courseItem.name.split(';').join(', ') : '-'}</div>
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.student_counts && courseItem.student_counts.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.student_counts && courseItem.student_counts.map((i: any, index: any) => (index == courseItem!.student_counts!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.student_counts && courseItem.student_counts.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.student_count_total}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.classes && courseItem.classes.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.classes && courseItem.classes.map((i: any, index: any) => (index == courseItem!.classes!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.sections && courseItem.sections.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.sections && courseItem.sections.map((i: any, index: any) => (index == courseItem!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.faculties && courseItem.faculties.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.faculties && courseItem.faculties.map((i: any, index: any) => (index == courseItem!.faculties!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.campuses && courseItem.campuses.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.campuses && courseItem.campuses.map((i: any, index: any) => (index == courseItem!.campuses!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td scope="row" data-label={T.t('gen_exam_duration')} className="text-center">
                                {courseItem && courseItem.exam_duration}
                              </td>
                              <td data-label={T.t('gen_session_count')} className="text-center">
                                {courseItem && courseItem.exam_session}
                              </td>
                              <td data-label={T.t('gen_classroom_combine_status')} className="text-center">
                                {ClassroomCombineStatusObject && ClassroomCombineStatusObject.label}
                              </td>
                              <td data-label={T.t('gen_exam_type')} className="text-center">
                                {ExamTypesObject && ExamTypesObject.label ? ExamTypesObject && ExamTypesObject.label : '-'}
                              </td>
                              <td data-label={T.t('gen_exam_environment')} className="text-center">
                                {ExamEnvironmentsObject && ExamEnvironmentsObject.label ? ExamEnvironmentsObject && ExamEnvironmentsObject.label : '-'}
                              </td>
                              <td data-label={T.t('gen_exam_overlap_type')} className="text-center">
                                {examOverlapTypesObject && examOverlapTypesObject.label ? examOverlapTypesObject && examOverlapTypesObject.label : '-'}
                              </td>
                              <td data-label={T.t('gen_invigilator_status')} className="text-center">
                                {invigilatorStatusesObject && invigilatorStatusesObject.label ? invigilatorStatusesObject && invigilatorStatusesObject.label : '-'}
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.programs && courseItem.programs.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.programs && courseItem.programs.map((i: any, index: any) => (index == courseItem!.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.instructors && courseItem.instructors.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.instructors && courseItem.instructors.map((i: any, index: any) => (index == courseItem!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.assistant_staff && courseItem.assistant_staff.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem && courseItem.assistant_staff && courseItem.assistant_staff.map((i: any, index: any) => (index == courseItem!.assistant_staff!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td data-label={T.t('gen_description')} className="text-center">
                                {courseItem && courseItem.description && courseItem.description.length ?
                                  <div className="table-scrollable-td">{courseItem.description}</div> : '-'}
                              </td>
                            </tr>
                          );
                        }) : null}
                    </tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.ICombineModalProps
): Types.ICombineModalProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ICombineModalProps = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.coursePage,
    general_settings: store.state.general_settings,
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CombineExamsModal);

export default container;
