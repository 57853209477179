import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import Translator from '../../services/translate-factory';
import { ISelectOption } from '../../store/types';
import Spinner from '../templates/spinner';
import cn from '../ui/Tailwind';

interface SelectFieldProps extends React.ComponentProps<typeof CreatableSelect> {
    id: string;
    label?: string;
    options: ISelectOption[] | undefined;
    onChange: (options: any) => void;
    placeholder: string;
    error?: string;
    submitCount?: number;
    T: Translator;
    spinner?: string,
    marginBottom?: boolean,
    isCreatable?: boolean,
    showError?: boolean,
    rootClassName?: string,
    constantVariants?: ISelectOption[]
    onCreateValue?: (label: string) => void;
}

const SelectField: React.FC<SelectFieldProps> = ({
    id,
    label,
    options,
    onChange,
    placeholder,
    error,
    showError,
    spinner,
    submitCount,
    marginBottom = true,
    T,
    isCreatable,
    rootClassName,
    constantVariants = [],
    onCreateValue = (label: string) => label,
    ...otherSelectBaseProps
}) => {
    const [currentOptions, setCurrentOptions] = useState<ISelectOption[] | undefined>(options);
    const [newOptions, setNewOptions] = useState<ISelectOption[]>([]);

    useEffect(() => {
        setCurrentOptions([...(options || []), ...newOptions]);
    }, [options])

    const handleCreateOption = (inputValue: string) => {
        const newOption: ISelectOption = { label: inputValue, value: onCreateValue(inputValue) };
        const updatedOptions: ISelectOption[] = [...(currentOptions || []), newOption];
        setCurrentOptions(updatedOptions);
        setNewOptions(prev => ([...prev, newOption]));

        if (otherSelectBaseProps.isMulti) {
            onChange([...(otherSelectBaseProps.value as Array<ISelectOption> || []), newOption]);
        } else {
            onChange(newOption);
        }
    };

    const SelectComponent = isCreatable
        ? (CreatableSelect as React.ComponentType<React.ComponentProps<typeof CreatableSelect>>)
        : (Select as React.ComponentType<React.ComponentProps<typeof Select>>);

    return (
        <>
            {spinner && <Spinner name={spinner} />}
            <div className={cn(
                "add-custom-tag",
                { "mb-3": marginBottom },
                rootClassName
            )}>
                <div className={cn({ "react-select-container": marginBottom })}>
                    {label && <label>{label}</label>}
                    <SelectComponent
                        id={id}
                        className="react-select"
                        formatCreateLabel={(inputValue: string) => T.t('gen_create') + ` "${inputValue}"`}
                        filterOption={(option, search) => {
                            const normalizedSearch = search.toLowerCase().trim();
                            const matches = constantVariants.filter(
                                variant =>
                                    variant.label.toLowerCase().includes(normalizedSearch)
                            );

                            // if there is a match with the value, return true
                            const isMatched = matches.some(match => match.value === option.data.value);

                            return isMatched || option.label.toLowerCase().includes(normalizedSearch);
                        }}
                        options={currentOptions}
                        value={otherSelectBaseProps.value}
                        placeholder={placeholder}
                        onChange={onChange}
                        isValidNewOption={(inputValue) => {
                            // if the input is matches with the constant variants, return false
                            const normalizedInput = inputValue.toLowerCase().trim();
                            if (normalizedInput.length === 0) return false;
                            const isExisting = [...constantVariants, ...(currentOptions || [])].some(
                                variant => variant.label.toLowerCase() === normalizedInput
                            );

                            return !isExisting;
                        }}
                        onCreateOption={isCreatable ? handleCreateOption : undefined}
                        noOptionsMessage={() => T.t('gen_no_options_available')}
                        {...otherSelectBaseProps}
                    />
                </div>
                {showError && error && (
                    <div className="error">{error}</div>
                )}
            </div>
        </>
    );
};

export default SelectField;
