import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment, { duration } from 'moment';
import {
  ICourseSolutionGridInitialValues,
  coursesFormatsValues,
  courseMessagesValues,
  ActivitySolutionSearchInitialValues
} from '../../../store/constants/solution-const';
import { Log } from 'ng2-logger';
import * as Types from '../../../store/types';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ICourseSolutionGridState } from '../../../store/types';
import Select from 'react-select';
import * as Actions from '../../../store/actions/general';
import DisplayEventModal from './display-event-modal';
import SolutionActivityAddModal from './solution-activity-add-modal';
import ReportsDownloadModal from '../../planning/reports-download-modal';
import * as Constants from '../../../store/constants/all';
import { constants } from '../../../store/constants/solution-const';
import { jsPDF } from 'jspdf';
import h2c from "html2canvas";
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import Translator from '../../../services/translate-factory';
import Announcement from '../../../assets//images/announcement.svg';
import { UncontrolledTooltip } from 'reactstrap';
import { SectionTypes } from '../../../store/constants/enums';
import { getLocalizedDateLocalizer } from '../../../util/language';
import { LegendComponent } from '../../exam-period/solution/solution-calendar';
import SelectField from '../../../components/Input/select-field';

const T = Translator.create();
const Logger = Log.create('SolutionGrid');

let activityIds: Array<number> = new Array<number>();

function getInitialState(): Types.ICourseSolutionGridState {
  return Object.assign({}, ICourseSolutionGridInitialValues);
}

class SolutionGrid extends Component<any, ICourseSolutionGridState> {
  state: Types.ICourseSolutionGridState = getInitialState();
  localizer = getLocalizedDateLocalizer(T);

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
        this.state.filters = Object.assign({}, ICourseSolutionGridInitialValues);
        this.localizer = getLocalizedDateLocalizer(T)
        this.resetScheduleProps();
        this.resetFormikForm();
        this.setState(this.state)
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.getSelectOptions();
    this.getProgramsByFacultiesAtSolution([]);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  searchActivitySolution() {
    this.state.filters.solution_id = this.props.solutionId;
    this.state.filters.term_id = this.props.term_id;

    if (this.state.filters.term_id === -1) {
      this.state.filters.term_id = this.props.termId === undefined ? -1 : this.props.termId;
    }

    this.props.dispatch(
      Actions.ApiRequest(Constants.solution.SOLUTION_ACTIVITY_LIST_SEARCH, this.state.filters, 'solution-schedule-spinner')
    );
  }

  getSelectOptions() {
    let term_id = this.props.termId === undefined ? this.props.term_id : this.props.termId;
    this.props.dispatch(Actions.ApiRequest(Constants.solution.SOLUTION_ACTIVITY_SELECT_OPTIONS, term_id, 'solution-list-spin'));
  };

  getProgramsByFacultiesAtSolution = (facultyIds: any) => {
    let model = {
      faculty_ids: facultyIds,
      term_id: this.state.termId! > 0 && this.state.termId != undefined ? this.state.termId! : this.props.termId
    };

    this.props.dispatch(Actions.ApiRequest(Constants.solution.SOLUTION_GET_PROGRAMS_BY_FACULTIES, model, 'solution-list-spin'));
  }

  resetScheduleProps = () => {
    let model: Types.IFilterSolutionModel = {
      term_id: this.props.solutionInfo.term_id,
      active_tab: '0',
      page: 1,
      total: -1,
      size: 10,
      solution_id: this.props.solutionId
    };

    this.props.dispatch(
      Actions.ApiRequest(Constants.solution.SOLUTION_GET_BY_TERM, model, 'solution-schedule-spinner')
    );
    this.getSelectOptions();
    this.getProgramsByFacultiesAtSolution([]);

    let searchModel: Types.IFilterActivitySolution = {
      term_id: this.props.term_id,
      solution_id: this.props.solutionId,
      activities: [],
      activity_ids: activityIds,
      instructors: [],
      instructor_ids: [],
      faculties: [],
      faculty_ids: [],
      programs: [],
      program_ids: [],
      sections: [],
      section_ids: [],
      classrooms: [],
      classroom_ids: [],
      campus_ids: [],
      campuses: [],
      buildings: [],
      building_ids: [],
      weeks: [],
      holiday_exclusions: [],
      week: 0,
      holiday_exclusion: 0,
      packages: [],
      package_ids: []
    };

    if (searchModel.activity_ids.length != 0) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.solution.SOLUTION_ACTIVITY_LIST_SEARCH, searchModel, 'solution-schedule-spinner')
      );
    }
  }

  switchSolutionActivityAddModalStatus = () => {
    this.setState({
      ...this.state,
      activityCopyModalIsOpen: !this.state.activityCopyModalIsOpen,
    });

    if (this.state.activityCopyModalIsOpen === false) {
      this.resetScheduleProps();
    }
  };

  resultSearch(
    activity_ids: Array<number>
  ) {
    activityIds = activity_ids;
  }

  checkSolutionFirstWeek(values: Types.IFilterActivitySolution) {
    const startDate = this.state.scheduleData.startDate;

    try {
      if (values.weeks[0]) {
        const updatedDate = SolutionGrid.extractAndParseDate(values.weeks[0].label)

        return this.setState((prev) => ({
          ...prev,
          calendar_default_date: updatedDate,
        }))

      }

      const weekLabel = this.props.selectOptions && this.props.selectOptions.weeks[0].label;

      const firstWeek = SolutionGrid.extractAndParseDate(weekLabel);

      const lastWeekLabel = this.props.selectOptions && this.props.selectOptions.weeks[this.props.selectOptions.weeks.length - 1].label;
      const lastWeek = SolutionGrid.extractAndParseDate(lastWeekLabel, true);

      if (startDate < firstWeek) {
        return this.setState((prev) => ({
          ...prev,
          calendar_default_date: firstWeek,
        }))
      }
      else if (startDate > lastWeek) {
        return this.setState((prev) => ({
          ...prev,
          calendar_default_date: firstWeek,
        }));
      }
      else {
        return this.setState((prev) => ({
          ...prev,
          calendar_default_date: startDate

        }))
      }

    } catch (error) {
      return this.setState((prev) => ({
        ...prev,
        calendar_default_date: startDate
      }))
    }
  }

  static extractAndParseDate(str: string, secondDate = false) {
    const dateRegex = /\b(\d{4}\.\d{2}\.\d{2})\b/;
    const match = dateRegex.exec(str)

    if (match) {
      const [year, month, day] = match[secondDate ? 1 : 0].split('.').map(Number);
      return new Date(year, month - 1, day);
    } else {
      return new Date()
    }
  }

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;
    if (props && props.solutionInfo) {
      hasNewState = true;
      // if week of day is sunday, will be shown next day on calendar.
      let currentDate = new Date();
      var firstDay = currentDate.getDate() - currentDate.getDay();
      if (firstDay == currentDate.getDate()) {
        currentDate.setDate(currentDate.getDate() + 1);
      } else {
        currentDate.setDate(currentDate.getDate());
      }
      let startDate = currentDate.toISOString().slice(0, 10);

      const startHour = moment(props.solutionInfo.start_hour, 'HH:mm').format('H');
      const endHour = moment(props.solutionInfo.end_hour, 'HH:mm').format('H');
      const min = parseInt(startHour, 10);
      const min_minute = parseInt(props.solutionInfo.start_hour ? props.solutionInfo.start_hour.split(':')[1] : '', 10);
      const max = parseInt(endHour, 10);
      const max_minute = parseInt(props.solutionInfo.end_hour ? props.solutionInfo.end_hour.split(':')[1] : '', 10);

      const duration_hour = moment(props.solutionInfo.slot_duration, 'HH:mm').format('H');
      const duration_mins = moment(props.solutionInfo.slot_duration, 'HH:mm').format('m');

      const duration_in_minutes = parseInt(duration_hour, 10) * 60 + parseInt(duration_mins, 10);
      const step = 15;
      const slotDuration = Math.floor(duration_in_minutes / step);

      const isScheduleCreated = props.solutionInfo.start_date && props.solutionInfo.start_date;

      state.scheduleData.startDate = moment(startDate).toDate();
      state.scheduleData.min = min;
      state.scheduleData.max = max;
      state.scheduleData.max_minute = max_minute;
      state.scheduleData.min_minute = min_minute;
      state.scheduleData.slotDuration = slotDuration;
      state.scheduleData.isScheduleCreated = isScheduleCreated;
      state.solutionInfo = props.solutionInfo;

      if (props.settings != undefined) state.settings = props.settings;
    }

    let term_id: number = props.solutionInfo != undefined ? props.solutionInfo.term_id : state.termId;

    if (props.model && props.model.createDate) {
      state.termId = term_id;
      state.dataModel = props.model;
      state.initialModel = props.model.solutionActivities;
    }

    if (state.initialModel) {
      // DEPRECATED - This is not used anymore
      if (props.model && props.selectOptions && props.selectOptions.weeks && state.filters && state.filters.week == 0) {
        const selectOptionsDates = props.selectOptions.weeks && props.selectOptions.weeks.map((item: any) => item.label);
        state.weekDateFormat = coursesFormatsValues(T).weekSelectBoxFormat(selectOptionsDates[0].split('(')[1].split(' -')[0]) + ' — ' + coursesFormatsValues(T).weekSelectBoxFormat(selectOptionsDates[0].split(')')[0].split('- ')[1]);
      }

      state.resultModel = SolutionGrid.loadResultModel(props, state);
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  static loadResultModel = (props: any, state: any) => {
    let term_id: number = props.solutionInfo != undefined ? props.solutionInfo.term_id : state.termId;

    let calendar_classroom_display_type: number = props.settings != undefined ? props.settings.calendar_classroom_display_type : state.settings.calendar_classroom_display_type;
    let settings_solution_show_course_opened_sections: boolean = props.settings != undefined ? props.settings.solution_show_course_opened_sections : state.settings.solution_show_course_opened_sections;

    const activityArray: any[] = []

    state.initialModel.forEach((activity: any) => {

      activity.weeks.forEach((week: number) => {
        let validStartDate = moment()
        try {
          const validWeekInfo: Types.ISelectOption = props.selectOptions.weeks.find((item: Types.ISelectOption) => item.value == week);
          const weekDate = SolutionGrid.extractAndParseDate(validWeekInfo.label)
          validStartDate = moment(weekDate)
        } catch (error) {
          return Logger.error("Error on parsing activity week", { error, activity })
        }


        const slotDuration = props.solutionInfo != undefined ? props.solutionInfo.slot_duration.split(':') : state.solutionInfo.slot_duration.split(':');
        const durationMinutes = slotDuration[0] * 60 + parseInt(slotDuration[1], 10);
        const activityDateInfo = state.dataModel.solutionStartDates.find(
          (item: any) => item.activityNo == activity.activityNo
        );

        // parsedDate'den itibaren haftanin gunu
        const weekStart = validStartDate.clone().startOf('isoWeek'); // parsedDate'in bulundugu haftanin basi (pazartesi)

        // haftanin hangi gün
        const activityWeekDay = weekStart.clone().add(activityDateInfo.weekOfDay - 1, 'days'); //ornek -> carsamba weekOfDay = 3

        const hourToString = activityDateInfo.hour.toString() + ':' + activityDateInfo.minute.toString();
        const createHour = moment(hourToString, 'HH:mm');

        const startDate = moment(activityWeekDay.format('YYYY-MM-DD') + ' ' + createHour.format('HH:mm:ss'));
        const endDate = startDate.clone().add(durationMinutes * activity.activityDuration, 'm');

        const activityInfo = {
          id: activity.activityNo,
          code: activity.courseCode,
          name: activity.courseName,
          date: moment(startDate).format('dddd HH:mm') + ' - ' + moment(endDate).format('HH:mm'),
          dayOfWeek: activityDateInfo.weekOfDay,
          startHour: startDate.format('HH:mm'),
          campus: activity.campusName,
          campusCode: activity.campusCode,
          building: activity.buildingName,
          duration: durationMinutes * activity.activityDuration,
          lessonCount: activity.activityDuration,
          instructor: activity.instructorName,
          instructorCodes: activity.instructorCodes,
          program: activity.programName,
          educationType: activity.education,
          studentCount: activity.studentCount,
          classroom: activity.classroomName,
          classroomCode: activity.classroomCode,
          lectureCapacity: activity.lectureCapacity,
          branch: activity.branch,
          courseOpenedSection: activity.courseOpenedSection,
          grade: activity.grade,
          activityType: activity.activity_type,
          courseType: activity.course_type,
          solutionId: props.solutionId,
          termId: term_id,
          weeks: activity.weeks,
          solutionPublishmentInfo: props.solutionPublishmentInfo
        };
        const sectionBranch = activity.branch ? activity.branch : '-'
        const classroomDisplay = (name: any) => (calendar_classroom_display_type == 1
          ? activity.classroomCode || '-'
          : name || '-')
        const classroomDisplay2 = (calendar_classroom_display_type == 1
          ? activity.classroomCode || '-'
          : activity.classroomName || '-')

        activityArray.push({
          id: activity.activityNo,
          title: (
            (endDate.toDate().getHours() > 0 && endDate.toDate().getHours() <= 8 ? (T.t('gen_this_lesson_until')).replace('{0}', endDate.toDate().toString().substring(16, 21)) : '')
            + (activity.courseCode ? activity.courseCode : '-') + ' - ' +
            (activity.courseName ? activity.courseName : '-') + ('\n') +
            (activity.instructorName ? activity.instructorName : '-') + ('\n') +
            T.t('gen_grade') + ' ' + (activity.grade ? activity.grade : '-') + ' - ' +
            T.t('gen_section') + ' ' + ((settings_solution_show_course_opened_sections && activity.courseOpenedSection != undefined) ? activity.courseOpenedSection : (activity.branch ? activity.branch : '-')) + ' - ' +
            (activity.campusName ? activity.campusName : '-') + ' - ' +
            T.t('gen_classroom') + ': ' + (
              calendar_classroom_display_type == 0 ?
                ((activity.classroomName ? activity.classroomName : '-') + ' (' + (activity.classroomCode ? activity.classroomCode : '-') + ')') :
                (calendar_classroom_display_type == 1 ? (activity.classroomCode ? activity.classroomCode : '-') :
                  (activity.classroomName ? activity.classroomName : '-'))
            )
          ),
          courseCode: activity.courseCode ? activity.courseCode : '-',
          courseName: activity.courseName ? activity.courseName : '-',
          instructorName: Array.isArray(activity.instructorName) ? activity.instructorName : [activity.instructorName],
          grade: activity.grade ? activity.grade : '-',
          section: (settings_solution_show_course_opened_sections && activity.courseOpenedSection != undefined)
            ? activity.courseOpenedSection
            : sectionBranch,
          campusName: activity.campusName ? activity.campusName : '-',
          classroom: Array.isArray(activity.classroomName)
            ? activity.classroomName.map((name: any) => (calendar_classroom_display_type == 0
              ? `${name} (${activity.classroomCode || '-'})`
              : classroomDisplay(name))
            )
            : [(calendar_classroom_display_type == 0
              ? `${activity.classroomName || '-'} (${activity.classroomCode || '-'})`
              : classroomDisplay2)],
          monthTitle: (activity.courseCode ? activity.courseCode : '-') + ' - ' + (activity.courseName ? activity.courseName : '-'),
          start: startDate.toDate(),
          end: endDate.toDate(),
          desc: JSON.stringify(activityInfo),
          type: activity.type ? activity.type : ''
        } as Types.ISolutionEvent)
      })
    })
    return activityArray
  }

  printDocument() {
    const input = document.getElementById('printed-component');
    h2c(input!)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png', 1.0);
        const pdf = new jsPDF("l", "mm", "a4");
        var doc = new jsPDF("l", "mm", "a4");
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        pdf.addImage(imgData, 'PNG', 15, 3, 265, 200, '', 'MEDIUM', 0);
        pdf.save("Çozüm.pdf");
      });
  }

  showInfoModalCourseSchedulePdf = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      let weekNumber = !!!this.state.filters.week ? 1 : this.state.filters.week;
      let selectedWeekForPdf: any;
      if (this.props.selectOptions.weeks) {
        selectedWeekForPdf = this.props.selectOptions.weeks.filter((item: any) =>
          weekNumber === item.value
        );
      }

      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_course_schedule_download_title'),
          body: T.t('gen_course_schedule_download_body').replace('{0}', selectedWeekForPdf[0].label),
          name: 'course_schedule_download',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const model = {
              termId: this.props.solutionInfo.term_id,
              sectionType: SectionTypes.Course_Schedule,
              sectionId: this.props.solutionId,
              parameters: [weekNumber]
            };
            this.props.dispatch(Actions.ApiRequest(Constants.building.DOWNLOAD_ALL_DATA, model, ''));
          }
        })
      );
    }
  };

  switchReportsDownloadModal = () => {
    this.setState({
      ...this.state,
      reportsDownloadModalIsOpen: !this.state.reportsDownloadModalIsOpen
    });
  };

  showInfoModalCourseBasicReport = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_solution_basic_download'),
          body: T.t('gen_continue'),
          name: 'solution_course_download',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const model = {
              termId: this.props.solutionInfo.term_id,
              sectionType: SectionTypes.Solution_Basic,
              sectionId: this.props.solutionId
            };
            this.props.dispatch(Actions.ApiRequest(Constants.building.DOWNLOAD_ALL_DATA, model, ''));
          }
        })
      );
    }
  };

  showInfoModalSolutionRawFiles() {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_solution_download_raw_files_title'),
        body: T.t('gen_continue'),
        name: 'solution_download_raw_files',
        icon: 'info',
        iconColor: 'blue',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const model = {
            solutionId: this.props.solutionId
          };
          this.props.dispatch(
            Actions.ApiRequest(Constants.solution.SOLUTION_DOWNLOAD_RAW_FILES, model, '',
              (result: string, status: number) => {
                if (status === 200) {
                  this.openLinkInNewTab(result);
                }
              })
          );
        }
      })
    );
  };

  openLinkInNewTab(url: string) {
    var win = window.open(url, '_blank');
    win && win.focus();
  }

  switchEventModalStatus = () => {
    this.setState({
      ...this.state,
      eventModalIsOpen: !this.state.eventModalIsOpen
    })

    if (this.state.eventModalIsOpen === false) {
      let model: Types.IFilterSolutionModel = {
        term_id: this.props.solutionInfo.term_id,
        active_tab: '0',
        page: 1,
        total: -1,
        size: 10,
        solution_id: this.props.solutionId
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.solution.SOLUTION_GET_BY_TERM, model, 'solution-schedule-spinner')
      );

      this.searchActivitySolution();
    }
  };

  getDefaultView = () => {
    return (window.innerWidth > 760) ? 'week' : 'agenda'
  };

  onFilterActivitySolution(model: Types.IFilterActivitySolution, FormActions: FormikActions<Types.IFilterActivitySolution>) {
    if (this.state.isSearchRequest) {
      this.state.filters = model;
      // DEPRECATED - This is not used anymore
      if (this.props.model && this.props.selectOptions && this.props.selectOptions.weeks && this.state.filters && this.state.filters.week) {
        const selectOptionsDates = this.props.selectOptions.weeks && this.props.selectOptions.weeks.map((item: any) => item.label);
        this.state.weekDateFormat = coursesFormatsValues(T).weekSelectBoxFormat(selectOptionsDates[this.state.filters.week - 1].split('(')[1].split(' -')[0]) + ' — ' + coursesFormatsValues(T).weekSelectBoxFormat(selectOptionsDates[this.state.filters.week - 1].split(')')[0].split('- ')[1]);
      }
      this.setState(this.state);
      this.searchActivitySolution();
      FormActions.setSubmitting(false);
      this.setState(
        {
          ...this.state,
          isSearchRequest: false
        }
      );
    }
  }

  filterOption = (option: Types.ISelectOption, inputValue: string) => {
    if (option.value === -1) {
      return true;
    } else if (inputValue) {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    } else {
      return true;
    }
  };

  onInputChange = (inputValue: string, action: any) => {
    if (action.action === "input-change") {
      this.state.activities_filtered = this.props.selectOptions && this.props.selectOptions.activities_exist.filter((item: any) =>
        item.label.toLowerCase().includes(inputValue.toLowerCase()) || item.value.toString().toLowerCase().includes(inputValue.toLowerCase()));
    }

    if (action.action === "set-value" && this.state.activities_filtered == undefined) {
      this.state.activities_filtered = this.props.selectOptions && this.props.selectOptions.activities_exist;
    }

    if (action.action === "menu-close" && this.state.activities_filtered != undefined) {
      this.state.activities_filtered = undefined;
    }
  };

  resetFormikForm() {
    if (this.state.formikRef && this.state.formikRef.current) {
      this.state.formikRef.current.resetForm();
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.activeTab !== this.props.activeTab) {
      this.resetFormikForm()
    }
  }

  constructor(props: any) {
    super(props);
    this.state.formikRef = React.createRef();
  }

  render() {
    const activityOptions = this.props.selectOptions && this.props.selectOptions.activities_exist ? this.props.selectOptions.activities_exist : []
    const activitySelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...activityOptions];
    return (
      <Formik
        initialValues={ActivitySolutionSearchInitialValues}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          this.checkSolutionFirstWeek(values)
          this.onFilterActivitySolution(values, actions);
        }}
        ref={this.state.formikRef}
      >
        {(props: FormikProps<Types.IFilterActivitySolution>) => {
          if (props.values.faculties.length == 0) {
            props.values.programs = [];
            props.values.program_ids = [];
          }
          if (props.values.programs.length == 0) {
            props.values.sections = [];
            props.values.section_ids = [];
          }
          return (
            <form onSubmit={props.handleSubmit}>
              <div id="printed-component">
                <div className="row">
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS_EX_INVIGILATORS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_STUDENTS) && (
                      <div className="col-md-3 col-xs-12">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_faculty')}</label>
                            <Select
                              id='select_faculty'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={true}
                              options={
                                this.props.selectOptions && this.props.selectOptions.faculties
                                  ? this.props.selectOptions.faculties
                                  : []
                              }
                              placeholder={T.t('gen_select_faculty')}
                              value={props.values.faculties ? props.values.faculties : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('faculties', list);
                                props.setFieldValue(
                                  'faculty_ids',
                                  list.map((item) => item.value)
                                );
                                this.getProgramsByFacultiesAtSolution(list.map(item => item.value))
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS_EX_INVIGILATORS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_STUDENTS) && (
                      <div className="col-md-5 col-xs-12">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            {props.values.faculty_ids == undefined || props.values.faculty_ids.length == 0 ? (
                              <><label id="select_faculty_filter_program">{T.t('gen_program')} <i className="material-icons">info_outlined</i></label>
                                <UncontrolledTooltip placement="bottom" target="select_faculty_filter_program">
                                  {T.t('gen_select_faculty_filter_program')}
                                </UncontrolledTooltip></>
                            ) : (<><label>{T.t('gen_program')}</label></>)}
                            <Select
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={true}
                              isDisabled={props.values.faculty_ids == undefined || props.values.faculty_ids.length == 0 ? true : false}
                              options={this.props.programs_related_faculty}
                              placeholder={T.t('gen_select_program')}
                              value={props.values.programs ? props.values.programs : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('programs', list);
                                props.setFieldValue(
                                  'program_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_program')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_STUDENTS) && (
                      <div className="col-md-2 col-xs-12">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            {props.values.program_ids == undefined || props.values.program_ids.length == 0 ? (
                              <><label id="select_program_filter_branch">{T.t('gen_section')} <i className="material-icons">info_outlined</i></label>
                                <UncontrolledTooltip placement="bottom" target="select_program_filter_branch">
                                  {T.t('gen_select_program_filter_branch')}
                                </UncontrolledTooltip></>
                            ) : (<><label>{T.t('gen_section')}</label></>)}
                            <Select
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={true}
                              isDisabled={props.values.program_ids == undefined || props.values.program_ids.length == 0 ? true : false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.sections
                                  ? this.props.selectOptions.sections
                                  : []
                              }
                              placeholder={T.t('gen_select_branch')}
                              value={props.values.sections ? props.values.sections : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('sections', list);
                                props.setFieldValue(
                                  'section_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_branch')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS) && (
                      <div className="col-md-2 col-xs-12">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_package')}</label>
                            <Select
                              id='select_package'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.packages
                                  ? this.props.selectOptions.packages
                                  : []
                              }
                              placeholder={T.t('gen_select_package')}
                              value={props.values.packages ? props.values.packages : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('packages', list);
                                props.setFieldValue(
                                  'package_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_package')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_STUDENTS) && (
                      <div className="col-md-3 col-xs-12">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_course')}</label>
                            <Select
                              id='select_course'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={activitySelectOptions}
                              placeholder={T.t('gen_select_course')}
                              value={props.values.activities ? props.values.activities : null}
                              filterOption={this.filterOption}
                              onInputChange={this.onInputChange}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('activities', list.map(item => item.value).includes(-1) ? this.state.activities_filtered : list);
                                props.setFieldValue(
                                  'activity_ids',
                                  list.map(item => item.value).includes(-1) ? this.state.activities_filtered && this.state.activities_filtered.map((item: any) => item.value) : list.map(item => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_course')}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_INSTRUCTORS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS) && (
                      <div className="col-md-3 col-xs-12">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_instructor')}</label>
                            <Select
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.instructors
                                  ? this.props.selectOptions.instructors
                                  : []
                              }
                              placeholder={T.t('gen_select_instructor')}
                              value={props.values.instructors ? props.values.instructors : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('instructors', list);
                                props.setFieldValue(
                                  'instructor_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                  {
                    (this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                      this.props.activeTab === constants.SOLUTION_GET_BY_CLASSROOMS) && !this.props.publicSolution &&
                    <>

                      <div className="col-md-3 col-xs-12">
                        <SelectField
                          T={T}
                          id="select_campus"
                          label={T.t('gen_campus')}
                          value={props.values.campuses}
                          options={this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : []}
                          onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                            const list: Array<Types.ISelectOption> = options
                              ? (options as Array<Types.ISelectOption>)
                              : [];
                            props.setFieldValue('campuses', list);
                            props.setFieldValue(
                              'campus_ids',
                              list.map((item) => item.value)
                            );
                          }}
                          noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                          placeholder={T.t('gen_select_campus')}
                          isMulti={true}

                        />
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <SelectField
                          T={T}
                          id="select_building"
                          label={T.t('gen_building')}
                          value={props.values.buildings}
                          options={this.props.selectOptions && this.props.selectOptions.buildings ? this.props.selectOptions.buildings : []}
                          onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                            const list: Array<Types.ISelectOption> = options
                              ? (options as Array<Types.ISelectOption>)
                              : [];
                            props.setFieldValue('buildings', list);
                            props.setFieldValue(
                              'building_ids',
                              list.map((item) => item.value)
                            );
                          }}
                          noOptionsMessage={(): string => T.t('gen_select_no_building')}
                          placeholder={T.t('gen_select_building')}
                          isMulti={true}
                        />
                      </div>
                    </>
                  }
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_PUBLIC_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_CLASSROOMS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_INSTRUCTORS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_INVIGILATORS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_STUDENTS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS) && (
                      <div className="col-md-3 col-xs-12">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_classroom')}</label>
                            <Select
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.classrooms
                                  ? this.props.selectOptions.classrooms
                                  : []
                              }
                              placeholder={T.t('gen_select_classroom')}
                              value={props.values.classrooms ? props.values.classrooms : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('classrooms', list);
                                props.setFieldValue(
                                  'classroom_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_classroom')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_STUDENTS) && (
                      <div className="col-md-3 col-xs-12">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            {props.values.holiday_exclusion != null && props.values.holiday_exclusion != 0 ? (
                              <><label id="remove_holiday_filter">{T.t('gen_week')} <i className="material-icons">info_outlined</i></label>
                                <UncontrolledTooltip placement="bottom" target="remove_holiday_filter">
                                  {T.t('gen_remove_holiday_filter')}
                                </UncontrolledTooltip></>
                            ) : (<><label>{T.t('gen_week')}</label></>)}
                            <Select
                              id='select_week'
                              className="react-select"
                              isMulti={false}
                              isClearable={true}
                              closeMenuOnSelect={true}
                              isDisabled={props.values.holiday_exclusion != null && props.values.holiday_exclusion != 0 ? true : false}
                              placeholder={T.t('gen_select_week')}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              options={
                                this.props.selectOptions && this.props.selectOptions.weeks
                                  ? this.props.selectOptions.weeks
                                  : []
                              }
                              value={props.values.weeks ? props.values.weeks : null}
                              onChange={(option: any) => {
                                let arr = [option]
                                props.setFieldValue('weeks', arr);
                                props.setFieldValue('week', option && option.value);
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_week')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES && (
                    <div className="col-md-3 col-xs-12">
                      <div className="add-custom-tag mb-3">
                        <div className="react-select-container">
                          {props.values.week != null && props.values.week != 0 ? (
                            <> <label id="remove_week_filter">{T.t('gen_exclusion_holiday')} <i className="material-icons">info_outlined</i></label>
                              <UncontrolledTooltip placement="bottom" target="remove_week_filter">
                                {T.t('gen_remove_week_filter')}
                              </UncontrolledTooltip></>
                          ) : (<><label>{T.t('gen_exclusion_holiday')} </label> </>)}
                          <Select
                            id='select_holiday_exclusion'
                            className="react-select"
                            isMulti={false}
                            isClearable={true}
                            closeMenuOnSelect={true}
                            isDisabled={props.values.week != null && props.values.week != 0 ? true : false}
                            placeholder={T.t('gen_select_holiday_exclusion')}
                            filterOption={(option: any, query: any) =>
                              option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                            }
                            options={
                              this.props.selectOptions && this.props.selectOptions.holiday_exclusions
                                ? this.props.selectOptions.holiday_exclusions
                                : []
                            }
                            value={props.values.holiday_exclusions ? props.values.holiday_exclusions : null}
                            onChange={(option: any) => {
                              let arr = [option]
                              props.setFieldValue('holiday_exclusions', arr);
                              props.setFieldValue('holiday_exclusion', option && option.value);
                            }}
                            noOptionsMessage={(): string => T.t('gen_select_no_week')}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {this.props.activeTab == constants.SOLUTION_GET_BY_PROGRAMS ? (<div className="col-md-3 col-ml-3"></div>) : null}
                  <div className="col-12 text-right">
                    <button
                      type="button"
                      className="btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                      onClick={() => this.resetFormikForm()}
                    >
                      {T.t('reset')}
                    </button>
                    <button
                      type="button"
                      className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2 ml-3"
                      disabled={
                        (props.values.faculties != undefined && props.values.faculties.length != 0) ||
                          (props.values.programs != undefined && props.values.programs.length != 0) ||
                          (props.values.classrooms != undefined && props.values.classrooms.length != 0) ||
                          (props.values.activities != undefined && props.values.activities.length != 0) ||
                          (props.values.instructors != undefined && props.values.instructors.length != 0) ||
                          (props.values.packages != undefined && props.values.packages.length != 0) ||
                          (props.values.campuses != undefined && props.values.campuses.length != 0) ||
                          (props.values.buildings != undefined && props.values.buildings.length != 0) ||
                          (props.values.week != undefined && props.values.week != 0) ||
                          (props.values.holiday_exclusion != undefined && props.values.holiday_exclusion != 0)
                          ? false : true
                      }
                      onClick={() => {
                        props.handleSubmit();
                        this.setState(
                          {
                            ...this.state,
                            isSearchRequest: true
                          }
                        );
                      }}
                    >
                      <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                    </button>
                    <hr />
                  </div>
                  <div className="col-md-12">
                    {this.props.user && this.props.user.role === 's' ? (
                      <div className="solution-add-button">
                        <button id='button_close' className="category-tag-square tag-green solution-add-button" onClick={() => {
                          if (this.props.solutionPublishmentInfo.isPublished) {
                            this.props.dispatch(
                              Actions.ShowModal({
                                title: T.t('gen_add_course'),
                                body: T.t("gen_solution_process_info"),
                                name: 'add_course',
                                icon: 'error_outline',
                                iconColor: 'red',
                                cancel: T.t('gen_close')
                              })
                            );
                          } else {
                            this.getSelectOptions();
                            this.setState({ activityCopyModalIsOpen: !this.state.activityCopyModalIsOpen });
                          }
                        }}>
                          <i className="material-icons mr-2">add</i>{T.t('gen_add_course')}
                        </button>
                      </div>
                    ) : null}
                    <div className="solution-button">
                      {this.props.user && this.props.user.role === 's' && this.props.user.name === 'system' ? (
                        <div className="add-custom-tag">
                          <button className="category-tag-square tag-blue" onClick={() => { this.showInfoModalSolutionRawFiles() }}>
                            <i className="material-icons mr-2">manage_search</i>
                            <span>{T.t('gen_solution_download_raw_files')}</span>
                          </button>
                        </div>
                      ) : null}
                      <div className="add-custom-tag">
                        <button id='button_picture_as_pdf' className="category-tag-square tag-blue ml-1" onClick={this.printDocument}>
                          <i className="material-icons mr-2">picture_as_pdf</i>
                          {T.t('gen_print')}
                        </button>
                      </div>
                      {['s', 'a', 'b', 'e', 'd', 'p', 'r'].includes(this.props.user.role) ? (
                        <div className="add-custom-tag">
                          <button id='button_course_schedule_download' className="category-tag-square tag-blue ml-1" onClick={this.showInfoModalCourseSchedulePdf}>
                            <i className="material-icons mr-2">picture_as_pdf</i>
                            {T.t('gen_course_schedule_download')}
                          </button>
                        </div>
                      ) : null}
                      {/* {['s', 'a', 'b'].includes(this.props.user.role) ? (
                        <div className="add-custom-tag">
                          <button id='button_cloud_download' className="category-tag-square tag-blue ml-1" onClick={this.showInfoModalCourseBasicReport}>
                            <i className="material-icons mr-2">cloud_download</i>
                            <span>{T.t('gen_download')}</span>
                          </button>
                        </div>
                      ) : null} */}
                      {['s', 'a', 'b'].includes(this.props.user.role) ? (
                        <div className="add-custom-tag">
                          <button
                            id='button_cloud_solution_download'
                            className="category-tag-square tag-blue ml-1"
                            onClick={this.switchReportsDownloadModal}
                          >
                            <i className="material-icons mr-2">cloud_download</i>
                            <span>{T.t('solution_download')}</span>
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <LegendComponent items={[
                    { bgColor: '#c7dcf0', title: `${T.t('gen_face_to_face')} / ${T.t("gen_hybrid")}`, textColor: '#265985' },
                    { bgColor: '#c7f0cc', title: T.t('gen_distance_education'), textColor: '#265985' },
                  ]} />
                </div>
                <div className="generic-wrapper" style={{ height: '100vh' }}>
                  {this.state.scheduleData.isScheduleCreated && this.state.resultModel && this.state.resultModel.length !== 0 ? (
                    <Calendar
                      key={JSON.stringify(this.state.calendar_default_date)}
                      events={this.state.resultModel}
                      min={new Date(0, 0, 0, this.state.scheduleData.min, this.state.scheduleData.min_minute, 0)}
                      max={new Date(0, 0, 0, this.state.scheduleData.max, this.state.scheduleData.max_minute, 0)}
                      defaultDate={this.state.calendar_default_date || this.state.scheduleData.startDate}
                      formats={coursesFormatsValues(T)}
                      step={this.state.scheduleData.step}
                      timeslots={this.state.scheduleData.slotDuration}
                      localizer={this.localizer}
                      messages={courseMessagesValues(T)}
                      //toolbar={false}
                      defaultView={this.getDefaultView()}
                      //views={['week']}
                      onSelectEvent={(event: Object, e: React.SyntheticEvent) => {
                        this.setState({
                          ...this.state,
                          selectedEvent: event,
                          eventModalIsOpen: true
                        });
                      }}
                      eventPropGetter={(event, start, end, isSelected) => {
                        const events = event.desc ? JSON.parse(event.desc) : '';
                        let backgroundColor
                        let textColor
                        if (/(^|\s)online(\s|$)/i.test(events.campus) || events.campusCode === 'Uzaktan Eğitim' || events.campusCode === 'Kurum Dışı-Sorumlu' || events.campusCode === 'Kurum Dışı-Aktif') {
                          // online
                          textColor = '#265985'; //blue (default)
                          backgroundColor = '#c7f0cc';
                        } else {
                          // face2face
                          textColor = '#265985'; //blue (default)
                          backgroundColor = '#c7dcf0';
                        }

                        let newStyle = {
                          backgroundColor: backgroundColor,
                          color: textColor,
                          borderRadius: '4px',
                          border: `1px solid ${textColor}`
                        };

                        return {
                          className: '',
                          style: newStyle
                        };
                      }}
                      components={{
                        event: ({ event }) => (
                          <div>
                            <div className='tw-truncate'>{event.courseCode}</div>
                            <div className='tw-truncate'>{event.courseName}</div>
                            <div className='tw-truncate'>
                              {Array.isArray(event.instructors) ? event.instructors.join(', ') : event.instructors}
                            </div>
                            <div className='tw-flex tw-flex-row'>
                              <div className='tw-truncate'>{T.t("gen_grade")}: {event.grade},&nbsp;</div>
                              <div className='tw-truncate'>{T.t("gen_section")}: {event.section}</div>
                            </div>
                            <div className='tw-truncate'>{event.campusName}</div>
                            <div className='tw-truncate'>
                              {T.t("gen_classroom")}: {Array.isArray(event.classroom) ? event.classroom.join(', ') : event.classroom}
                            </div>
                          </div>
                        ),
                        agenda: {
                          event: (e: any) => {
                            try {
                              return <div className=''>{e.event.title}</div>
                            } catch (error) {
                              return <>-</>
                            }
                          }
                        },
                        month: {
                          event: (e: any) => {
                            try {
                              return <div className='tw-truncate'>{e.event.monthTitle}</div>
                            } catch (error) {
                              return <>-</>
                            }
                          }
                        }
                      }}
                    />
                  ) : (
                    <div>
                      <p style={{ textAlign: 'center' }}>
                        {T.t('gen_course_solution_area_info')}
                      </p>
                      <br />
                      <img
                        id='instructor_hours_area_info'
                        style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          width: '40%'
                        }}
                        src={Announcement}
                      />
                    </div>
                  )}
                </div>
                {this.state.eventModalIsOpen && (
                  <DisplayEventModal
                    solutionId={this.props.solutionId}
                    event={this.state.selectedEvent}
                    instructors={this.state.selectOptions.instructorSolution}
                    modalIsOpen={this.state.eventModalIsOpen}
                    onClose={this.switchEventModalStatus}
                  />
                )}
                {this.state.activityCopyModalIsOpen && (
                  <SolutionActivityAddModal
                    solutionId={this.props.solutionId}
                    modalIsOpen={this.state.activityCopyModalIsOpen}
                    solutionActivityAddSelectOptions={this.props.solutionActivityPage}
                    onClose={this.switchSolutionActivityAddModalStatus}
                    resultSearchObjectFunction={this.resultSearch}
                  />
                )}
                {
                  this.state.reportsDownloadModalIsOpen && (
                    <ReportsDownloadModal
                      modalIsOpen={this.state.reportsDownloadModalIsOpen}
                      onClose={this.switchReportsDownloadModal}
                      solution_id={this.props.solutionId}
                    />
                  )
                }
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: any
): any => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    model: store.state.solution_page && store.state.solution_page.solution,
    filters: store.state.solution_page && store.state.solution_page.filters,
    solutionInfo: store.state.examPeriodModal && store.state.examPeriodModal.exam_dates,
    selectOptions: store.state.select_options && store.state.select_options.solutionActivityPage,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
    user: store.state.user,
    term_id: store.state.term_id,
    settings: store.state.general_settings
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.solution_page) {
    return (
      !!equal(
        prev.state.solution_page && prev.state.solution_page,
        next.state.solution_page && next.state.solution_page
      ) &&
      !!equal(
        prev.state.solution_page && prev.state.solution_page.solution,
        next.state.solution_page && next.state.solution_page.solution
      ) &&
      !!equal(
        prev.state.solution_page && prev.state.solution_page.filters,
        next.state.solution_page && next.state.solution_page.filters
      ) &&
      !!equal(
        prev.state.examPeriodModal && prev.state.examPeriodModal.exam_dates,
        next.state.examPeriodModal && next.state.examPeriodModal.exam_dates
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.solutionActivityPage,
        next.state.select_options && next.state.select_options.solutionActivityPage
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.programs_related_faculty,
        next.state.select_options && next.state.select_options.programs_related_faculty
      ) &&
      !!equal(
        prev.state.general_settings && prev.state.general_settings,
        next.state.general_settings && next.state.general_settings
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(SolutionGrid);

export default container;
