import { Formik, FormikActions, FormikProps, ErrorMessage } from 'formik';
import { Log, Logger } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Paginate from '../../components/table/paginate';
import Announce from '../../components/templates/announce';
import { Modal } from 'reactstrap';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';
import { UncontrolledTooltip } from 'reactstrap';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import { DistributorInvigilatorSearchInitialValues } from '../../store/constants/distributor-const';
import {
  ExamPeriodCourseTermLectureLocations,
  EducationTypeSelectOptions,
  ExamTypes,
  InvigilatorStatuses,
  InvigilatorAssignmentStatuses
} from '../../store/constants/course-const';
import { ValueType } from 'react-select/lib/types';
import DistributorInvigilatorCountsModal from './distributor-invigilator-counts-modal';
import { filterClassroomTypesForEventModuleStatus } from '../../util/sort';
import { ClassroomTypesLabel } from '../../store/constants/setting-const';


const T = Translator.create();
const L = Log.create('SolutionEditModal');

const initialValues: any = {
  distributorInvigilatorCountsModalIsOpen: false,
  filters: DistributorInvigilatorSearchInitialValues,
  filterIsOpen: false,
  invigilatorCountsIsOpen: false,
  invigilatorCountsCodes: [],
  invigilatorCountsCardIndex: -1,
  invigilatorCountsInvigilatorId: -1,
  invigilatorCountsRoomId: -1,
  deletedList: [],
  collapse: [],
  cards: [],
  accept_inappropriate_solution: true,
  term_id: -1,
  page: 1,
  total: -1,
  size: 10,
  subfilter_invigilator_name: ''
};
function getInitialState(): any {
  return Object.assign({}, initialValues);
}

class ChangeInvigilatorModal extends Component<any, any> {
  state: {
    distributorInvigilatorCountsModalIsOpen?: boolean;
    filters: Types.IFilterDistributorInvigilator;
    filterIsOpen: boolean;
    invigilatorCountsIsOpen: boolean;
    invigilatorCountsCodes: Array<string>;
    invigilatorCountsCardIndex: number;
    invigilatorCountsInvigilatorId: number;
    invigilatorCountsRoomId: number;
    collapse: Array<Number>;
    cards: Array<Types.IDistributorCourseInvigilatorEditModel>;
    term_id: number;
    size: number;
    page: number;
    total: number;
    subfilter_invigilator_name: string
  } = getInitialState();
  componentRef: any;

  constructor(props: any) {
    super(props);
    this.componentRef = React.createRef();
    this.toggle = this.toggle.bind(this);
  }

  toggle(e: any) {
    let event = e.target.dataset.event;
    this.state.collapse.includes(Number(event)) ? this.state.collapse = this.state.collapse.filter((item: any) => item != Number(event)) : this.state.collapse.push(Number(event));
    this.setState(this.state);
  }

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.state.filters.page = 1;
    this.state.page = 1;
    this.setState(this.state);
    this.getInfoInvigilatorChangeDistributions();
    this.getInvigilatorChangeSelectOptions(0);
    this.setState(this.state);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getInvigilatorChangeSelectOptions(course_id_selected: number) {
    const resultCallback = (result: any, status: number) => { };
    let model = {
      job_id: this.props.distribution_in_progress_status.job_id > 0 ? this.props.distribution_in_progress_status.job_id : this.props.jobId,
      course_id_selected: course_id_selected
    }
    this.props.dispatch(Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_INVIGILATOR_CHANGE_GET_SELECT_OPTIONS, model, 'distribution-invigilator-change-spin', resultCallback));
  }

  getInfoInvigilatorChangeDistributions() {
    const resultCallback = (result: any, status: number) => {
      if (status === 200) {
        this.state.total = result && result.count_courses;
        this.setState(this.state);
      }
    };

    this.state.filters.job_id = this.props.distribution_in_progress_status.job_id > 0 ? this.props.distribution_in_progress_status.job_id : this.props.jobId;
    this.props.dispatch(
      Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_GET_INFO_INVIGILATOR_CHANGE, this.state.filters, 'distribution-invigilator-change-spin', resultCallback),
    );
  }

  setExamInvigilator(exam_id: number, room_id: number, invigilator_id: number, assigned_invigilator_id: number) {
    const resultCallback = (result: any, status: number) => {
      if (status === 200) {
        this.getInfoInvigilatorChangeDistributions();
      }
    };
    this.state.invigilatorCountsIsOpen = false;
    this.state.subfilter_invigilator_name = '';
    this.setState(this.state);
    const model = {
      job_id: this.props.distribution_in_progress_status.job_id > 0 ? this.props.distribution_in_progress_status.job_id : this.props.jobId,
      exam_id: exam_id,
      room_id: room_id,
      invigilator_id: invigilator_id,
      assigned_invigilator_id: assigned_invigilator_id
    };
    this.props.dispatch(Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_INVIGILATOR_CHANGE_SET_EXAM_INVIGILATOR, model, '', resultCallback));
  }

  onInvigilatorChange(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, accept_changes: boolean) {
    if (e && e.currentTarget) {
      const resultCallback = (result: any, status: number) => {
        if (status === 200) {
          this.setCloseModal();
        }
      };
      const onConfirm = () => {
        const model = {
          job_id: this.props.distribution_in_progress_status.job_id > 0 ? this.props.distribution_in_progress_status.job_id : this.props.jobId,
          accept_changes: accept_changes
        };
        this.props.dispatch(
          Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_INVIGILATOR_CHANGE, model, 'distribution-invigilator-change-spin', resultCallback)
        );
      };
      if (accept_changes) {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_distribution_assign_invigilator_manual_accept_title'),
            body: T.t('gen_distribution_assign_invigilator_manual_accept_body'),
            name: 'distribution_assign_invigilator_manual_accept',
            icon: 'info',
            iconColor: 'blue',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_no'),
            onConfirm: onConfirm
          })
        );
      } else {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_distribution_assign_invigilator_manual_cancel_title'),
            body: T.t('gen_distribution_assign_invigilator_manual_cancel_body'),
            name: 'distribution_assign_invigilator_manual_cancel',
            icon: 'error_outline',
            iconColor: 'red',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_no'),
            onConfirm: onConfirm
          })
        );
      }
    }
  }

  onFilterCourseInvigilator(model: Types.IFilterDistributorInvigilator, FormActions: FormikActions<Types.IFilterDistributorInvigilator>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.page = 1;
    this.state.filterIsOpen = true;
    this.state.collapse = [];
    this.setState(this.state);
    this.getInfoInvigilatorChangeDistributions();
    FormActions.setSubmitting(false);
  }

  onFormReset = () => {
    this.state.filters = Object.assign({}, DistributorInvigilatorSearchInitialValues);
    this.setState(this.state);
    this.getInfoInvigilatorChangeDistributions();
  };

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onPageChange = (page: number) => {
    this.state.page = page;
    this.state.filters.page = page;
    this.setState(this.state);
    this.getInfoInvigilatorChangeDistributions();
  };

  switchInvigilatorCountsModalStatus = () => {
    this.setState({
      ...this.state,
      subfilter_invigilator_name: '',
      distributorInvigilatorCountsModalIsOpen: !this.state.distributorInvigilatorCountsModalIsOpen
    });
  };

  switchInvigilatorCountsTableStatus = (cardIndex: number, invigilatorId: number, roomId: number, codes: Array<string>) => {
    this.setState({
      ...this.state,
      invigilatorCountsCardIndex: cardIndex,
      invigilatorCountsInvigilatorId: invigilatorId,
      invigilatorCountsRoomId: roomId,
      invigilatorCountsCodes: codes,
      subfilter_invigilator_name: '',
      invigilatorCountsIsOpen: this.state.invigilatorCountsIsOpen == true && this.state.invigilatorCountsCardIndex == cardIndex &&
        this.state.invigilatorCountsInvigilatorId == invigilatorId && this.state.invigilatorCountsRoomId == roomId ? false : true
    });
  };

  handleChangeSubFilter(e: React.ChangeEvent<HTMLInputElement>) {
    if (e && e.target && e.target.value && e.target.value.length) {
      this.state.subfilter_invigilator_name = e.target.value;
    } else {
      this.state.subfilter_invigilator_name = '';
    }
    this.setState(this.state);
  }

  render() {
    let cards = this.props.result && this.props.result.courses_with_invigilators;

    let invigilators = this.props.result && this.props.result.invigilators ? this.props.result.invigilators : [];
    invigilators.sort((a: any, b: any) => {
      return a.top_all - b.top_all;
    });
    if (this.state.subfilter_invigilator_name && this.state.subfilter_invigilator_name.length) {
      invigilators = invigilators.filter((invigilator: any) => (invigilator.instructor_name.toLowerCase().includes(this.state.subfilter_invigilator_name.toLowerCase())))
    }

    let invigilatorCountsCodes = this.state.invigilatorCountsCodes && this.state.invigilatorCountsCodes.length ? this.state.invigilatorCountsCodes : [];

    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const CampusSelectOptions = [...ExamPeriodCourseTermLectureLocations(T), ...campusOptions];

    const scrollToTop = () => {
      this.componentRef.current.scrollIntoView({ block: 'nearest' });
    }

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div ref={this.componentRef}></div>
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_exam_and_invigilator_distribution')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <Spinner name="distribution-invigilator-change-spin" />
            <div className="container-fluid p-0">
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                }}
              >
                {(props: FormikProps<Types.IDistributorCourseTimeSlotEditModel>) => {
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 col-sm-4 col-12">
                          <button
                            id='button_invigilator_counts'
                            className="category-tag-square tag-green float-left pr-2 pl-2"
                            style={{ margin: '5px' }}
                            onClick={() => {
                              this.state.distributorInvigilatorCountsModalIsOpen = !this.state.distributorInvigilatorCountsModalIsOpen;
                              this.state.subfilter_invigilator_name = '';
                              this.setState(this.state);
                            }}
                          >
                            <i className="material-icons mr-2">people</i>
                            <span>{T.t('gen_invigilator_counts')}</span>
                          </button>
                        </div>
                        <div className="col-6 mb-3 text-right">
                          <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                            <button
                              id='button_filter'
                              className="category-tag-square tag-blue float-right ml-3 mr-3"
                              style={{ margin: '5px' }}
                              onClick={() => {
                                this.state.filterIsOpen = !this.state.filterIsOpen;
                                this.setState(this.state);
                              }}
                            >
                              <i className="material-icons mr-2">filter_list</i>
                              <span>{T.t('gen_filter')}</span>
                            </button>
                          </div>
                        </div>
                        {/* FILTER STARTS HERE  */}
                        <div
                          className={`white-container collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                          id="advance-search"
                        >
                          <div className="advance-search d-block ml-4 mr-4" style={{}}>
                            <Formik
                              initialValues={DistributorInvigilatorSearchInitialValues}
                              enableReinitialize={true}
                              onSubmit={(values, Actions) => {
                                this.onFilterCourseInvigilator(values, Actions);
                                scrollToTop();
                              }}
                              onReset={this.onFormReset}
                            >
                              {(props: FormikProps<Types.IFilterDistributorInvigilator>) => {
                                return (
                                  <form onSubmit={props.handleSubmit}>
                                    <div className="row">
                                      <div className="col-12" style={{ backgroundColor: 'lightblue' }}>
                                        <h6 className="mt-2" style={{ color: 'black' }}>{T.t('gen_filter_parameters')}</h6>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_start_hour')}</label>
                                            <Select
                                              id='select_start_hour'
                                              className="react-select"
                                              isMulti={true}
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.time_slots
                                                  ? this.props.selectOptions.time_slots
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_start_hour')}
                                              value={props.values.time_slots}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('time_slots', list);
                                                props.setFieldValue(
                                                  'time_slot_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_start_hour')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_course_no')}</label>
                                            <Select
                                              id='select_course_no'
                                              className="react-select"
                                              isMulti={true}
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.course_ids_select
                                                  ? this.props.selectOptions.course_ids_select
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_course_no')}
                                              value={props.values.course_ids_select}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('course_ids_select', list);
                                                props.setFieldValue(
                                                  'course_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_course_no')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_course_code')}</label>
                                            <Select
                                              id='select_course_code'
                                              className="react-select"
                                              isMulti={true}
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.course_codes_select
                                                  ? this.props.selectOptions.course_codes_select
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_course_code')}
                                              value={props.values.course_codes_select}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('course_codes_select', list);
                                                props.setFieldValue(
                                                  'course_codes_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_course_code')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="form-input form-group date-picker mt-3">
                                          <input
                                            id="course_name"
                                            name="course_name"
                                            value={props.values.course_name}
                                            onChange={props.handleChange}
                                            type="text"
                                            required
                                          />
                                          <span className="highlight" />
                                          <span className="bar" />
                                          <label htmlFor="course_name">{T.t('gen_course_name')}</label>
                                          <i className="material-icons">title</i>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_faculty_college')}</label>
                                            <Select
                                              id='select_faculty'
                                              className="react-select"
                                              isMulti={true}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.faculties
                                                  ? this.props.selectOptions.faculties
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_faculty')}
                                              value={props.values.faculties ? props.values.faculties : null}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('faculties', list);
                                                props.setFieldValue(
                                                  'faculty_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_education_type')}</label>
                                            <Select
                                              id='education_type'
                                              className="react-select"
                                              isMulti={true}
                                              closeMenuOnSelect={false}
                                              options={EducationTypeSelectOptions(T)}
                                              placeholder={T.t('gen_select_education_type')}
                                              value={props.values.education_types_select}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('education_types_select', list);
                                                props.setFieldValue(
                                                  'education_types',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_education_type')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_campus')}</label>
                                            <Select
                                              id='select_location'
                                              className="react-select"
                                              isMulti={true}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={false}
                                              options={CampusSelectOptions}
                                              placeholder={T.t('gen_select_location')}
                                              value={props.values.campuses ? props.values.campuses : null}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('campuses', list);
                                                props.setFieldValue(
                                                  'campus_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-4">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_connected_programs')}</label>
                                            <Select
                                              id='select_program'
                                              className="react-select"
                                              isMulti={true}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.programs
                                                  ? this.props.selectOptions.programs
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_program')}
                                              value={props.values.programs}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('programs', list);
                                                props.setFieldValue(
                                                  'program_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_grade')}</label>
                                            <Select
                                              id='select_grade'
                                              className="react-select"
                                              isMulti={true}
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.class
                                                  ? this.props.selectOptions.class
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_grade')}
                                              value={props.values.class ? props.values.class : null}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('class', list);
                                                props.setFieldValue(
                                                  'class_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_instructors')}</label>
                                            <Select
                                              id='select_instructor'
                                              className="react-select"
                                              isMulti={true}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.instructors
                                                  ? this.props.selectOptions.instructors
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_instructor')}
                                              value={props.values.instructors}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('instructors', list);
                                                props.setFieldValue(
                                                  'instructor_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_exam_type')}</label>
                                            <Select
                                              id='select_exam_type'
                                              className="react-select"
                                              isMulti={true}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={false}
                                              options={ExamTypes(T)}
                                              placeholder={T.t('gen_select_exam_type')}
                                              value={props.values.exam_type ? props.values.exam_type : null}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('exam_type', list);
                                                props.setFieldValue(
                                                  'exam_type_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_exam_type')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_classroom_type')}</label>
                                            <Select
                                              id='select_type'
                                              className="react-select"
                                              isMulti={true}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.classroom_types
                                                  ? this.props.selectOptions.classroom_types
                                                    .map((_: any) => ({ value: _.value, label: ClassroomTypesLabel(_, T) }))
                                                    .filter(filterClassroomTypesForEventModuleStatus(this.props.general_settings))
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_type')}
                                              value={props.values.classroom_types ? props.values.classroom_types : null}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('classroom_types', list);
                                                props.setFieldValue(
                                                  'classroom_type_ids',
                                                  list.map((item) => item.value.toString())
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_invigilators')}</label>
                                            <Select
                                              id='select_invigilator'
                                              className="react-select"
                                              isMulti={true}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.invigilators
                                                  ? this.props.selectOptions.invigilators
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_invigilator')}
                                              value={props.values.invigilators}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('invigilators', list);
                                                props.setFieldValue(
                                                  'invigilator_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_invigilator')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_invigilator_assignment_status')}</label>
                                            <Select
                                              id='select_invigilator_assignment_status'
                                              className="react-select"
                                              isMulti={true}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={false}
                                              options={InvigilatorAssignmentStatuses(T)}
                                              placeholder={T.t('gen_select_invigilator_assignment_status')}
                                              value={props.values.invigilator_assignment_status ? props.values.invigilator_assignment_status : null}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('invigilator_assignment_status', list);
                                                props.setFieldValue(
                                                  'invigilator_assignment_status_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_invigilator_assignment_status')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_classrooms')}</label>
                                            <Select
                                              id='select_classroom'
                                              className="react-select"
                                              isMulti={true}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.classrooms
                                                  ? this.props.selectOptions.classrooms
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_classroom')}
                                              value={props.values.classrooms}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('classrooms', list);
                                                props.setFieldValue(
                                                  'classroom_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_classroom')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_invigilator_status')}</label>
                                            <Select
                                              id='select_invigilator_status'
                                              className="react-select"
                                              isMulti={true}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={false}
                                              options={InvigilatorStatuses(T)}
                                              placeholder={T.t('gen_select_invigilator_status')}
                                              value={props.values.invigilator_status ? props.values.invigilator_status : null}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('invigilator_status', list);
                                                props.setFieldValue(
                                                  'invigilator_status_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_invigilator_status')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row mb-3">
                                      <div className="col-6">
                                        <button
                                          id='button_arrow_upward'
                                          type="button"
                                          onClick={() => {
                                            this.state.filterIsOpen = false;
                                            this.setState(this.state);
                                          }}
                                          className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                        >
                                          <i className="material-icons">arrow_upward</i>
                                        </button>
                                        <button
                                          id='button_delete_sweep'
                                          type="reset"
                                          onClick={() => {
                                            this.state.filters.page = 1;
                                            this.state.page = 1;
                                            this.setState(this.state);
                                            props.handleReset();
                                            scrollToTop();
                                          }}
                                          className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                        >
                                          <i className="material-icons">delete_sweep</i>
                                        </button>
                                      </div>
                                      <div className="col-6 text-right">
                                        <button
                                          id='button_search'
                                          type="button"
                                          className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                          onClick={() => props.handleSubmit()}
                                          disabled={props.isSubmitting}
                                        >
                                          <i className="material-icons mr-2">search</i>{T.t('gen_search')}
                                        </button>
                                      </div>
                                    </div>
                                    <hr />
                                    <hr />
                                  </form>
                                );
                              }}
                            </Formik>
                          </div>
                        </div>
                        {/* ROWS STARTS HERE  */}
                        <div className="col-12">
                          {cards && cards.length ? (
                            cards.map((item: Types.IDistributorCourseInvigilatorEditModel, index: any) => {
                              return (
                                <Card style={{ marginBottom: '1rem' }} key={index} >
                                  <CardHeader style={{ backgroundColor: 'lightgray' }} onClick={this.toggle} data-event={index}>
                                    {
                                      this.state.collapse.includes(index) ?
                                        <i className="material-icons pr-1 pl-1 mb-1" onSelect={this.toggle} data-event={index}>expand_more</i> :
                                        <i className="material-icons pr-1 pl-1 mb-1" onSelect={this.toggle} data-event={index}>navigate_next</i>
                                    }
                                    <b onSelect={this.toggle} data-event={index}>{item.course_info}</b>
                                    <b className='float-right' onSelect={this.toggle} data-event={index}>{item.exam_info}</b>
                                  </CardHeader>
                                  <Collapse isOpen={this.state.collapse.includes(index) ? true : false}>
                                    <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover filter-table">
                                      <thead>
                                        <tr>
                                          <th className="text-center" scope="col">
                                            {T.t('gen_date')}
                                          </th>
                                          <th className="text-center" scope="col">
                                            {T.t('gen_campus_and_classroom')}
                                          </th>
                                          <th className="text-center" scope="col">
                                            {T.t('gen_invigilator_assignment_status')}
                                          </th>
                                          <th className="text-center" scope="col">
                                          </th>
                                          <th className="text-right" scope="col">
                                            {T.t('gen_actions')}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {item.exam_classrooms.map((exam: any, index_course: any) => {
                                          return (
                                            <tr key={'status-history-detail-' + index_course}>
                                              <td style={{ width: '17%' }} scope="row" data-label={T.t('gen_date')} className="text-center">
                                                {exam && exam.date}
                                              </td>
                                              <td scope="row" data-label={T.t('gen_campus_and_classroom')} className="text-center">
                                                {exam && exam.classroom_name}
                                              </td>
                                              <td scope="row" data-label={T.t('gen_invigilator_assignment_status')} className="text-center">
                                                {exam && exam.dll_room_id == -1 ? '-' : (exam && exam.dll_invigilator_id > -1 ? exam.invigilator_name : T.t('gen_invigilator_unassigned'))}
                                              </td>
                                              <td style={{ width: '2%', visibility: (exam && (exam.dll_room_id == 1 || exam.dll_invigilator_id == -1) ? 'hidden' : 'visible') }} className="text-center" data-label={T.t('change_exam_invigilator')} key={'change_exam_invigilator-' + index_course}>
                                                <button
                                                  id={"button_change_exam_invigilator" + index_course}
                                                  className="mss-form-button mr-2"
                                                  data-toggle="tooltip"
                                                  title={T.t('gen_change_exam_invigilator')}
                                                  style={{ backgroundColor: '#ffc000', color: "#fff" }}
                                                  disabled={exam && exam.dll_room_id == -1}
                                                  onClick={() => this.switchInvigilatorCountsTableStatus(index, exam.dll_invigilator_id, exam.dll_room_id, exam.available_invigilator_codes)}
                                                >
                                                  <i className="material-icons align-items-center justify-content-center">cached</i>
                                                </button>
                                              </td>
                                              <td style={{ width: '2%', visibility: (exam && (exam.dll_room_id == 1 || exam.dll_invigilator_id == -1) ? 'hidden' : 'visible') }} className="text-center" data-label={T.t('delete_exam_invigilator')} key={'delete_exam_invigilator-' + index_course}>
                                                <button
                                                  id='button_delete_exam_invigilator'
                                                  className="mss-form-button mr-2"
                                                  data-toggle="tooltip"
                                                  title={T.t('gen_delete_exam_invigilator')}
                                                  style={{ backgroundColor: '#dc3545', color: "#fff" }}
                                                  disabled={exam && exam.dll_room_id == -1}
                                                  onClick={() => this.setExamInvigilator(item.exam_id, exam.dll_room_id, exam.dll_invigilator_id, -1)}
                                                >
                                                  <i className="material-icons align-items-center justify-content-center">delete_sweep</i>
                                                </button>
                                              </td>
                                              <td style={{ width: '2%', visibility: (exam && exam.dll_room_id == -1 ? 'hidden' : 'visible') }} className="text-center" data-label={T.t('add_exam_invigilator')} key={'add_exam_invigilator-' + index_course}>
                                                <button
                                                  id='button_add_exam_invigilator'
                                                  className="mss-form-button mr-2"
                                                  data-toggle="tooltip"
                                                  title={T.t('gen_add_exam_invigilator')}
                                                  style={{ backgroundColor: '#059700', color: "#fff" }}
                                                  disabled={exam && exam.dll_room_id == -1}
                                                  onClick={() => {
                                                    if (exam.dll_invigilator_id == -1) {
                                                      this.switchInvigilatorCountsTableStatus(index, -1, exam.dll_room_id, exam.available_invigilator_codes)
                                                    } else {
                                                      this.setExamInvigilator(item.exam_id, exam.dll_room_id, -1, -1)
                                                    }
                                                  }}
                                                >
                                                  <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                                                </button>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                    <div className="row">
                                      <div className="col-md-4 pt-3">
                                        {this.state.invigilatorCountsIsOpen && index == this.state.invigilatorCountsCardIndex ?
                                          <div className="form-input form-group date-picker">
                                            <input
                                              id="subfilter_invigilator_name"
                                              name="subfilter_invigilator_name"
                                              value={this.state.subfilter_invigilator_name}
                                              onChange={(e) => this.handleChangeSubFilter(e)}
                                              type="text"
                                            />
                                            <span className="highlight" />
                                            <span className="bar" />
                                            <label htmlFor="subfilter_invigilator_name">{T.t('gen_invigilator') + " " + T.t('gen_name_surname')}</label>
                                            <i className="material-icons">title</i>
                                          </div>
                                          : null}
                                      </div>
                                      <div className="col-md-2 pt-4">
                                        {this.state.invigilatorCountsIsOpen && index == this.state.invigilatorCountsCardIndex && invigilators.length == 0 ?
                                          <label
                                            className="col-md-12 text-left mt-3"
                                            style={{ color: 'red' }}
                                            id="noInvigilatosFoundLabel">
                                            {T.t('gen_no_invigilators_found')}
                                          </label>
                                          : null}
                                      </div>
                                    </div>
                                    <div className="row table-scrollable-td">
                                      <div className="col-12">
                                        {this.state.invigilatorCountsIsOpen && index == this.state.invigilatorCountsCardIndex ?
                                          <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                                            <thead>
                                              <tr>
                                                <th scope="col" className="text-center">
                                                  {T.t('gen_invigilator')}
                                                </th>
                                                <><th id="own_exams_formal_education_code_modal" scope="col" className="text-center">{T.t('gen_own_exams_formal_education_code')}</th>
                                                  <UncontrolledTooltip placement="left" target="own_exams_formal_education_code_modal">
                                                    {T.t('gen_own_exams_formal_education')}
                                                  </UncontrolledTooltip></>
                                                <><th id="gen_own_exams_secondary_education_code_modal" scope="col" className="text-center">{T.t('gen_own_exams_secondary_education_code')}</th>
                                                  <UncontrolledTooltip placement="left" target="gen_own_exams_secondary_education_code_modal">
                                                    {T.t('gen_own_exams_secondary_education')}
                                                  </UncontrolledTooltip></>
                                                <><th id="gen_own_exams_weekend_code_modal" scope="col" className="text-center">{T.t('gen_own_exams_weekend_code')} </th>
                                                  <UncontrolledTooltip placement="left" target="gen_own_exams_weekend_code_modal">
                                                    {T.t('gen_own_exams_weekend')}
                                                  </UncontrolledTooltip></>
                                                <><th id="gen_own_exams_sum_code_modal" scope="col" className="text-center">{T.t('gen_own_exams_sum_code')}</th>
                                                  <UncontrolledTooltip placement="left" target="gen_own_exams_sum_code_modal">
                                                    {T.t('gen_own_exams_sum')}
                                                  </UncontrolledTooltip></>
                                                <><th id="gen_invigilations_formal_education_code_modal" scope="col" className="text-center"> {T.t('gen_invigilations_formal_education_code')} </th>
                                                  <UncontrolledTooltip placement="left" target="gen_invigilations_formal_education_code_modal">
                                                    {T.t('gen_invigilations_formal_education')}
                                                  </UncontrolledTooltip></>
                                                <><th id="gen_invigilations_secondary_education_code_modal" scope="col" className="text-center">{T.t('gen_invigilations_secondary_education_code')}</th>
                                                  <UncontrolledTooltip placement="left" target="gen_invigilations_secondary_education_code_modal">
                                                    {T.t('gen_invigilations_secondary_education')}
                                                  </UncontrolledTooltip></>
                                                <><th id="gen_invigilations_weekend_code_modal" scope="col" className="text-center"> {T.t('gen_invigilations_weekend_code')} </th>
                                                  <UncontrolledTooltip placement="left" target="gen_invigilations_weekend_code_modal">
                                                    {T.t('gen_invigilations_weekend')}
                                                  </UncontrolledTooltip></>
                                                <><th id="gen_invigilations_sum_code_modal" scope="col" className="text-center">  {T.t('gen_invigilations_sum_code')} </th>
                                                  <UncontrolledTooltip placement="left" target="gen_invigilations_sum_code_modal">
                                                    {T.t('gen_invigilations_sum')}
                                                  </UncontrolledTooltip></>
                                                <><th id="gen_all_exams_formal_education_code_modal" scope="col" className="text-center"> {T.t('gen_all_exams_formal_education_code')}</th>
                                                  <UncontrolledTooltip placement="left" target="gen_all_exams_formal_education_code_modal">
                                                    {T.t('gen_all_exams_formal_education')}
                                                  </UncontrolledTooltip></>
                                                <><th id="gen_all_exams_secondary_education_code_modal" scope="col" className="text-center">  {T.t('gen_all_exams_secondary_education_code')}</th>
                                                  <UncontrolledTooltip placement="left" target="gen_all_exams_secondary_education_code_modal">
                                                    {T.t('gen_all_exams_secondary_education')}
                                                  </UncontrolledTooltip></>
                                                <><th id="gen_all_exams_weekend_code_modal" scope="col" className="text-center">  {T.t('gen_all_exams_weekend_code')} </th>
                                                  <UncontrolledTooltip placement="left" target="gen_all_exams_weekend_code_modal">
                                                    {T.t('gen_all_exams_weekend')}
                                                  </UncontrolledTooltip></>
                                                <><th id="gen_all_exams_sum_code_modal" scope="col" className="text-center"> {T.t('gen_all_exams_sum_code')} </th>
                                                  <UncontrolledTooltip placement="left" target="gen_all_exams_sum_code_modal">
                                                    {T.t('gen_all_exams_sum')}
                                                  </UncontrolledTooltip></>
                                                <th scope="col" className="d-none d-lg-table-cell d-xl-table-cell text-right">
                                                  <span className="text-center">{T.t('gen_select') + " / " + T.t('gen_add')}</span>
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {invigilators && invigilators.length
                                                ? invigilators.filter((invigilator: any) => (invigilatorCountsCodes.includes(invigilator.instructor_code)))
                                                  .map((invigilator: any) => (
                                                    <tr key={'invigilator-' + invigilator.dll_instructor_id} data-title={invigilator.dll_instructor_id}>
                                                      <td scope="row" data-label={T.t('gen_invigilator')} className="text-center">
                                                        {invigilator.instructor_name}
                                                      </td>
                                                      <td data-label={'S_NO'} className="text-center">
                                                        {invigilator.s_no}
                                                      </td>
                                                      <td data-label={'S_IO'} className="text-center">
                                                        {invigilator.s_io}
                                                      </td>
                                                      <td data-label={'S_HS'} className="text-center">
                                                        {invigilator.s_hs}
                                                      </td>
                                                      <td data-label={'S_TOP'} className="text-center">
                                                        {invigilator.s_top}
                                                      </td>
                                                      <td data-label={'G_NO'} className="text-center">
                                                        {invigilator.g_no}
                                                      </td>
                                                      <td data-label={'G_IO'} className="text-center">
                                                        {invigilator.g_io}
                                                      </td>
                                                      <td data-label={'G_HS'} className="text-center">
                                                        {invigilator.g_hs}
                                                      </td>
                                                      <td data-label={'G_TOP'} className="text-center">
                                                        {invigilator.g_top}
                                                      </td>
                                                      <td data-label={'TOP_NO'} className="text-center">
                                                        {invigilator.top_no}
                                                      </td>
                                                      <td data-label={'TOP_IO'} className="text-center">
                                                        {invigilator.top_io}
                                                      </td>
                                                      <td data-label={'TOP_HS'} className="text-center">
                                                        {invigilator.top_hs}
                                                      </td>
                                                      <td data-label={'TOP_ALL'} className="text-center">
                                                        {invigilator.top_all}
                                                      </td>
                                                      <td data-label={T.t('gen_add')} className="table-buttons">
                                                        <div className="table-buttons-wrapper">
                                                          <button
                                                            id='button_access_time'
                                                            data-toggle="tooltip"
                                                            data-id={invigilator.dll_instructor_id}
                                                            onClick={(e) => this.setExamInvigilator(item.exam_id, this.state.invigilatorCountsRoomId, this.state.invigilatorCountsInvigilatorId, invigilator.dll_instructor_id)}
                                                            title={T.t('gen_change_invigilator')}
                                                            className="btn btn-light btn-sm table-button"
                                                          >
                                                            <span className="d-block" data-toggle="modal" data-target="#addNew">
                                                              <i className="material-icons lg">person_add</i>
                                                            </span>
                                                          </button>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  ))
                                                : null}
                                            </tbody>
                                          </table>
                                          : null}
                                      </div>
                                    </div>
                                  </Collapse>
                                </Card>
                              )
                            })) : (
                            <div className="row">
                              <div className="col-md-12 col-xs-12 text-center">
                                <Announce title={T.t('gen_no_records_found')} />
                              </div>
                            </div>)
                          }
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="row-options justify-content-end">
              <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
                {cards && cards.length > 0 ? (
                  <Paginate filters={this.state}
                    onPageChange={(e: any) => {
                      this.onPageChange(e);
                      this.state.collapse = [];
                      this.setState(this.state);
                      scrollToTop();
                    }} />
                ) : null}
              </div>
            </div>
            <div className="row-options justify-content-end">
              <div className="page-sorting d-flex align-items-end justify-content-end" style={{ marginRight: '5px' }}>
                <div className="row">
                  <button
                    id='button_cancel'
                    type="button"
                    className="btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2 mr-3"
                    onClick={(e) => {
                      this.onInvigilatorChange(e, false);
                    }}
                  >
                    {T.t('gen_cancel')}
                  </button>
                  <button
                    id='button_save'
                    type="button"
                    className="btn btn-green mt-md-0 mt-2 mb-md-0 mb-2 mr-3"
                    onClick={(e) => {
                      this.onInvigilatorChange(e, true);
                    }}
                  >
                    {T.t('gen_save')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.distributorInvigilatorCountsModalIsOpen && (
          <DistributorInvigilatorCountsModal
            invigilators={invigilators}
            modalIsOpen={this.state.distributorInvigilatorCountsModalIsOpen}
            onClose={this.switchInvigilatorCountsModalStatus}
          />
        )}
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IDistributorPageProps): Types.IDistributorPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IDistributorPageProps = Object.assign({}, ownProps, {
    result: store.state.distributor_invigilator_change_page && store.state.distributor_invigilator_change_page.results,
    term_type: store.state.term_type,
    term_id: store.state.term_id,
    distribution_in_progress_status: store.state.distribution_in_progress_status,
    selectOptions: store.state.select_options && store.state.select_options.distributorInvigilatorChangePage,
    general_settings: store.state.general_settings,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.examPeriodModal) {
    return (
      !!equal(
        prev.state.distributor_invigilator_change_page && prev.state.distributor_invigilator_change_page.results,
        next.state.distributor_invigilator_change_page && next.state.distributor_invigilator_change_page.results
      ) &&
      !!equal(
        prev.state && prev.state.term_type,
        next.state && next.state.term_type
      ) &&
      !!equal(
        prev.state && prev.state.term_id,
        next.state && next.state.term_id
      ) &&
      !!equal(
        prev.state && prev.state.distribution_in_progress_status,
        next.state && next.state.distribution_in_progress_status
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.distributorInvigilatorChangePage,
        next.state.select_options && next.state.select_options.distributorInvigilatorChangePage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ChangeInvigilatorModal);

export default container;