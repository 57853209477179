import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Sketches from "..";
import Translator from "../../../services/translate-factory";
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import { Log } from 'ng2-logger';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SettingsEventModuleStatus } from "../../../store/constants/setting-const";


const L = Log.create('PublicSketches');
const T = Translator.create();

export const PublicSketchesIn: React.FC<any> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const checkEventModuleActive = () => {
        const { general_settings } = props;
        return (
            general_settings && general_settings.event_module_status === SettingsEventModuleStatus.Active
        );
    };

    const checkSketchesActive = () => {
        const { general_settings } = props;

        const campusSketches = general_settings && general_settings.show_public_campus_settlements
        const buildingSketches = general_settings && general_settings.show_public_building_settlements

        return campusSketches || buildingSketches
    }

    const backToLogin = () => {
        props.dispatch(Actions.Navigation(Constants.routes.LOGIN))
    }

    useEffect(() => {
        try {
            const { location: { pathname } } = props;

            if (pathname.startsWith('/login/sketches')) {

                if (!checkEventModuleActive() || !checkSketchesActive()) {
                    backToLogin();
                    return
                }

                setIsOpen(true)
            } else {
                setIsOpen(false)
            }

        } catch (error) {
            L.error("useEffect Params okunamadi", error)
        }

    }, [props.location])

    return (
        <Modal
            className="pt-0"
            style={{ maxWidth: '100%', padding: '0 15px' }}
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            onClosed={backToLogin}
        >
            <ModalHeader toggle={() => setIsOpen(!isOpen)}>
                {T.t('sketches')}
            </ModalHeader>
            <ModalBody className="tw-bg-[#fbfaf6] tw-rounded-lg tw-p-0">
                <Sketches publicSketches={true} />
            </ModalBody>
        </Modal>
    )
}


const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICampusPageProps): Types.ICampusPageProps => {
    if (!store || !store.state) {
        return ownProps;
    }
    const newProps: Types.ICampusPageProps = Object.assign({}, ownProps, {
        general_settings: store.state.general_settings,
    });
    return newProps;
};
const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
    return equal(prev, next)
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const PublicSketches = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(withRouter(PublicSketchesIn));

export default PublicSketches;
