import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import './rbc.css'
import { connect } from 'react-redux';
import { Modal } from 'antd';
import Translator from '../../services/translate-factory';
import * as Types from '../../store/types';
import * as Constants from '../../store/constants/all';
import { debounce } from 'lodash';
import Preview, { isAuthorized, PreviewHeader } from './add-preview';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import EventDetail from './add-detail';
import Button from '../../components/button';
import cn, { flexCenter, flexCol, flexIC, flexJB, flexRow, gap2, gap4 } from '../../components/ui/Tailwind';
import AvailablePlace from './add-place';
import { RiSave2Fill } from 'react-icons/ri';
import { RBCEventStatus } from './constants';
import * as Actions from '../../store/actions/general';
import { EventFormValidation } from './validations/form-validation';
import moment from 'moment';

const L = Log.create('event-calendar-detail.tsx');
const T = Translator.create();

interface EventCalendarProps {
    event?: Types.RBCEvents
    isOpen: boolean
    onClose: () => void,
    afterClose: () => void,
    dispatch?: any,
    user?: Types.IAuthUser
    term_id?: number
}

const EventCalendarDetailIn: React.FC<EventCalendarProps> = ({ event, isOpen, onClose, afterClose, user, dispatch, term_id }) => {
    const formikRef = useRef<Formik<Types.IExtendedEvent>>(null)
    const [, forceRender] = useReducer(x => x + 1, 0);
    const key = event && event.event_id || 0;

    const [showEdit, setShowEdit] = useState(false)
    const [showPlace, setShowPlace] = useState(false)
    const [backupEvent, setBackupEvent] = useState<Types.IExtendedEvent | undefined>(undefined)

    const handleLanguageChange = useCallback(
        debounce(() => {
            forceRender(1);
        }, 1000),
        []
    );
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);

        return () => {
            T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        };
    }, []);

    const searchEventCalendar = () => {
        // loads calendar events
        dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_SOLUTION_SEARCH, { term_id: term_id }, 'event-solution-spin'));
    }

    const handleSubmit = (values: Types.IExtendedEvent, formikActions: FormikActions<Types.IExtendedEvent>) => {
        setSubmitting(true)
        if (!values.selectedPlace || !values.event_type)
            return formikActions.setErrors({ event_type: T.t('gen_cannot_leave_empty'), selectedPlace: T.t('gen_cannot_leave_empty') })

        dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_EVENT_UPDATE, {
            ...values,
            classroom_id: values.selectedPlace.id,
            event_type_id: Number(values.event_type.value),
            start_date: moment(values.start_date).format("DD.MM.YYYY HH:mm"),
            end_date: moment(values.end_date).format("DD.MM.YYYY HH:mm"),
            user_ids: values.responsibles && values.responsibles.map((item) => item.value),

        }, 'submit-save-event', () => {
            setSubmitting(false)
            searchEventCalendar()
            // close modal after 1 second
            const timer = setTimeout(() => {
                onClose()
            }, 1000)
            return () => clearTimeout(timer)
        }));
    }

    const areYouSureToDelete = () => {
        dispatch(
            Actions.ShowModal({
                title: T.t('gen_delete_action'),
                body: (
                    <div>
                        {T.t('gen_delete_proccess')}
                    </div>
                ),
                name: 'delete_event',
                icon: 'delete',
                iconColor: 'red',
                confirm: T.t('gen_yes'),
                cancel: T.t('gen_cancel'),
                onConfirm: () => {
                    handleDelete();
                }
            })
        );
    }

    const handleDelete = () => {
        if (!event) return
        dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_EVENT_DELETE, {
            event_id: event.event_id
        }, 'submit-delete-event', () => {
            setSubmitting(false)
            searchEventCalendar()
            // close modal after 1 second
            const timer = setTimeout(() => {
                onClose()
            }, 1000)
            return () => clearTimeout(timer)
        }))
    }

    const handleFormSubmit = () => {
        if (formikRef.current) {
            formikRef.current.submitForm();
        } else {
            console.error('Formik ref is not available yet.');
        }
    }


    return (
        <Modal
            style={{ maxWidth: '92rem', padding: '0 15px' }}
            styles={{
                wrapper: {
                    zIndex: 1052
                },
                mask: {
                    zIndex: 1051
                }
            }}
            width="100%"
            open={isOpen}
            okText={T.t('gen_save')}
            cancelText={T.t('gen_cancel')}
            footer={
                <div className={cn(flexRow, flexIC, gap2, "tw-ml-auto tw-justify-end")}>
                    <Button borderColor='light-gray' onClick={onClose}>{T.t("gen_cancel")} </Button>
                    {isAuthorized(user) && <Button icon='delete' color='red' onClick={areYouSureToDelete}>{T.t("gen_delete")}</Button>}
                    {isAuthorized(user) && <Button icon='save-fa' color='blue' onClick={handleFormSubmit}>{T.t("gen_save")}</Button>}
                </div>
            }
            okButtonProps={{
                loading: submitting,
                disabled: submitting,
                form: 'event-form',
                icon: <RiSave2Fill />,
                style: {
                    display: isAuthorized(user) ? "" : 'none'
                }

            }}
            onCancel={onClose}
            afterClose={afterClose}
        >

            <Formik
                initialValues={{
                    ...event,
                    status_approval: event && (event.status_approval || RBCEventStatus.PENDING),
                } || {}}
                onSubmit={handleSubmit}
                ref={formikRef}
                validationSchema={EventFormValidation(T)}
            >
                {(formikProps: FormikProps<Types.IExtendedEvent>) => {
                    const { values } = formikProps
                    const detailBackIsDisable = values.title && values.start_date && values.end_date && values.event_type
                    const isPlaceDone = values.selectedPlace

                    if (showEdit) return <div className={cn(flexCol, gap4)}>
                        <PreviewHeader props={formikProps} T={T} user={user} />
                        <div className='tw-relative tw-border-gray-200 tw-bg-white tw-border-solid tw-border tw-w-full tw-rounded-lg tw-p-8 tw-space-y-4 tw-col-span-2'>
                            <div className={cn(flexCenter, flexCol, gap2)}>
                                <div className='tw-flex tw-mr-auto tw-gap-2'>
                                    <Button size='sm' icon='back-ri' disabled={!detailBackIsDisable} borderColor='light-gray' onClick={() => {
                                        setShowEdit(false)
                                    }}>
                                        {T.t("gen_previous")}
                                    </Button>
                                    <Button size='sm' borderColor='light-gray' onClick={() => {
                                        formikProps.setValues(backupEvent || {})
                                    }}>
                                        {T.t("gen_discard_changes")}
                                    </Button>
                                </div>
                                <EventDetail formikProps={formikProps} key={key} />
                            </div>
                        </div>
                    </div>
                    if (showPlace) return <div className={cn(flexCol, gap4)}>
                        <PreviewHeader props={formikProps} T={T} user={user} />
                        <div className='tw-relative tw-border-gray-200 tw-bg-white tw-border-solid tw-border tw-w-full tw-rounded-lg tw-p-8 tw-space-y-4 tw-col-span-2'>
                            <div className={cn(flexCenter, flexCol, gap2)}>
                                <div className='tw-flex tw-mr-auto tw-gap-2'>
                                    <Button size='sm' icon='back-ri' disabled={!isPlaceDone} borderColor='light-gray' onClick={() => {
                                        setShowPlace(false)
                                    }}>
                                        {T.t("gen_previous")}
                                    </Button>
                                    <Button size='sm' borderColor='light-gray' onClick={() => {
                                        formikProps.setValues(backupEvent || {})
                                    }}>
                                        {T.t("gen_discard_changes")}
                                    </Button>
                                </div>
                                <AvailablePlace formikProps={formikProps} key={key} />
                            </div>
                        </div>
                    </div>

                    return <Preview formikProps={formikProps}
                        setShowEdit={(e) => {
                            setBackupEvent(values)
                            setShowEdit(e)
                        }}
                        setShowPlace={(e) => {
                            setBackupEvent(values)
                            setShowPlace(e)
                        }}
                        user={user} />
                }}
            </Formik>
        </Modal>
    );
}

const mapStateToProps = (
    store: Types.IPersistedState,
    ownProps: EventCalendarProps
): EventCalendarProps => {
    if (!store) {
        return ownProps;
    }
    const newProps: EventCalendarProps = {
        ...ownProps,
        user: store.state.user,
        term_id: store.state.term_id,
    };
    return newProps;
};

const dispatchProps = (dispatch: any) => ({
    dispatch
});

const EventCalendarDetail = connect(mapStateToProps, dispatchProps)(EventCalendarDetailIn);

export default EventCalendarDetail