import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import { ValueType } from 'react-select/lib/types';
import { NotificationSearchInitialValues, NotificationStatuses, NotificationTypes } from '../../store/constants/notification-const';
import Spinner from '../../components/templates/spinner';
import MainLayout from '../layouts/main-layout';
import APlanHeader from '../../components/templates/aplan-header';
import SortedColumn from '../../components/table/sorted-column';
import Paginate from '../../components/table/paginate';
import Translator from '../../services/translate-factory';
import { formatDate } from '../../util/language';

const T = Translator.create();
const L = Log.create('NotificationPage');

class NotificationPage extends Component<Types.INotificationPageProps, Types.INotificationPageState> {
  state: Types.INotificationPageState = {
    filters: NotificationSearchInitialValues,
    filterIsOpen: false,
    all_ids: [],
    selected_ids: [],
    notificationList: [],
    notification_id: undefined
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    window.scrollTo(0, 0);
    this.searchNotifications();
    this.getNotificationSelectOptions();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  searchNotifications() {
    this.props.dispatch(
      Actions.ApiRequest(
        Constants.notification.NOTIFICATION_LIST_SEARCH,
        this.state.filters,
        'notification-list-spin'
      )
    );
  }

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchNotifications();
  };

  getNotificationSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.notification.NOTIFICATION_GET_SELECT_OPTIONS, 'notification-list-spin'));
  }

  onPageChange = (page: number, size?: number) => {
    this.setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        page: page,
        size: size ? size : 10
      }
    }), this.searchNotifications)
  };

  onSizeChange = (size: number) => {
    this.setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        size: size
      }
    }), this.searchNotifications)
  }
  onFormReset = () => {
    this.state.filters = Object.assign({}, NotificationSearchInitialValues);
    this.setState(this.state);
    this.searchNotifications();
  };

  onFilterNotification(model: Types.IFilterNotification, FormActions: FormikActions<Types.IFilterNotification>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchNotifications();
    FormActions.setSubmitting(false);
  }

  showDownloadFileModal = (e: React.MouseEvent<HTMLButtonElement>, notification_id: number) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_download_file_title'),
          body: T.t('gen_download_file_notification_body'),
          name: 'download_file',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            this.props.dispatch(
              Actions.ApiRequest(Constants.notification.NOTIFICATION_GET_SIGNED_URL_BY_ID, notification_id, '',
                (result: string, status: number) => {
                  if (status === 200) {
                    this.openLinkInNewTab(result);
                  }
                })
            );
          }
        })
      );
    }
  };

  openLinkInNewTab(url: string) {
    var win = window.open(url, '_blank');
    win && win.focus();
  }

  onNotificationDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, notification_ids: Array<number>) => {
    if (e && e.currentTarget.dataset && e.currentTarget.dataset.id) {
      let id = e.currentTarget.dataset.id;
      let name = e.currentTarget.dataset.name;
      const resultCallBack = () => {
        this.searchNotifications()
      };
      const onConfirmDelete = () => {
        this.props.dispatch(
          Actions.ApiRequest(Constants.notification.NOTIFICATION_DELETE, notification_ids, undefined, resultCallBack)
        );
      };
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_notification_will_be_deleted'),
          body: T.t('gen_notification_will_be_deleted_question'),
          name: 'notification_delete',
          icon: 'error_outline',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_no'),
          onConfirm: onConfirmDelete
        })
      );
    }
  };

  static getDerivedStateFromProps(props: Types.INotificationPageProps, state: Types.INotificationPageState) {
    let hasNewState: boolean = false;

    if (props.results) {
      hasNewState = true;
      state.notificationList = props.results;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let notificationList = this.state.notificationList ? this.state.notificationList : [];
    return (
      <MainLayout header={<APlanHeader />}>
        <div className="main list-notification" style={{ display: false ? 'none' : 'block' }}>
          <div className="container-fluid">
            <div className="row align-items-center mb-sm-4 mb-2">
              <div className="col-md-6 col-sm-4 col-12">
                <h4 className="mb-4 mb-sm-0">{T.t('gen_notifications')}</h4>
              </div>
              <div className="col-md-6 col-sm-8 col-12 text-right">
                <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                  <button
                    id='button_filter'
                    className="category-tag-square tag-glass float-right ml-3 mr-3"
                    style={{ margin: '5px' }}
                    onClick={() => {
                      this.state.filterIsOpen = !this.state.filterIsOpen;
                      this.setState(this.state);
                    }}
                  >
                    <i className="material-icons mr-2">filter_list</i>
                    <span>{T.t('gen_filter')}</span>
                  </button>
                </div>
              </div>
            </div>
            <div
              className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
              id="advance-search"
            >
              <div className="advance-search d-block mt-3">
                <Formik
                  initialValues={NotificationSearchInitialValues}
                  enableReinitialize={true}
                  onSubmit={(values, actions) => {
                    this.onFilterNotification(values, actions);
                  }}
                  onReset={this.onFormReset}
                >
                  {(props: FormikProps<Types.IFilterNotification>) => {
                    return (
                      <form onSubmit={props.handleSubmit}>
                        <div className="row">
                          <div className="col-12">
                            <h6>{T.t('gen_filter_parameters')}</h6>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_term')}</label>
                                <Select
                                  id='select_term'
                                  className="react-select"
                                  isMulti={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={false}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.terms
                                      ? this.props.selectOptions.terms
                                      : []
                                  }
                                  placeholder={T.t('gen_select_term')}
                                  value={props.values.terms ? props.values.terms : null}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: Array<Types.ISelectOption> = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('terms', list);
                                    props.setFieldValue(
                                      'term_ids',
                                      list.map((item) => item.value)
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_term')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_notification_type')}</label>
                                <Select
                                  id='notification_type'
                                  className="react-select"
                                  isMulti={true}
                                  closeMenuOnSelect={false}
                                  options={NotificationTypes(T)}
                                  placeholder={T.t('gen_select_type')}
                                  value={props.values.notification_types_select}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: Array<Types.ISelectOption> = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('notification_types_select', list);
                                    props.setFieldValue(
                                      'types',
                                      list.map((item) => item.value)
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_status')}</label>
                                <Select
                                  id='notification_status'
                                  className="react-select"
                                  isMulti={true}
                                  closeMenuOnSelect={false}
                                  options={NotificationStatuses(T)}
                                  placeholder={T.t('gen_select_status')}
                                  value={props.values.notification_status_select}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: Array<Types.ISelectOption> = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('notification_status_select', list);
                                    props.setFieldValue(
                                      'status',
                                      list.map((item) => item.value)
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_status')}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-6">
                            <button
                              id='button_arrow_upward'
                              type="button"
                              onClick={() => {
                                this.state.filterIsOpen = false;
                                this.setState(this.state);
                              }}
                              className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn mr-2"
                            >
                              <i className="material-icons">arrow_upward</i>
                            </button>
                          </div>
                          <div className="col-6 text-right">
                            <button
                              id='button_search'
                              type="button"
                              className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                              onClick={() => props.handleSubmit()}
                              disabled={props.isSubmitting}
                            >
                              <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                            </button>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
            <Spinner name="notification-list-spin" />
            <div className="white-container mt-4">
              <div className="row">
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <SortedColumn
                          datacell="status"
                          className="d-none d-sm-table-cell d-sm-table-cell text-center"
                          title={T.t('gen_status')}
                          sortkey="status"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="term_id"
                          className="d-none d-xl-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_term')}
                          sortkey="term_id"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_notification_type')}
                          sortkey="type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="d-none d-xl-table-cell d-xl-table-cell text-center">
                          <span className="text-center">{T.t('gen_description')} </span>
                        </th>
                        <SortedColumn
                          datacell="created_date"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_create_date')}
                          sortkey="created_date"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="d-none d-sm-table-cell d-sm-table-cell text-center">
                          <span className="text-center">{T.t('gen_report')} </span>
                        </th>
                        <th scope="col" className="text-right">
                          <span className="text-right">{T.t('gen_actions')}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {notificationList && notificationList.length
                        ? notificationList.map((item: Types.INotificationItem) => {
                          return (
                            <tr key={'notification-' + item.notification_id} data-title={item.description}>
                              <td scope="row" data-label={T.t('gen_status')} className="text-center">
                                <div className="tags ml-1 mr-4">
                                  <button
                                    id='button_status'
                                    className={
                                      `small-tag text-uppercase` +
                                      (item.status == 1 ? ` tag-green` : (item.status == 0 ? ` tag-orange` : ` tag-red`))
                                    }
                                  >
                                    {NotificationStatuses(T).find((x) => x.value === item.status)!.label}
                                  </button>
                                </div>
                              </td>
                              <td data-label={T.t('gen_term')} className="text-center">
                                {item.term_id == 0 ? "-" : ("ID: " + item.term_id + " - " + item.term_name)}
                              </td>
                              <td data-label={T.t('gen_notification_type')} className="text-center">
                                {NotificationTypes(T).find((x) => x.value === item.type)!.label}
                              </td>
                              <td data-label={T.t('gen_description')} className="text-center">
                                {item.description && item.description.length ?
                                  <div className="table-scrollable-td">{item.description}</div> : '-'}
                              </td>
                              <td data-label={T.t('gen_create_date')} className="text-center" >{formatDate(item.created_date, T)}</td>
                              <td data-label={T.t('gen_download')} className="text-center">
                                {item && ((item.status == 1 && item.type != 6) || (item.status != 0 && item.type == 2)) ? (
                                  <button
                                    id='button_download'
                                    className="category-tag-square tag-blue text-center"
                                    disabled={((item.status == 1) ? false : true)}
                                    onClick={(e) => this.showDownloadFileModal(e, item.notification_id)}>
                                    <i className="material-icons mr-2">download</i>
                                    {T.t('gen_download')}
                                  </button>
                                ) : ("-")}
                              </td>
                              <td data-label={T.t('gen_actions')} className="table-buttons">
                                <div className="table-buttons-wrapper">
                                  <button
                                    id='button_delete'
                                    data-toggle="tooltip"
                                    data-id={item.term_id}
                                    data-name={item.term_name}
                                    disabled={item && item.user_id == 0}
                                    onClick={(e) => this.onNotificationDelete(e, [item.notification_id])}
                                    title={T.t('gen_delete')}
                                    className="btn btn-light btn-sm table-button"
                                  >
                                    <span className="d-block" data-toggle="modal" data-target="#coursepleModal">
                                      <i className="material-icons">delete</i>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                        : null}
                    </tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    >
                      {this.props.results && this.props.results.length > 0 && (
                        <Paginate filters={this.props.filters} onPageChange={this.onPageChange} onSizeChange={this.onSizeChange} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.INotificationPageProps
): Types.INotificationPageProps => {
  if (!store) {
    return ownProps;
  }

  const newProps: Types.INotificationPageProps = Object.assign({}, ownProps, {
    results: store.state.notification_page && store.state.notification_page.results,
    filters: store.state.notification_page && store.state.notification_page.filters,
    selectOptions: store.state.select_options && store.state.select_options.notificationsPage,
    user: store.state.user,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.notification_page) {
    return (
      !!equal(
        prev.state.notification_page && prev.state.notification_page.results,
        next.state.notification_page && next.state.notification_page.results
      ) &&
      !!equal(
        prev.state.notification_page && prev.state.notification_page.filters,
        next.state.notification_page && next.state.notification_page.filters
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.notificationsPage,
        next.state.select_options && next.state.select_options.notificationsPage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(NotificationPage);

export default container;
