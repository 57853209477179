import { FormikActions } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { ExamCalendarInitialValues } from '../../store/constants/exam-period-const';
import * as Types from '../../store/types';
import Spinner from '../../components/templates/spinner';
import Schedule from './schedule';
import TermWeekDates from './term-week-dates';
import InstructorHours from './instructor-hours';
import ClassroomHours from './classroom-hours';
import CourseHours from './course-hours';
import ClassroomPriority from './classroom-priority/classroom-priority-main';
import Translator from '../../services/translate-factory';
import DistributorListPage from '../distributor/distributor-list-page';
import { routes as Routes } from '../../store/constants/routes';
import { allRestirectedRoles, instructorAndManagerRoles, isUserEquals } from '../../util/authorize';
import { UserRole } from '../../store/constants/enums';

const T = Translator.create();
const Logger = Log.create('CoursePeriodForm');

function getInitialState(): Types.IExamCalendarModalState {
  const initialValues: Types.IExamCalendarModalState = {
    model: Object.assign({}, ExamCalendarInitialValues)
  };
  return Object.assign({}, initialValues);
}

class CoursePeriodForm extends Component<Types.IExamCalendarModalProps, Types.IExamCalendarModalState> {
  state: Types.IExamCalendarModalState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.getGeneralSettings();
    this.tabRedirection();

  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES, {
          reset: true
        })
      );
      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_SAVE_SCHEDULE, {
          reset: true
        })
      );
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  getGeneralSettings() {
    this.props.dispatch(Actions.ApiRequest(Constants.setting.GET_GENERAL_SETTINGS, 'general-settings-spin'));
  }

  tabReloaded = () => {
    if (this.state.tab_reloaded) {
      this.state.tab_reloaded = false;
      return true;
    }
    return false;
  };

  tabRedirection() {
    let selectedTab = Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES

    if (window.location.pathname === Routes.COURSE_PERIODS_PLANNING_TERM_WEEK_DATES) {
      selectedTab = Constants.exam_period.COURSE_PERIOD_GET_TERM_WEEK_DATES
    }

    if (isUserEquals(this.props.user, instructorAndManagerRoles)) {
      if (this.props.general_settings && (this.props.general_settings.restrict_hours_type_instructors === 1 || this.props.general_settings.restrict_hours_type_program_managers === 1)) {
        selectedTab = Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS
      }
    }

    if (selectedTab != null) {
      let tabs =
        this.state.model.tabManager &&
        this.state.model.tabManager.map((tab) => {
          return tab.key == selectedTab ? { key: tab.key, value: true } : { key: tab.key, value: false };
        });
      this.state.model.tabManager = tabs;
      this.isActiveTab(selectedTab);
      this.selectActiveTab(selectedTab);
    }
  }

  onFormSave = (model: Types.IExamCalendarModalItem, FormActions: FormikActions<Types.IExamCalendarModalItem>) => {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }
        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201) {
        this.setClose(true);
      }
    };

    if (this.props.coursePeriodId) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_UPDATE, model, 'exam-period-form-spin', resultCallback)
      );
    } else {
      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_CREATE, model, 'exam-period-form-spin', resultCallback)
      );
    }
    FormActions.setSubmitting(false);
  };

  selectActiveTab = (tabName: string) => {
    let tabs =
      this.state.model.tabManager &&
      this.state.model.tabManager.map((tab) => {
        return tab.key == tabName ? { key: tab.key, value: true } : { key: tab.key, value: false };
      });

    let activeTab = tabs && tabs.find((item) => item.value == true);

    let tabConstant = activeTab && activeTab.key;
    this.state.tab_reloaded = true;
    this.state.model.tabManager = tabs;
    this.setState(this.state);

    if (tabConstant == 'EXAM_PERIOD_GET_PERIOD_DATES') {
      this.props.dispatch(
        Actions.ApiRequest(
          Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES,
          this.props.coursePeriodId,
          'exam-period-modal-tab-spin'
        )
      );
    }

    if (tabConstant == 'EXAM_PERIOD_GET_INSTRUCTOR_HOURS') {
      let model: Types.IFilterExamPeriodCalendarModal = {
        term_id: this.props.coursePeriodId,
        active_tab: 0,
        page: 1,
        total: -1,
        size: 10
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_INSTRUCTOR_HOURS, model, 'exam-period-modal-tab-spin')
      );
    }

    if (tabConstant == 'EXAM_PERIOD_GET_CLASSROOM_HOURS') {
      let model: Types.IFilterExamPeriodCalendarModal = {
        term_id: this.props.coursePeriodId,
        page: 1,
        total: -1,
        size: 10
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS, model, 'exam-period-modal-tab-spin')
      );
    }

    if (tabConstant == 'COURSE_PERIOD_GET_CLASSROOM_PRIORITY') {
      let model: Types.IFilterExamPeriodCalendarModal = {
        term_id: this.props.coursePeriodId,
        page: 1,
        total: -1,
        size: 10
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.COURSE_PERIOD_GET_CLASSROOM_PRIORITY, model, 'exam-period-modal-tab-spin')
      );
    }

    if (tabConstant == 'EXAM_PERIOD_GET_COURSE_HOURS' || tabConstant == 'EXAM_PERIOD_GET_EXAM_HOURS') {
      let model: Types.IFilterExamPeriodCalendarModal = {
        term_id: this.props.coursePeriodId,
        page: 1,
        total: -1,
        size: 10,
        status: [1]
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_COURSETERM_COURSE_HOURS, model, 'exam-period-modal-tab-spin')
      );
    }

    if (tabConstant == 'COURSE_PERIOD_GET_TERM_WEEK_DATES') {
      let model: Types.IFilterExamPeriodCalendarModal = {
        term_id: this.props.coursePeriodId,
        page: 1,
        total: -1,
        size: 10,
        status: [1]
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.COURSE_PERIOD_GET_TERM_WEEK_DATES, model, 'exam-period-modal-tab-spin')
      );
    }

    if (tabConstant == 'DISTRIBUTOR:GET_DISTRIBUTION_LIST') {
      let model: Types.IFilterDistributor = {
        term_ids: [this.props.coursePeriodId ? this.props.coursePeriodId : -1],
        page: 1,
        total: -1,
        size: 10
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_GET_DISTRIBUTION_LIST, model, 'distribution-list-spin')
      );
    }
  };

  isActiveTab = (tabName: string) => {
    let activeTab = this.state.model.tabManager && this.state.model.tabManager.find((item) => item.key == tabName);
    if (activeTab) {
      return activeTab.value;
    } else return false;
  };

  static getDerivedStateFromProps(props: Types.IExamCalendarModalProps, state: Types.IExamCalendarModalState) {
    let hasNewState: boolean = false;
    if (props.coursePeriodId && props.coursePeriodId != state.model.term_id) {
      state.model.term_id = props.coursePeriodId;
      props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES, {
          reset: true
        })
      );
      props.dispatch(
        Actions.ApiRequest(
          Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES,
          props.coursePeriodId,
          'exam-period-modal-tab-spin'
        )
      );
      hasNewState = true;
    }

    if (state.model.name !== props.name) {
      hasNewState = true;
      state.model.name = props.name;
    }

    if (hasNewState) {
      return state;
    } else if (!props.coursePeriodId && state.model.term_id) {
      return getInitialState();
    } else {
      return null;
    }
  }

  render() {
    return (
      <div
        className="main editor-screen-main"
        style={{
          display: this.props.formIsOpen ? 'block' : 'none'
        }}
      >
        <div
          id="editorScreen"
          className="white-container mt-4 editor-screen collapse editor-screen"
          style={{
            display: 'block',
            minHeight: '100vh',
            marginTop: '0 !important'
          }}
        >
          <div className="row">
            <div className="col-10">
              <h5 className="editor-title">
                <i className="material-icons mr-2" style={{ verticalAlign: 'sub' }}>
                  event_note
                </i>
                {this.state.model.name ? this.state.model.name : 'Yükleniyor...'}
              </h5>
            </div>
            <div className="col-2">
              <button
                type="button"
                data-toggle="collapse"
                data-target="#editorScreen"
                onClick={this.setCloseForm}
                aria-expanded="true"
                className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn float-right"
              >
                <i className="material-icons">close</i>
              </button>
              {/* <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseForm}
                >
               <i className="material-icons">close</i>
                <span>ESC</span>
                </button> */}
            </div>
          </div>
          <div className="row">
            <Spinner name="exam-period-modal-tab-spin" />
            <div className="col-12">
              <nav className="inner-page-nav">
                <div className="nav nav-tabs nav-fill mb-4" id="nav-tab" role="tablist">
                  <a
                    className={
                      this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES)
                        ? 'nav-item nav-link active'
                        : 'nav-item nav-link'
                    }
                    id="dates-tab"
                    data-toggle="tab"
                    href="#dates"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    {T.t('gen_calendar_weekly_hours')}
                  </a>
                  {this.props.user && this.props.user.role === 's' ? (
                    <a
                      className={
                        this.isActiveTab(Constants.exam_period.COURSE_PERIOD_GET_TERM_WEEK_DATES)
                          ? 'nav-item nav-link active'
                          : 'nav-item nav-link'
                      }
                      onClick={() => this.selectActiveTab(Constants.exam_period.COURSE_PERIOD_GET_TERM_WEEK_DATES)}
                      id="week-dates-tab"
                      data-toggle="tab"
                      href="#weekdates"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      {T.t('gen_term_week_dates')}
                    </a>
                  ) : null}
                  {this.props.user && (this.props.user.role == 'e' || this.props.user.role == 'd' || this.props.user.role == 'p' || this.props.user.role == 'r') ? null : (
                    <a
                      className={
                        this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS)
                          ? 'nav-item nav-link active'
                          : 'nav-item nav-link'
                      }
                      onClick={() => this.selectActiveTab(Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS)}
                      id="classrooms-tab"
                      data-toggle="tab"
                      href="#classrooms"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      {T.t('gen_classrooms')}
                    </a>
                  )}
                  {this.props.user && (this.props.user.role == 'e' || this.props.user.role == 'd' || this.props.user.role == 'p' || this.props.user.role == 'r') ? null : (
                    <a
                      className={
                        this.isActiveTab(Constants.exam_period.COURSE_PERIOD_GET_CLASSROOM_PRIORITY)
                          ? 'nav-item nav-link active'
                          : 'nav-item nav-link'
                      }
                      onClick={() => this.selectActiveTab(Constants.exam_period.COURSE_PERIOD_GET_CLASSROOM_PRIORITY)}
                      id="classroom_priority-tab"
                      data-toggle="tab"
                      href="#classroom_priority"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      {T.t('gen_classroom_priority')}
                    </a>
                  )}
                  {this.props.user && (this.props.user.role == 'e' || this.props.user.role == 'd' || this.props.user.role == 'p' || this.props.user.role == 'r') ? null : (
                    <a
                      className={
                        this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_COURSE_HOURS)
                          ? 'nav-item nav-link active'
                          : 'nav-item nav-link'
                      }
                      onClick={() => this.selectActiveTab(Constants.exam_period.EXAM_PERIOD_GET_COURSE_HOURS)}
                      id="course-tab"
                      data-toggle="tab"
                      href="#courses"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      {T.t('gen_courses')}
                    </a>
                  )}
                  {this.instructorTab()
                    ? <a
                      className={
                        this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_INSTRUCTOR_HOURS)
                          ? 'nav-item nav-link active'
                          : 'nav-item nav-link'
                      }
                      onClick={() => this.selectActiveTab(Constants.exam_period.EXAM_PERIOD_GET_INSTRUCTOR_HOURS)}
                      style={{
                        display: isUserEquals(this.props.user, allRestirectedRoles) ? 'none' : ''
                      }}
                      id="personnals-tab"
                      data-toggle="tab"
                      href="#personnals"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      {T.t('gen_instructors')}
                    </a>
                    : null
                  }
                  <a
                    className={
                      this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS)
                        ? 'nav-item nav-link active'
                        : 'nav-item nav-link'
                    }
                    onClick={() => this.selectActiveTab(Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS)}
                    id="exam-tab"
                    data-toggle="tab"
                    href="#exams"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    {T.t('gen_calendar_course_hours_and_classrooms')}
                  </a>

                  {this.props.user && this.props.user.role === 's' &&
                    (
                      <a
                        className={
                          this.isActiveTab(Constants.exam_period.DISTRIBUTOR_GET_DISTRIBUTION_LIST)
                            ? 'nav-item nav-link active'
                            : 'nav-item nav-link'
                        }
                        onClick={() => this.selectActiveTab(Constants.exam_period.DISTRIBUTOR_GET_DISTRIBUTION_LIST)}
                        id="distribute-exams-tab"
                        data-toggle="tab"
                        href="#distribute-exams"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('gen_distribute_activities')}
                      </a>
                    )}
                </div>
              </nav>

              <div className="tab-content" id="nav-tabContent">
                <div
                  className={
                    this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES)
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  }
                  id="dates"
                  role="tabpanel"
                  aria-labelledby="dates-tab"
                >
                  <Schedule />
                </div>
                <div
                  className={
                    this.isActiveTab(Constants.exam_period.COURSE_PERIOD_GET_TERM_WEEK_DATES)
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  }
                  id="weekdates"
                  role="tabpanel"
                  aria-labelledby="week-dates-tab"
                >
                  <TermWeekDates />
                </div>
                <div
                  className={
                    this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_INSTRUCTOR_HOURS)
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  }
                  id="personnals"
                  role="tabpanel"
                  aria-labelledby="personnals-tab"
                >
                  {this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_INSTRUCTOR_HOURS) ? (
                    <InstructorHours tabReloaded={this.tabReloaded} />
                  ) : null}
                </div>
                <div
                  className={
                    this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS)
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  }
                  id="classrooms"
                  role="tabpanel"
                  aria-labelledby="classrooms-tab"
                >
                  {this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS) ? (
                    <ClassroomHours />
                  ) : null}
                </div>
                <div
                  className={
                    this.isActiveTab(Constants.exam_period.COURSE_PERIOD_GET_CLASSROOM_PRIORITY)
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  }
                  id="classroom_priority"
                  role="tabpanel"
                  aria-labelledby="classroom_priority-tab"
                >
                  {this.isActiveTab(Constants.exam_period.COURSE_PERIOD_GET_CLASSROOM_PRIORITY) ? (
                    <ClassroomPriority />
                  ) : null}
                </div>
                <div
                  className={
                    this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_COURSE_HOURS)
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  }
                  id="courses"
                  role="tabpanel"
                  aria-labelledby="course-tab"
                >
                  {this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_COURSE_HOURS) ? (
                    <CourseHours calledFromCourseTab={true} />
                  ) : null}
                </div>
                <div
                  className={
                    this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS)
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  }
                  id="exams"
                  role="tabpanel"
                  aria-labelledby="exam-tab"
                >
                  {this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS) ? (
                    <CourseHours calledFromCourseTab={false} />
                  ) : null}
                </div>
                <div
                  className={
                    this.isActiveTab(Constants.exam_period.DISTRIBUTOR_GET_DISTRIBUTION_LIST)
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  }
                  id="distribute-exams"
                  role="tabpanel"
                  aria-labelledby="distribute-exams-tab"
                >
                  {this.isActiveTab(Constants.exam_period.DISTRIBUTOR_GET_DISTRIBUTION_LIST) ? <DistributorListPage /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  instructorTab() {
    if (!this.props.user || !this.props.general_settings) {
      return false
    }

    if (UserRole.SYSTEM === this.props.user.role) {
      return true
    }

    if ([UserRole.INSTRUCTOR, UserRole.PANEL].includes(this.props.user.role as UserRole)) {
      return this.props.general_settings.restrict_hours_type_instructors !== 2
    }

    if ([UserRole.MANAGER].includes(this.props.user.role as UserRole)) {
      return this.props.general_settings.restrict_hours_type_program_managers !== 2
    }
    return false
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IExamCalendarModalProps
): Types.IExamCalendarModalProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IExamCalendarModalProps = Object.assign({}, ownProps, {
    model: store.state.examPeriodModal,
    name:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.exam_dates &&
      store.state.examPeriodModal.exam_dates.name,
    user: store.state.user,
    general_settings: store.state.general_settings,
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.examPeriodModal) {
    return (
      !!equal(
        prev.state.examPeriodModal,
        next.state.examPeriodModal) &&
      !!equal(
        prev.state && prev.state.general_settings,
        next.state && next.state.general_settings
      ));
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CoursePeriodForm);

export default container;
