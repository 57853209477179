import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Translator from "../../../services/translate-factory";
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import { Log } from 'ng2-logger';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { TermType } from "../../../store/constants/general";
import EventCalendarPure from "../event-calendar-pure";
import { SettingsEventModuleStatus } from "../../../store/constants/setting-const";


const L = Log.create('PublicSketches');
const T = Translator.create();

const PublicEventCalendarIn: React.FC<any> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [termId, setTermId] = useState<number>(-1);

    const checkEventModuleActive = () => {
        const { general_settings } = props;
        return (
            general_settings && general_settings.event_module_status === SettingsEventModuleStatus.Active
        );
    };

    const checkEventsActive = () => {
        const { general_settings } = props;

        return general_settings && general_settings.show_public_event_calendar
    }

    useEffect(() => {
        try {
            const {
                location: { pathname },
                match: { params }
            } = props;

            // /login/ + {term_id} + /event-period/calendar
            const regex = /^\/login\/(\d+)\/event-period\/calendar/;
            const match = pathname.match(regex);

            if (match) {
                if (!checkEventModuleActive() || !checkEventsActive()) {
                    backToLogin();
                    return
                }
                setTermId(Number(params.term_id));
                setIsOpen(true);
            } else {
                setIsOpen(false);
            }
        } catch (error) {
            L.error("useEffect Params okunamadi", error);
            backToLogin();
        }

    }, [props.location]);

    const backToLogin = () => {
        props.dispatch(Actions.Navigation(Constants.routes.LOGIN))
    }
    return (
        <Modal
            className="pt-0"
            style={{ maxWidth: '100%', padding: '0 15px' }}
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            onClosed={backToLogin}
        >
            <ModalHeader toggle={() => setIsOpen(!isOpen)}>
                {T.t('gen_event_calendar')}
            </ModalHeader>
            <ModalBody className="tw-bg-[#fbfaf6] tw-rounded-lg tw-p-0">
                <EventCalendarPure publicCalendar={true} term_id={termId} term_type={TermType.EVENT} />
            </ModalBody>
        </Modal>
    )
}


const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICampusPageProps): Types.ICampusPageProps => {
    if (!store || !store.state) {
        return ownProps;
    }
    const newProps: Types.ICampusPageProps = Object.assign({}, ownProps, {
        general_settings: store.state.general_settings,
    });
    return newProps;
};
const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
    return equal(prev, next)
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const PublicEventCalendar = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(withRouter(PublicEventCalendarIn));

export default PublicEventCalendar;
