import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

export const constants = {
  INSTRUCTOR_LIST_SEARCH: 'INSTRUCTOR:LIST_SEARCH',
  INSTRUCTOR_CREATE: 'INSTRUCTOR:CREATE',
  INSTRUCTOR_UPDATE: 'INSTRUCTOR:UPDATE',
  INSTRUCTOR_DELETE: 'INSTRUCTOR:DELETE',
  INSTRUCTOR_GET_BY_ID: 'INSTRUCTOR:GET_BY_ID',
  INSTRUCTOR_GET_SELECT_OPTIONS: 'INSTRUCTOR:GET_SELECT_OPTIONS',
  INSTRUCTOR_CREATE_EXCEL_BULK: 'INSTRUCTOR:CREATE_EXCEL_BULK',
  INSTRUCTOR_GET_PROGRAMS_BY_FACULTIES: 'INSTRUCTOR:GET_PROGRAMS_BY_FACULTIES',
  INSTRUCTOR_GET_INVIGILATOR_PROGRAMS_BY_FACULTIES: 'INSTRUCTOR:GET_INVIGILATOR_PROGRAMS_BY_FACULTIES',
  INSTRUCTOR_CHANGE_ALL_DATA: 'INSTRUCTOR:CHANGE_ALL_DATA'
};

export const InstructorSearchInitialValues: Types.IFilterInstructor = {
  instructor_code: '',
  name: '',
  email: '',
  status: [],
  daily_max_class: undefined,
  daily_max_classes: [],
  invigilator_levels: [],
  invigilation_campuses: [],
  invigilation_faculties: [],
  programs: [],
  faculties: [],
  invigilation_campus_ids: undefined,
  max_invigilation_duty: undefined,
  max_time_bw_consecutive_invigilations: undefined,
  daily_max_duty: undefined,
  invigilation_faculty_ids: undefined,
  term_id: undefined,
  add_list: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
  select_all: false
};

export const InstructorHoursSearchInitialValues: Types.IFilterInstructor = {
  instructor_code: '',
  name: '',
  email: '',
  status: [1],
  daily_max_class: undefined,
  invigilator_levels: [],
  invigilation_campuses: [],
  invigilation_faculties: [],
  invigilation_campus_ids: undefined,
  max_invigilation_duty: undefined,
  max_time_bw_consecutive_invigilations: undefined,
  daily_max_duty: undefined,
  invigilation_faculty_ids: undefined,
  term_id: undefined,
  add_list: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
  select_all: false
};

export const InstructorFormInitialValues: Types.IInstructorItem = {
  instructor_id: undefined,
  instructor_code: '',
  status: 1,
  title: undefined,
  staff_type: undefined,
  faculty_of_duty: undefined,
  faculty_of_duty_id: undefined,
  program_of_duty: undefined,
  program_of_duty_id: undefined,
  name: '',
  email: '',
  mobile: '',
  description: '',
  invigilator_level: undefined,
  daily_max_class: 8,
  weekly_max_day: 5,
  max_invigilation_duty: 1000,
  max_time_bw_consecutive_invigilations: 8,
  daily_max_duty: 8,
  invigilation_campuses: [],
  invigilation_campus_ids: [],
  invigilation_faculty_ids: [],
  invigilation_program_ids: [],
  invigilation_faculties: [],
  invigilation_programs: [],
  faculties: [],
  programs: [],
  administrative_positions: [],
  administrative_position_options: [],
  contractual_workload: undefined
};

export const InvigilatorLevels = (T: Translator, opts?: any) => [
  { label: T.t('gen_very_high', opts), value: 5 },
  { label: T.t('gen_high', opts), value: 4 },
  { label: T.t('gen_medium', opts), value: 3 },
  { label: T.t('gen_low', opts), value: 2 },
  { label: T.t('gen_very_low', opts), value: 1 },
  { label: T.t('gen_doesnt_supervise', opts), value: 0 }
];

export const Titles = (T: Translator, opts?: any) => [
  { label: T.t('gen_academic_exp', opts), value: 'Akademik Uzm.' },
  { label: T.t('gen_coach', opts), value: 'Antrenör' },
  { label: T.t('gen_ra', opts), value: 'Araş. Gör.' },
  { label: T.t('gen_ra_phd', opts), value: 'Araş. Gör. Dr.' },
  { label: T.t('gen_researcher', opts), value: 'Araştırmacı' },
  { label: T.t('gen_marine_instructor', opts), value: 'Denizci Eğitmen' },
  { label: T.t('gen_language_instructor', opts), value: 'Dil Eğitmeni' },
  { label: T.t('gen_director', opts), value: 'Direktör' },
  { label: T.t('gen_assoc', opts), value: 'Doç.' },
  { label: T.t('gen_assoc_dr', opts), value: 'Doç. Dr.' },
  { label: T.t('gen_dr', opts), value: 'Dr.' },
  { label: T.t('gen_dr_instr', opts), value: 'Dr. Öğr. Üyesi' },
  { label: T.t('gen_education_consultant', opts), value: 'Eğitim Danışmanı' },
  { label: T.t('gen_med_phys_spc', opts), value: 'Med. Fiz. Uzm.' },
  { label: T.t('gen_lectr', opts), value: 'Okutman' },
  { label: T.t('gen_instr', opts), value: 'Öğr. Gör.' },
  { label: T.t('gen_instr_dr', opts), value: 'Öğr. Gör. Dr.' },
  { label: T.t('gen_instr_lecturer', opts), value: 'Öğr. Gör. (Okutman)' },
  { label: T.t('gen_staff', opts), value: 'Personel' },
  { label: T.t('gen_prof', opts), value: 'Prof.' },
  { label: T.t('gen_prof_dr', opts), value: 'Prof. Dr.' },
  { label: T.t('gen_project_adv', opts), value: 'Proje Danışmanı' },
  { label: T.t('gen_responsible', opts), value: 'Sorumlu' },
  { label: T.t('gen_technical_exp', opts), value: 'Teknik Uzm.' },
  { label: T.t('gen_thesis_adv', opts), value: 'Tez Danışmanı' },
  { label: T.t('gen_exp', opts), value: 'Uzm.' },
  { label: T.t('gen_exp_dr', opts), value: 'Uzm. Dr.' },
  { label: T.t('gen_exp_psyc', opts), value: 'Uzm. Psk.' }
].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));

export const TitlesLabel = (titleValue: string, T: Translator): string => {
  const titleObject = Titles(T).find(title => title.value === titleValue);

  return titleObject ? titleObject.label : titleValue;
}

export const DailyMaxClasses = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
];

export const WeeklyMaxDays = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
];

export const StaffTypes = (T: Translator, opts?: any) => [
  { label: T.t('gen_part_time', opts), value: 1 },
  { label: T.t('gen_full_time', opts), value: 2 },
  { label: T.t('gen_partial_full_time', opts), value: 3 }
];

export const StaffTypesShort = (T: Translator, opts?: any) => [
  { label: T.t('gen_part_time_short', opts), value: 1 },
  { label: T.t('gen_full_time_short', opts), value: 2 },
  { label: T.t('gen_partial_full_time_short', opts), value: 3 }
];

export const StaffApplyType = (T: Translator, opts?: any) => [
  { label: T.t('gen_title_based', opts), value: 0 },
  { label: T.t('gen_staff_type_based', opts), value: 1 }
]

export const ChangeAllDataModalInstructorInitialValues: Types.IInstructorFilterChangeAllDataModal = {
  selected_types: undefined,
  selected_type_ids: undefined,
  staff_type: 0,
  title: '',
  invigilator_level: 0,
  invigilation_campuses: [],
  invigilation_campus_ids: [],
  invigilation_faculties: [],
  invigilation_faculty_ids: [],
  invigilation_programs: [],
  invigilation_program_ids: [],
  status: 0
};


export const ImportingKeySelectOptions = (T: Translator, opts?: any) => [
  { label: T.t('gen_instructor_code', opts), value: 'INSTRUCTOR_CODE' },
  { label: T.t('gen_do_not_check', opts), value: 'NONE' }
];

export const InstructorAdministrativePositionOptions = (T: Translator, opts?: any) => [
  { label: T.t('gen_rector', opts), value: 1 },
  { label: T.t('gen_vice_rector', opts), value: 2 },
  { label: T.t('gen_advisor_to_the_rector', opts), value: 3 },
  { label: T.t('gen_dean', opts), value: 4 },
  { label: T.t('gen_deputy_dean', opts), value: 5 },
  { label: T.t('gen_secretary_general', opts), value: 6 },
  { label: T.t('gen_deputy_secretary_general', opts), value: 7 },
  { label: T.t('gen_head_of_department', opts), value: 8 },
  { label: T.t('gen_vice_department_chair', opts), value: 9 },
  { label: T.t('gen_head_of_program', opts), value: 10 },
  { label: T.t('gen_assistant_program_head', opts), value: 11 },
  { label: T.t('gen_director_of_the_center', opts), value: 12 },
  { label: T.t('gen_assistant_director_of_the_center', opts), value: 13 },
  { label: T.t('gen_director_of_the_institute', opts), value: 14 },
  { label: T.t('gen_assistant_director_of_the_institute', opts), value: 15 },
  { label: T.t('gen_director_of_vocational_school', opts), value: 16 },
  { label: T.t('gen_deputy_director_of_vocational_school', opts), value: 17 },
  { label: T.t('gen_head_of_department_for_administrative_units', opts), value: 18 },
  { label: T.t('gen_unit_manager', opts), value: 19 },
  { label: T.t('gen_member_of_the_university_senate', opts), value: 20 },
  { label: T.t('gen_member_of_the_university_board', opts), value: 21 },
  { label: T.t('gen_chair_of_the_commission', opts), value: 22 },
  { label: T.t('gen_member_of_the_commission', opts), value: 23 },
  { label: T.t('gen_board_member', opts), value: 24 },
];