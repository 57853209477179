import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal, UncontrolledTooltip } from 'reactstrap';
import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  ClassroomFormInitialValues,
  ClassroomFeatureOptions,
  ClassroomCategoryOptions,
  ClassroomAttributeOptions,
  ClassroomUsageStateOptions,
  ClassroomSeatingArrangementOptions,
  ClassroomSeatingTypeOptions,
  ClassroomUserTypeOptions,
  ClassroomSpecialUsingStateOptions,
  ClassroomInventoryOptions
} from '../../store/constants/classroom-const';
import * as Types from '../../store/types';
import { ClassroomFormValidation } from './validations/classroom-form-val';
import Translator from '../../services/translate-factory';
import ImageDropzone from '../../components/upload/ImageDropzone';
import { ClassroomTypes, ClassroomTypesLabel, LocalizedClassroomTypes, SettingsEventModuleStatus } from '../../store/constants/setting-const';
import InputField from '../../components/Input/default-input';
import cn, { flexCol, gap1 } from '../../components/ui/Tailwind';
import SelectField from '../../components/Input/select-field';
import Button from '../../components/button';
import { filterClassroomTypesForEventModuleStatus } from '../../util/sort';
import TableBase from '../../components/table/Table';
import { UserRole } from '../../store/constants/enums';
import { getConstantVariants, localizeSelectOptions } from '../../util/language';
const T = Translator.create();
const Logger = Log.create('ClassroomForm');

function getInitialState(): Types.IClassroomFormState {
  const initialValues: Types.IClassroomFormState = {
    model: Object.assign({}, ClassroomFormInitialValues)
  };
  return Object.assign({}, initialValues);
}

class ClassroomForm extends Component<Types.IClassroomFormProps, Types.IClassroomFormState> {
  state: Types.IClassroomFormState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };
  addNewInventory = (values: Types.IClassroomItem, actions: FormikActions<Types.IClassroomItem>) => {
    if (values.selected_inventory) {
      const value = values.selected_inventory.value
      const label = values.selected_inventory.label
      let id = value
      if (typeof value === "string" && value.startsWith('new_')) {
        id = 0
      }
      actions.setFieldValue("classroom_inventories",
        [
          ...(values.classroom_inventories || []),
          {
            id: id,
            label: label,
            value: value,
            count: values.inventory_count
          }
        ])
      actions.setFieldValue("inventory_count", 0)
      actions.setFieldValue("selected_inventory", null)
    }
  }

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.addEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.setCloseForm();
    }
  };

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  static handleClassroomIdChange(
    props: Types.IClassroomFormProps,
    state: Types.IClassroomFormState
  ): boolean {
    if (props.classroomId && props.classroomId !== state.model.classroom_id) {
      state.model.classroom_id = props.classroomId;
      props.dispatch(
        Actions.ApiRequest(
          Constants.classroom.CLASSROOM_GET_BY_ID,
          props.classroomId,
          'classroom-form-spin'
        )
      );
      return true;
    }
    return false;
  }

  static handleFormUpdate(
    props: Types.IClassroomFormProps,
    state: Types.IClassroomFormState
  ): boolean {
    if (
      props.form &&
      props.classroomId &&
      props.classroomId === state.model.classroom_id
    ) {
      const newState = getInitialState();
      newState.model = { ...newState.model, ...props.form };

      ClassroomForm.updateClassroomFeatures(props, newState);
      ClassroomForm.updateBuildingId(props, newState);
      ClassroomForm.updateClassroomType(props, newState);
      ClassroomForm.updateClassroomCategoryOptions(props, newState);
      ClassroomForm.updateClassroomAttributeOptions(props, newState);
      ClassroomForm.updateClassroomUsageStateOptions(props, newState);
      ClassroomForm.updateClassroomSeatingTypeOptions(props, newState);
      ClassroomForm.updateClassroomSeatingArrangementOptions(props, newState);
      ClassroomForm.updateClassroomUserTypeOptions(props, newState);
      ClassroomForm.updateClassroomSpecialUsingStateOptions(props, newState);
      ClassroomForm.updateClassroomInventoryOptions(props, newState);

      Object.assign(state, newState)

      return true;
    }
    return false;
  }

  static updateClassroomFeatures(
    props: Types.IClassroomFormProps,
    state: Types.IClassroomFormState
  ) {
    if (props.form && props.form.feature_codes) {
      let classroomFeatureOptions = ClassroomFeatureOptions(T);
      if (
        props.selectOptions &&
        props.selectOptions.additional_classroom_features &&
        props.selectOptions.additional_classroom_features.length > 0
      ) {
        classroomFeatureOptions = [
          ...classroomFeatureOptions,
          ...props.selectOptions.additional_classroom_features,
        ].sort((a, b) =>
          a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1
        );
      }
      state.model.feature_codes = props.form.feature_codes;
      state.model.features = props.form.feature_codes.map((featureCode: string) =>
        classroomFeatureOptions.find(
          (item: Types.ISelectOption) => item.value === featureCode
        ) as Types.ISelectOption
      );
    }
  }

  static updateBuildingId(
    props: Types.IClassroomFormProps,
    state: Types.IClassroomFormState
  ) {
    if (props.form && props.form.building) {
      state.model.building_id = props.form.building.value;
    }
  }

  static updateClassroomType(
    props: Types.IClassroomFormProps,
    state: Types.IClassroomFormState
  ) {
    if (props.form && props.form.classroomTypeSelect) {
      state.model.classroom_type = props.form.classroomTypeSelect.value;
    }
  }

  static updateClassroomCategoryOptions(
    props: Types.IClassroomFormProps,
    state: Types.IClassroomFormState
  ) {
    if (props.form && props.form.classroom_category) {
      state.model.classroom_category_options = this.getOption(
        props,
        props.form.classroom_category,
        ClassroomCategoryOptions,
        props.selectOptions && props.selectOptions.additional_classroom_categories
      );
    } else {
      state.model.classroom_category_options = [];
    }
  }

  static updateClassroomAttributeOptions(
    props: Types.IClassroomFormProps,
    state: Types.IClassroomFormState
  ) {
    if (props.form && props.form.classroom_attribute) {
      state.model.classroom_attribute_options = this.getOption(
        props,
        props.form.classroom_attribute,
        ClassroomAttributeOptions,
        props.selectOptions && props.selectOptions.additional_classroom_attributes
      );
    } else {
      state.model.classroom_attribute_options = [];
    }
  }

  static updateClassroomUsageStateOptions(
    props: Types.IClassroomFormProps,
    state: Types.IClassroomFormState
  ) {
    if (props.form && props.form.classroom_usage_state) {
      state.model.classroom_usage_state_options = this.getOption(
        props,
        props.form.classroom_usage_state,
        ClassroomUsageStateOptions,
        props.selectOptions && props.selectOptions.additional_classroom_usage_states
      );
    } else {
      state.model.classroom_usage_state_options = [];
    }
  }

  static updateClassroomSeatingTypeOptions(
    props: Types.IClassroomFormProps,
    state: Types.IClassroomFormState
  ) {
    if (props.form && props.form.classroom_seating_type) {
      state.model.classroom_seating_type_options = this.getOption(
        props,
        props.form.classroom_seating_type,
        ClassroomSeatingTypeOptions,
        props.selectOptions && props.selectOptions.additional_classroom_seating_types
      );
    } else {
      state.model.classroom_seating_type_options = [];
    }
  }

  static updateClassroomSeatingArrangementOptions(
    props: Types.IClassroomFormProps,
    state: Types.IClassroomFormState
  ) {
    if (props.form && props.form.classroom_seating_arrangement) {
      state.model.classroom_seating_arrangement_options = this.getOption(
        props,
        props.form.classroom_seating_arrangement,
        ClassroomSeatingArrangementOptions,
        props.selectOptions && props.selectOptions.additional_classroom_seating_arrangements
      );
    } else {
      state.model.classroom_seating_arrangement_options = [];
    }
  }

  static updateClassroomUserTypeOptions(
    props: Types.IClassroomFormProps,
    state: Types.IClassroomFormState
  ) {
    if (props.form && props.form.classroom_user_type) {
      state.model.classroom_user_type_options = this.getOption(
        props,
        props.form.classroom_user_type,
        ClassroomUserTypeOptions,
        props.selectOptions && props.selectOptions.additional_classroom_user_types
      );
    } else {
      state.model.classroom_user_type_options = [];
    }
  }

  static updateClassroomSpecialUsingStateOptions(
    props: Types.IClassroomFormProps,
    state: Types.IClassroomFormState
  ) {
    if (props.form && props.form.classroom_special_using_state) {
      state.model.classroom_special_using_state_options = this.getOption(
        props,
        props.form.classroom_special_using_state,
        ClassroomSpecialUsingStateOptions,
        props.selectOptions && props.selectOptions.additional_classroom_special_using_states
      );
    } else {
      state.model.classroom_special_using_state_options = [];
    }
  }

  static updateClassroomInventoryOptions(
    props: Types.IClassroomFormProps,
    state: Types.IClassroomFormState
  ) {
    if (props.form && props.form.classroom_inventories) {
      state.model.classroom_inventory_options = this.getInventoryOption(
        props,
        props.form.classroom_inventories,
        ClassroomInventoryOptions,
        props.selectOptions && props.selectOptions.additional_classroom_inventories
      );
    } else {
      state.model.classroom_inventory_options = [];
    }
  }

  static getOption(
    props: Types.IClassroomFormProps,
    value: string | undefined,
    defaultOptionsFunc: (T: any) => Types.ISelectOption[],
    additionalOptions?: Types.ISelectOption[]
  ): Types.ISelectOption[] {
    if (value) {
      let option =
        defaultOptionsFunc(T).find(
          (item: Types.ISelectOption) => item.value.toString() === value
        ) ||
        (additionalOptions &&
          additionalOptions.find(
            (item: Types.ISelectOption) => item.value.toString() === value
          ));
      return option ? [option] : [];
    }
    return [];
  }

  static getInventoryOption(
    props: Types.IClassroomFormProps,
    values: Types.ClassroomInventory[] | undefined,
    defaultOptionsFunc: (T: any) => Types.ISelectOption[],
    additionalOptions?: Types.ISelectOption[]
  ): Types.ISelectOption[] {
    if (values) {
      let options =
        defaultOptionsFunc(T).filter(
          (item: Types.ISelectOption) => values.map((x) => x.value).includes(item.value.toString())
        ) ||
        (additionalOptions &&
          additionalOptions.find(
            (item: Types.ISelectOption) => values.map((x) => x.value).includes(item.value.toString())
          ));
      return options ? options : [];
    }
    return [];
  }

  static updateEventPlanningUsageOptions(
    props: Types.IClassroomFormProps,
    state: Types.IClassroomFormState
  ) {
    if (props.form && props.form.status_event_planning_options) {
      state.model.status_event_planning_options =
        props.form.status_event_planning_options;
    } else if (state.model && state.model.status_event_planning != undefined) {
      let option = { label: state.model.status_event_planning == 1 ? T.t('gen_in_use') : T.t('gen_unused'), value: state.model.status_event_planning };
      state.model.status_event_planning_options = [option];
    } else {
      state.model.status_event_planning_options = [
        { label: T.t('gen_in_use'), value: 1 },
      ];
    }
  }

  static getDerivedStateFromProps(
    props: Types.IClassroomFormProps,
    state: Types.IClassroomFormState
  ) {
    let hasNewState = false;

    if (ClassroomForm.handleClassroomIdChange(props, state)) {
      hasNewState = true;
    }

    if (ClassroomForm.handleFormUpdate(props, state)) {
      hasNewState = true;
    }

    ClassroomForm.updateEventPlanningUsageOptions(props, state);

    if (hasNewState) {
      return state;
    } else if (!props.classroomId && state.model.classroom_id) {
      return getInitialState();
    } else {
      return null;
    }
  }
  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  onFormSave = (model: Types.IClassroomItem, FormActions: FormikActions<Types.IClassroomItem>) => {
    this.disableAllChildren(true);
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }
        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201) {
        this.setClose(true);
      }
      this.disableAllChildren(false);
      FormActions.setSubmitting(false);
      this.getClassroomSelectOptions();
    };

    if (this.props.classroomId) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.classroom.CLASSROOM_UPDATE, model, 'classroom-form-spin', resultCallback)
      );
    } else {
      this.props.dispatch(
        Actions.ApiRequest(Constants.classroom.CLASSROOM_CREATE, model, 'classroom-form-spin', resultCallback)
      );
    }

  };

  disableAllChildren = (isDisabled: boolean) => {
    const disableOnLoading = document.getElementById('disableOnLoading');

    if (disableOnLoading) {
      disableOnLoading.querySelectorAll('input, button, textarea, select').forEach((element) => {
        if (element instanceof HTMLInputElement ||
          element instanceof HTMLButtonElement ||
          element instanceof HTMLTextAreaElement ||
          element instanceof HTMLSelectElement) {
          element.disabled = isDisabled;
        }
      });
    }
  };

  getClassroomFeatureOptions() {
    let classroomFeatureOptions = ClassroomFeatureOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_features && this.props.selectOptions.additional_classroom_features.length > 0) {
      classroomFeatureOptions = [...ClassroomFeatureOptions(T), ...this.props.selectOptions.additional_classroom_features].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }
    return classroomFeatureOptions
  }

  getClassroomCategoryOptions() {
    let classroomCategoryOptions = ClassroomCategoryOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_categories && this.props.selectOptions.additional_classroom_categories.length > 0) {
      classroomCategoryOptions = [
        ...ClassroomCategoryOptions(T),
        ...this.props.selectOptions.additional_classroom_categories
      ].sort((a, b) => a.label.localeCompare(b.label));
    }
    return classroomCategoryOptions;
  }

  getClassroomAttributeOptions() {
    let classroomAttributeOptions = ClassroomAttributeOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_attributes && this.props.selectOptions.additional_classroom_attributes.length > 0) {
      classroomAttributeOptions = [
        ...ClassroomAttributeOptions(T),
        ...this.props.selectOptions.additional_classroom_attributes
      ].sort((a, b) => a.label.localeCompare(b.label));
    }
    return classroomAttributeOptions;
  }

  getClassroomUsageStateOptions() {
    let classroomUsageStateOptions = ClassroomUsageStateOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_usage_states && this.props.selectOptions.additional_classroom_usage_states.length > 0) {
      classroomUsageStateOptions = [
        ...ClassroomUsageStateOptions(T),
        ...this.props.selectOptions.additional_classroom_usage_states
      ].sort((a, b) => a.label.localeCompare(b.label));
    }
    return classroomUsageStateOptions;
  }

  getClassroomSeatingTypeOptions() {
    let classroomSeatingTypeOptions = ClassroomSeatingTypeOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_seating_types && this.props.selectOptions.additional_classroom_seating_types.length > 0) {
      classroomSeatingTypeOptions = [
        ...ClassroomSeatingTypeOptions(T),
        ...this.props.selectOptions.additional_classroom_seating_types
      ].sort((a, b) => a.label.localeCompare(b.label));
    }
    return classroomSeatingTypeOptions;
  }

  getClassroomUserTypesOptions() {
    let userTypeOptions = ClassroomUserTypeOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_user_types && this.props.selectOptions.additional_classroom_user_types.length > 0) {
      userTypeOptions = [
        ...ClassroomUserTypeOptions(T),
        ...this.props.selectOptions.additional_classroom_user_types
      ].sort((a, b) => a.label.localeCompare(b.label));
    }
    return userTypeOptions;
  }

  getSpecialUsingStateOptions() {
    let specialUsingStates = ClassroomSpecialUsingStateOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_special_using_states && this.props.selectOptions.additional_classroom_special_using_states.length > 0) {
      specialUsingStates = [
        ...ClassroomSpecialUsingStateOptions(T),
        ...this.props.selectOptions.additional_classroom_special_using_states
      ].sort((a, b) => a.label.localeCompare(b.label));
    }
    return specialUsingStates;
  }

  getClassroomSeatingArrangementOptions() {
    let classroomSeatingArrangementOptions = ClassroomSeatingArrangementOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_seating_arrangements && this.props.selectOptions.additional_classroom_seating_arrangements.length > 0) {
      classroomSeatingArrangementOptions = [
        ...ClassroomSeatingArrangementOptions(T),
        ...this.props.selectOptions.additional_classroom_seating_arrangements
      ].sort((a, b) => a.label.localeCompare(b.label));
    }
    return classroomSeatingArrangementOptions;
  }

  getClassroomInventoryOptions() {
    let inventories = ClassroomInventoryOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_inventories && this.props.selectOptions.additional_classroom_inventories.length > 0) {
      inventories = [
        ...ClassroomInventoryOptions(T),
        ...this.props.selectOptions.additional_classroom_inventories
      ].sort((a, b) => a.label.localeCompare(b.label));
    }
    if (this.state.model && this.state.model.classroom_inventories && this.state.model.classroom_inventories.length) {
      inventories = inventories.filter((x) => !this.state.model.classroom_inventories!.map(y => y.value).includes(x.value)).sort((a, b) => a.label.localeCompare(b.label));
    }
    return inventories;
  }

  sortType(a: Types.ISelectOption, b: Types.ISelectOption) {
    return a.label.localeCompare(b.label)
  }

  getModalTitle() {
    return this.props.classroomId ? <><i className="material-icons mr-2">edit</i>{T.t('gen_update_classroom')}</> : <><i className="material-icons mr-2">add_circle_outline</i> {T.t('gen_add_classroom')}</>
  }

  setOptionValues(
    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>,
    props: FormikProps<Types.IClassroomItem>,
    setList: string,
    setValues: string,
  ) {
    const list: Array<Types.ISelectOption> = options
      ? (options as Array<Types.ISelectOption>)
      : [];
    props.setFieldValue(setList, list);
    props.setFieldValue(
      setValues,
      list.map((item) => item.value)
    );
  }

  setOptionValuesConditional(
    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>,
    props: FormikProps<Types.IClassroomItem>,
    setList: string,
    setValues: string,
  ) {
    const list: Array<Types.ISelectOption> = options
      ? (options as Array<Types.ISelectOption>)
      : [];
    if (props.values.user_capacity && list.length <= props.values.user_capacity) {
      this.setOptionValues(options, props, setList, setValues)
    }
  }

  getClassroomSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_GET_SELECT_OPTIONS, 'classroom-list-spin'));
  }

  renderCategory({ props }: { props: FormikProps<Types.IClassroomItem> }) {
    let classroomCategoryOptions = this.getClassroomCategoryOptions();

    return this.props.general_settings && this.props.general_settings.event_module_status === SettingsEventModuleStatus.Active ? (
      <div className="col-md-5">
        <SelectField
          T={T}
          label={T.t('gen_category')}
          placeholder={T.t('gen_select_category')}
          isCreatable={true}
          isClearable
          id='classroom_type'
          className="react-select"
          isMulti={false}
          constantVariants={getConstantVariants(ClassroomCategoryOptions, T)}
          closeMenuOnSelect={true}
          options={classroomCategoryOptions}
          value={props.values.classroom_category_options}
          onChange={(option: any) => {
            props.setFieldValue('classroom_category_options', [option]);
            props.setFieldValue('classroom_category', option && option.value);
          }}
          noOptionsMessage={(): string => T.t('gen_no_options_available')}
          error={props.errors.classroom_category}
          showError={props.submitCount > 0}
        />
      </div>
    ) : <div className="col-md-5" />
  }

  calculateAreaPerUserCapacity(props: FormikProps<Types.IClassroomItem>) {
    if (props.values.user_capacity && props.values.area) {
      return (props.values.area / props.values.user_capacity).toFixed(2);
    }
    return null
  }

  calculateVolumePerUserCapacity(props: FormikProps<Types.IClassroomItem>) {
    if (props.values.user_capacity && props.values.volume) {
      return (props.values.volume / props.values.user_capacity).toFixed(2);
    }
    return null
  }

  calculateAreaPerLectureCapacity(props: FormikProps<Types.IClassroomItem>) {
    if (props.values.lecture_capacity && props.values.area) {
      return (props.values.area / props.values.lecture_capacity).toFixed(2);
    }
    return null
  }

  calculateVolumePerLectureCapacity(props: FormikProps<Types.IClassroomItem>) {
    if (props.values.lecture_capacity && props.values.volume) {
      return (props.values.volume / props.values.lecture_capacity).toFixed(2);
    }
    return null
  }

  renderEventModuleFields({ props }: { props: FormikProps<Types.IClassroomItem> }) {
    let classroomAttributeOptions = this.getClassroomAttributeOptions();
    let classroomUsageStateOptions = this.getClassroomUsageStateOptions();
    let classroomSeatingTypeOptions = this.getClassroomSeatingTypeOptions();
    let classroomSeatingArrangementOptions = this.getClassroomSeatingArrangementOptions();
    let classroomUserTypeOptions = this.getClassroomUserTypesOptions();
    let specialUsingStateOptions = this.getSpecialUsingStateOptions();

    if (props.values.status_furnished != undefined && props.values.status_furnished_options == undefined) {
      let option = { label: props.values.status_furnished == 1 ? T.t('gen_yes') : T.t('gen_no'), value: props.values.status_furnished };
      props.setFieldValue('status_furnished_options', [option]);
    }

    if (props.values.status_sink_water != undefined && props.values.status_sink_water_options == undefined) {
      let option = { label: props.values.status_sink_water == 1 ? T.t('gen_yes') : T.t('gen_no'), value: props.values.status_sink_water };
      props.setFieldValue('status_sink_water_options', [option]);
    }

    if (props.values.status_heating != undefined && props.values.status_heating_options == undefined) {
      let option = { label: props.values.status_heating == 1 ? T.t('gen_yes') : T.t('gen_no'), value: props.values.status_heating };
      props.setFieldValue('status_heating_options', [option]);
    }

    if (props.values.status_event_planning != undefined && props.values.status_event_planning_options == undefined) {
      let option = { label: props.values.status_event_planning == 1 ? T.t('gen_in_use') : T.t('gen_unused'), value: props.values.status_event_planning };
      props.setFieldValue('status_event_planning_options', [option]);
    }

    const areaPerPerson = this.calculateAreaPerUserCapacity(props);
    const volumePerPerson = this.calculateVolumePerUserCapacity(props);

    return this.isEventStatusActive() ? (
      <React.Fragment>
        <div className="col-md-5">
          <SelectField
            label={T.t('gen_attribute')}
            T={T}
            error={props.errors.classroom_attribute}
            showError={props.submitCount > 0}
            className="react-select"
            name="attribute"
            id="attribute"
            isMulti={false}
            isClearable
            isCreatable={true}
            constantVariants={getConstantVariants(ClassroomAttributeOptions, T)}
            closeMenuOnSelect={true}
            options={classroomAttributeOptions}
            placeholder={T.t('gen_enter_attribute')}
            value={props.values.classroom_attribute_options}
            onChange={(option: any) => {
              props.setFieldValue('classroom_attribute_options', [option]);
              props.setFieldValue('classroom_attribute', option && option.value);
            }}
            noOptionsMessage={(): string => T.t('gen_no_options_available')}
          />

        </div>
        <div className="col-md-7">
          <SelectField
            label={T.t('gen_seating_arrangement')}
            T={T}
            error={props.errors.classroom_seating_arrangement}
            showError={props.submitCount > 0}
            isCreatable={true}
            className="react-select"
            name="seating_arrangements"
            id="seating_arrangements"
            isMulti={false} isClearable
            constantVariants={getConstantVariants(ClassroomSeatingArrangementOptions, T)}
            closeMenuOnSelect={true}
            options={classroomSeatingArrangementOptions}
            placeholder={T.t('gen_choose_seating_arrangement')}
            value={props.values.classroom_seating_arrangement_options}
            onChange={(option: any) => {
              props.setFieldValue('classroom_seating_arrangement_options', [option]);
              props.setFieldValue('classroom_seating_arrangement', option && option.value);
            }}
            noOptionsMessage={(): string => T.t('gen_no_options_available')}
          />
        </div>
        <div className="col-md-5">
          <SelectField
            T={T}
            label={T.t('gen_seating_type')}
            error={props.errors.classroom_seating_type}
            showError={props.submitCount > 0}
            isCreatable={true}
            className="react-select"
            name="seating_type"
            id="seating_type"
            isMulti={false} isClearable
            constantVariants={getConstantVariants(ClassroomSeatingTypeOptions, T)}
            closeMenuOnSelect={true}
            options={classroomSeatingTypeOptions}
            placeholder={T.t('gen_enter_seating_type')}
            value={props.values.classroom_seating_type_options}
            onChange={(option: any) => {
              props.setFieldValue('classroom_seating_type_options', [option]);
              props.setFieldValue('classroom_seating_type', option && option.value);
            }}
            noOptionsMessage={(): string => T.t('gen_no_options_available')}
          />
        </div>
        <div className="col-md-6">
          <SelectField
            T={T}
            label={T.t('gen_user_type')}
            error={props.errors.classroom_user_type}
            showError={props.submitCount > 0}
            isCreatable={true}
            className="react-select"
            name="user_type"
            id="user_type"
            isMulti={false} isClearable
            constantVariants={getConstantVariants(ClassroomUserTypeOptions, T)}
            closeMenuOnSelect={true}
            options={classroomUserTypeOptions}
            placeholder={T.t('gen_enter_user_type')}
            value={props.values.classroom_user_type_options}
            onChange={(option: any) => {
              props.setFieldValue('classroom_user_type_options', [option]);
              props.setFieldValue('classroom_user_type', option && option.value);
            }}
            noOptionsMessage={(): string => T.t('gen_no_options_available')}
          />
        </div>
        <div className="col-md-6">
          <SelectField
            T={T}
            label={T.t('gen_special_using_state')}
            error={props.errors.classroom_special_using_state}
            showError={props.submitCount > 0}
            isCreatable={true}
            className="react-select"
            name="special_using_state"
            id="special_using_state" isClearable
            isMulti={false}
            constantVariants={getConstantVariants(ClassroomSpecialUsingStateOptions, T)}
            closeMenuOnSelect={true}
            options={specialUsingStateOptions}
            placeholder={T.t('gen_enter_special_using_state')}
            value={props.values.classroom_special_using_state_options}
            onChange={(option: any) => {
              props.setFieldValue('classroom_special_using_state_options', [option]);
              props.setFieldValue('classroom_special_using_state', option && option.value);
            }}
            noOptionsMessage={(): string => T.t('gen_no_options_available')}

          />
        </div>
        <div className="col-md-6">
          <SelectField
            T={T}
            label={T.t('gen_using_state')}
            error={props.errors.classroom_usage_state}
            showError={props.submitCount > 0}
            isCreatable={true}
            className="react-select"
            name="classroom_usage_state"
            id="classroom_usage_state" isClearable
            isMulti={false}
            constantVariants={getConstantVariants(ClassroomUsageStateOptions, T)}
            closeMenuOnSelect={true}
            options={classroomUsageStateOptions}
            placeholder={T.t('gen_choose_using_state')}
            value={props.values.classroom_usage_state_options}
            onChange={(option: any) => {
              props.setFieldValue('classroom_usage_state_options', [option]);
              props.setFieldValue('classroom_usage_state', option && option.value);
            }}
            noOptionsMessage={(): string => T.t('gen_no_options_available')}
          />
        </div>
        <div className="col-md-6">
          <SelectField
            T={T}
            label={T.t("gen_event_planning_usage")}
            error={props.errors.status_event_planning}
            showError={props.submitCount > 0}
            className="react-select"
            name="event_planning_usage"
            id="event_planning_usage"
            isMulti={false}
            closeMenuOnSelect={true}
            options={[
              { label: T.t("gen_unused"), value: 0 },
              { label: T.t("gen_in_use"), value: 1 },
            ]}
            placeholder={T.t('gen_choose_event_planning_usage')}
            value={props.values.status_event_planning_options}
            onChange={(option: any) => {
              props.setFieldValue('status_event_planning_options', [option]);
              props.setFieldValue('status_event_planning', option && option.value);
            }}
            noOptionsMessage={(): string => T.t('gen_no_options_available')}
          />
        </div>
        <div className="col-md-6 form-input form-group with-icon">
          <InputField
            label={T.t("gen_classroom_user_capacity")}
            id="user_capacity"
            name="user_capacity"
            value={props.values.user_capacity}
            onChange={(e) => {
              if (props.values.classroom_users && parseInt(e.target.value) < props.values.classroom_users.length) {
                props.values.classroom_users = [];
              }
              props.handleChange(e);
            }}
            onBlur={props.handleBlur}
            type="number"
            defaultValue={'0'}
            min={0}
            error={props.errors.user_capacity}
            showError={props.submitCount > 0}
            subContent={<div className={cn(flexCol, gap1, "tw-mt-2")} style={{ display: 'none' }}>
              {areaPerPerson && <span className='tw-text-xs tw-text-gray-600'>{T.t('gen_area_per_person', { value: areaPerPerson })}</span>}
              {volumePerPerson && <span className='tw-text-xs tw-text-gray-600'>{T.t('gen_volume_per_person', { value: volumePerPerson })}</span>}
            </div>}
          />
        </div>
        <div className="col-md-6">
          <div className="add-custom-tag mb-3">
            <div className="react-select-container">
              <label id="classroom_users">{T.t('gen_classroom_users')} <i className="material-icons">info_outlined</i></label>
              <UncontrolledTooltip placement="bottom" target="classroom_users">
                {T.t('gen_enter_user_capacity_first')}
              </UncontrolledTooltip>
              <Select
                id='feature'
                className="react-select"
                isMulti={true}
                isDisabled={!(props.values.user_capacity && props.values.user_capacity > 0)}
                filterOption={(option: any, query: any) =>
                  option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                }
                closeMenuOnSelect={false}
                options={this.props.selectOptions && this.props.selectOptions.available_users}
                placeholder={T.t('gen_select_user')}
                value={props.values.classroom_users}
                onChange={(options) => this.setOptionValuesConditional(options, props, 'classroom_users', 'classroom_user_ids')}
                noOptionsMessage={(): string => T.t('gen_select_no_user')}
              />
              <span className="highlight" />
              <span className="bar" />
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <InputField
            id="area"
            name="area"
            value={props.values.area}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="number"
            className='tw-pr-8'
            min={0}
            step="0.1"
            label={T.t('gen_meter_square_area')}
            icon={<span>m²</span>}
            error={props.errors.area}
            showError={props.submitCount > 0}
          />
        </div>
        <div className='col-md-6'>
          <InputField
            id="volume"
            name="volume"
            value={props.values.volume}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="number"
            min={0}
            className='tw-pr-8'
            step="0.1"
            label={T.t('gen_meter_cubic_area')}
            icon={<span>m³</span>}
            error={props.errors.volume}
            showError={props.submitCount > 0}
          />
        </div>
      </React.Fragment>
    ) : <div className="col-md-5" />
  }

  setRangeValues(props: FormikProps<Types.IClassroomItem>) {
    if (props.values.area === undefined) {
      props.setFieldValue('area', 0);
    }
    if (props.values.volume === undefined) {
      props.setFieldValue('volume', 0);
    }
  }
  calculateAllRelatedFloors = () => {
    const floors: Types.ISelectOption[] = []

    for (let i = -5; i <= 25; i++) {
      floors.push({ label: i.toString(), value: i })
    }
    return floors
  }

  getImagesComponent(props: FormikProps<Types.IClassroomItem>) {
    const { general_settings } = this.props
    return this.props.term_id === -1
      && general_settings && general_settings.event_module_status === SettingsEventModuleStatus.Active &&
      <div className="row mt-3">
        <div className="col-md-12">
          <ImageDropzone
            uploadAreaText={T.t('gen_you_can_import_images_here')}
            title={T.t('gen_images')}
            type="default"
            imageList={props.values.photos}
            setImages={(newImages) => props.setFieldValue("photos", newImages)}
          />
        </div>
      </div>
  }
  isEventStatusActive() {
    return this.props.general_settings && this.props.general_settings.event_module_status === SettingsEventModuleStatus.Active
  }
  render() {
    let classroomFeatureOptions = this.getClassroomFeatureOptions()
    let classroomInventoryOptions = this.getClassroomInventoryOptions();
    const validation = ClassroomFormValidation(T)

    let classroomTypes: Types.ISelectOption[] = [];
    if (this.props.selectOptions) {
      classroomTypes = localizeSelectOptions(this.props.selectOptions.classroom_types_active, ClassroomTypes(T))
    }

    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Formik
          initialValues={this.state.model}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
          validationSchema={validation}
        >
          {(props: FormikProps<Types.IClassroomItem>) => {
            const { values, handleChange, errors, handleBlur, handleSubmit, setFieldValue, isSubmitting } = props;
            this.setRangeValues(props);
            const areaPerStudent = this.calculateAreaPerLectureCapacity(props);
            const volumePerStudent = this.calculateVolumePerLectureCapacity(props);
            const selectedValues = (values.classroom_inventories || []).map(x => x.value);

            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div id="disableOnLoading">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">
                            {this.getModalTitle()}
                          </h5>
                          <button
                            id='button_close'
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={this.setCloseForm}
                          >
                            <i className="material-icons">close</i>
                            <span>ESC</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-md-6 form-input form-group with-icon">
                              <input
                                id="classroom_code"
                                name="classroom_code"
                                value={values.classroom_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="classroom_code">{T.t('gen_code')}</label>
                              {errors && errors.classroom_code && props.submitCount > 0 && (
                                <div className="error">{errors && errors.classroom_code}</div>
                              )}
                            </div>
                            <div className="col-md-6 form-input form-group with-icon">
                              <input
                                id="name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="name">{T.t('gen_name')}</label>
                              {errors && errors.name && props.submitCount > 0 && (
                                <div className="error">{errors && errors.name}</div>
                              )}
                            </div>
                            <div className="col-md-7">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_campus_and_building')}</label>
                                  <Select
                                    id='select_building'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={this.props.selectOptions && this.props.selectOptions.buildings}
                                    placeholder={T.t('gen_select_building')}
                                    value={props.values.buildingSelect}
                                    onChange={(option: any) => {
                                      props.setFieldValue('building_id', option.value);
                                      props.setFieldValue('buildingSelect', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                  />
                                </div>
                                {errors && errors.building_id && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.building_id}</div>
                                )}
                              </div>
                            </div>
                            {this.renderCategory({ props })}
                            <div className="col-md-7">
                              <SelectField
                                T={T}
                                id='classroom_type'
                                className="react-select"
                                isMulti={false}
                                isCreatable={true}
                                closeMenuOnSelect={true}
                                label={T.t('gen_type_function')}
                                placeholder={T.t('gen_select_type_function')}
                                constantVariants={getConstantVariants(ClassroomTypes, T)}
                                options={classroomTypes.filter(filterClassroomTypesForEventModuleStatus(this.props.general_settings))}
                                value={classroomTypes.find((option) => option.value === values.classroom_type) || values.classroomTypeSelect}
                                onChange={(option: any) => {
                                  props.setFieldValue('classroom_type', option.value);
                                  props.setFieldValue('classroomTypeSelect', option);
                                }}
                                error={errors.classroom_type}
                                showError={props.submitCount > 0}
                                noOptionsMessage={(): string => T.t('gen_select_no_type')}
                              />
                            </div>

                            {this.renderEventModuleFields({ props })}

                            <div className="col-md-4">
                              <SelectField
                                T={T}
                                id="building_floor"
                                name="building_floor"
                                label={T.t('gen_floor')}
                                value={{ label: values.building_floor ? values.building_floor.toString() : "0", values: values.building_floor }}
                                onChange={(option) => {
                                  props.setFieldValue('building_floor', option.value);
                                }}
                                options={this.calculateAllRelatedFloors()}
                                placeholder={T.t('gen_select_floor')}
                                error={errors.building_floor}
                                showError={props.submitCount > 0}
                              />
                            </div>
                            <div className="col-md-4 form-input form-group with-icon">
                              <InputField
                                id="door_order"
                                name="door_order"
                                value={values.door_order}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                required
                                label={T.t('gen_door_number')}

                                error={errors.door_order}
                                showError={props.submitCount > 0}
                              />
                            </div>
                            {this.props.term_type !== 0 ? (
                              <div className="col-md-4 form-input form-group with-icon">
                                <InputField
                                  id="invigilator_count"
                                  name="invigilator_count"
                                  value={values.invigilator_count}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="number"
                                  label={T.t('gen_invigilator_count')}
                                  error={errors.invigilator_count}
                                  showError={props.submitCount > 0}
                                />
                              </div>
                            ) : null}
                            {this.props.term_type !== 0 ? (
                              <div className="col-md-6 form-input form-group with-icon">
                                <InputField
                                  id="exam_capacity"
                                  name="exam_capacity"
                                  value={values.exam_capacity}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="number"
                                  label={T.t('gen_exam_capacity')}
                                  error={errors.exam_capacity}
                                  showError={props.submitCount > 0}
                                />
                              </div>
                            ) : null}
                            <div className="col-md-6 form-input form-group with-icon">
                              <InputField
                                id="lecture_capacity"
                                name="lecture_capacity"
                                value={values.lecture_capacity}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                min={0}
                                error={errors.lecture_capacity}
                                showError={props.submitCount > 0}
                                label={T.t('gen_lecture_capacity')}
                                subContent={this.isEventStatusActive() && <div className={cn(flexCol, gap1, "tw-mt-2")}>
                                  {areaPerStudent && <span className='tw-text-xs tw-text-gray-600'>{T.t('gen_area_per_person', { value: areaPerStudent })}</span>}
                                  {volumePerStudent && <span className='tw-text-xs tw-text-gray-600'>{T.t('gen_volume_per_person', { value: volumePerStudent })}</span>}
                                </div>}
                              />
                            </div>
                            <div className="col-md-12">
                              <SelectField
                                T={T}
                                label={T.t('gen_feature')}
                                error={errors.features}
                                showError={props.submitCount > 0}
                                id='feature'
                                className="react-select"
                                isMulti={true}
                                isClearable
                                constantVariants={getConstantVariants(ClassroomFeatureOptions, T)}
                                closeMenuOnSelect={false}
                                options={classroomFeatureOptions}
                                placeholder={T.t('gen_select_feature')}
                                value={props.values.features}
                                onChange={(options) => this.setOptionValues(options, props, 'features', 'feature_codes')}
                                noOptionsMessage={(): string => T.t('gen_select_no_feature')}
                              />
                            </div>
                            {
                              this.isEventStatusActive()
                              && <>
                                <div className='col-md-4' >
                                  <SelectField
                                    T={T}
                                    id="furnished_states"
                                    name="furnished_states"
                                    value={values.status_furnished_options}
                                    onChange={(option) => {
                                      props.setFieldValue("status_furnished_options", [option]);
                                      props.setFieldValue("status_furnished", option.value);
                                    }}
                                    options={[
                                      { label: T.t('gen_yes'), value: 1 },
                                      { label: T.t('gen_no'), value: 0 },
                                    ]}
                                    label={T.t('gen_furnished')}
                                    placeholder={T.t("gen_select_state")}
                                    error={errors.status_furnished_options}
                                    showError={props.submitCount > 0}
                                  />
                                </div>
                                <div className='col-md-4'>
                                  <SelectField
                                    T={T}
                                    id="sink_water_states"
                                    name="sink_water_states"
                                    value={values.status_sink_water_options}
                                    onChange={(option) => {
                                      props.setFieldValue("status_sink_water_options", [option]);
                                      props.setFieldValue("status_sink_water", option && option.value);
                                    }}
                                    options={[
                                      { label: T.t('gen_yes'), value: 1 },
                                      { label: T.t('gen_no'), value: 0 },
                                    ]}
                                    label={T.t('gen_sink_water')}
                                    placeholder={T.t("gen_select_state")}
                                    error={errors.status_sink_water_options}
                                    showError={props.submitCount > 0}
                                  />
                                </div>
                                <div className='col-md-4'>
                                  <SelectField
                                    T={T}
                                    id="heating_states"
                                    name="heating_states"
                                    value={values.status_heating_options}
                                    onChange={(option) => {
                                      props.setFieldValue("status_heating_options", [option]);
                                      props.setFieldValue("status_heating", option && option.value);
                                    }}
                                    options={[
                                      { label: T.t('gen_yes'), value: 1 },
                                      { label: T.t('gen_no'), value: 0 },
                                    ]}
                                    label={T.t('gen_heating')}
                                    placeholder={T.t("gen_select_state")}
                                    error={errors.status_heating_options}
                                    showError={props.submitCount > 0}
                                  />
                                </div>
                                <div className='col-md-4'>
                                  <InputField
                                    id='count_windows'
                                    name='count_windows'
                                    value={values.count_windows}
                                    onChange={handleChange}
                                    type='number'
                                    min={0}
                                    label={T.t('gen_windows_count')}
                                    error={errors.count_windows}
                                    showError={props.submitCount > 0}
                                  />
                                </div>
                              </>
                            }
                            <div className="col-md-12 form-input form-group with-icon">
                              <textarea
                                name="description"
                                className="form-input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                rows={2}
                                id="description"
                                value={values.description}
                                placeholder={T.t('gen_description')}
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="description" />
                              {errors && errors.description && props.submitCount > 0 && (
                                <div className="error">{errors && errors.description}</div>
                              )}
                            </div>
                          </div>
                          {this.isEventStatusActive() && <div className='row mt-2 tw-items-center'>
                            <div className='col-md-7'>
                              <SelectField
                                label={T.t('gen_item')}
                                placeholder={T.t("gen_select_item")}
                                id="classroom_inventories"
                                isCreatable={true}
                                onCreateValue={(label) => `new_${label}`}
                                isClearable
                                name="classroom_inventories"
                                value={values.selected_inventory}
                                onChange={(options) => {
                                  props.setFieldValue("selected_inventory", options)
                                }}
                                options={classroomInventoryOptions.filter(x => !selectedValues.includes(x.value))}
                                constantVariants={
                                  [
                                    ...getConstantVariants(ClassroomInventoryOptions, T),
                                    ...values.classroom_inventories || [] // for new items
                                  ]
                                }
                                T={T}
                                error={errors.classroom_inventories}
                                showError={props.submitCount > 0}
                              />
                            </div>
                            <div className='col-md-3'>
                              <InputField
                                id='inventory_count'
                                name='inventory_count'
                                value={values.inventory_count}
                                onChange={handleChange}
                                type='number'
                                min={0}
                                label={T.t('gen_amount')}
                                error={errors.inventory_count}
                                showError={props.submitCount > 0}
                              />
                            </div>
                            <div className='col-md-2'>
                              <Button icon='add' color='green'
                                onClick={() => this.addNewInventory(values, props)}
                              >{T.t("gen_add")}</Button>
                            </div>
                            <div className='col-md-12'>
                              <TableBase
                                disableFetch={true}
                                disableFetchPagination={true}
                                pageSize={5}
                                columnComponent={[
                                  {
                                    widthPercentage: 40,
                                    __componentType: 'oneLine',
                                    title: T.t('gen_inventory'),
                                    __headerType: 'default',
                                    parseName: 'label',
                                  },
                                  {
                                    widthPercentage: 40,
                                    __componentType: 'oneLine',
                                    title: T.t('gen_amount'),
                                    __headerType: 'default',
                                    parseName: 'count',
                                  },
                                  {
                                    widthPercentage: 40,
                                    __componentType: 'oneLine',
                                    title: T.t('gen_action'),
                                    __headerType: 'default',
                                    parseName: 'value',
                                    customRender(item: Types.ClassroomInventory) {
                                      return <td className='text-center'>
                                        <Button icon='delete' color='genericRed' onClick={() => {
                                          if (values.classroom_inventories) {
                                            setFieldValue("classroom_inventories", [...(values.classroom_inventories.filter(x => x.value !== item.value))])
                                          }
                                          if (classroomInventoryOptions) {
                                            classroomInventoryOptions = [...classroomInventoryOptions, item].sort((a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1));
                                          }
                                        }} />
                                      </td>
                                    },
                                  }
                                ]}
                                results={values.classroom_inventories}
                              />
                            </div>
                          </div>}
                          <div className="row mt-3">
                            <div className="col-md-6">
                              <div className="text-left">
                                <h6>{T.t('gen_status')}</h6>
                                <div className="tick-radio position-relative d-inline-block">
                                  <Checkbox name="status" />
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.getImagesComponent(props)}
                        </div>
                        <Spinner name="classroom-form-spin" />
                        <div className="modal-footer d-block">
                          <div className="row">
                            <div className="col-md">
                              <button
                                id='button_cancel'
                                type="button"
                                data-dismiss="modal"
                                className="btn btn-gray min-auto"
                                aria-label="Close"
                                onClick={this.setCloseForm}
                              >
                                {T.t('gen_cancel')}
                              </button>
                            </div>
                            <div className="col-md text-md-right">
                              <Button
                                id='button_save'
                                type="button"
                                data-dismiss="modal"
                                onClick={() => props.handleSubmit()}
                                className="btn btn-green"
                                aria-label="alert-success"
                                disabled={isSubmitting || (this.props.user && this.props.user.role == UserRole.STAFF)}
                              >
                                <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IClassroomFormProps
): Types.IClassroomFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IClassroomFormProps = Object.assign({}, ownProps, {
    form:
      store.state.classroom_page && store.state.classroom_page.form
        ? store.state.classroom_page.form
        : getInitialState(),
    selectOptions: store.state.select_options && store.state.select_options.classroomPage,
    term_type: store.state.term_type,
    term_id: store.state.term_id,
    user: store.state.user,
    general_settings: store.state.general_settings
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.classroom_page) {
    return (
      !!equal(
        prev.state.classroom_page && prev.state.classroom_page.form,
        next.state.classroom_page && next.state.classroom_page.form
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.classroomPage,
        next.state.select_options && next.state.select_options.classroomPage
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ClassroomForm);

export default container;
