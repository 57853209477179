import * as Types from '../types';
import * as actions from '../constants/all';
import { Log } from 'ng2-logger';
const L = Log.create('UserReducer');

function UserReducer(state: Types.IAppState, action: Types.IAction): Types.IAppState {
  if (action.type === actions.user.USER_LIST_SEARCH) {
    const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
    if (result && result.content) {
      state = Object.assign({}, state, {
        user_pages: {
          ...state.user_pages,
          results: result.content ? result.content.data : [],
          filters: result.content.filters
        }
      });
    }
  } else if (action.type === actions.user.USER_GET_BY_ID) {
    const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
    if (result && result.content) {
      state = Object.assign({}, state, {
        user_pages: {
          ...state.user_pages,
          form: result.content.data
        }
      });
    }
  } else if (action.type === actions.user.USER_SELECT_OPTIONS) {
    const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
    if (result && result.content) {
      state = Object.assign({}, state, {
        select_options: {
          ...state.select_options,
          userPage: result.content
        }
      });
    }
  } else if (action.type === actions.user.USER_BROWSE) {
    const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
    if (result && result.content) {
      state = Object.assign({}, state, {
        user_browse: {
          ...state.user_browse,
          results: result.content ? result.content.results : [],
          filters: result.content.filters
        }
      });
    }
  } else if (action.type === actions.user.USER_BROWSE_ALL) {
    const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
    if (result && result.content) {
      state = Object.assign({}, state, {
        user_browse: {
          ...state.user_browse,
          all_results: result.content ? result.content.results : []
        }
      });
    }
  } else if (action.type === actions.user.USER_BROWSE_GET_BY_IDS) {
    const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
    if (result && result.content) {
      state = Object.assign({}, state, {
        user_browse: {
          ...state.user_browse,
          existingUsers: result.content ? result.content.results : []
        }
      });
    }
  } else if (action.type === actions.user.USER_CREATE_BULK) {
    const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
    if (result && result.content) {
      state = Object.assign({}, state, {
        user_import_result: result.content
      });
    }
  }
  else if (action.type === actions.user.USER_GET_PROGRAMS_BY_FACULTIES) {
    const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
    if (result && result.content) {
      state = Object.assign({}, state, {
        select_options: {
          ...state.select_options,
          programs_related_faculty: result.content.programs_related_faculty
        }
      });
    }
    return state;
  }
  else if (action.type === actions.user.USER_GET_FILTER_PROGRAMS_BY_FACULTIES) {
    const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
    if (result && result.content) {
      state = Object.assign({}, state, {
        select_options: {
          ...state.select_options,
          filter_programs_related_faculty: result.content.filter_programs_related_faculty
        }
      });
    }
    return state;
  }
  else if (action.type === actions.user.USER_CREATE_EXCEL_BULK) {
    const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
    if (result && result.content) {
      state = Object.assign({}, state, {
        user_import_result: result.content
      });
    }
    return state;
  }
  else if (action.type === actions.user.USER_STAFF_CREATE_EXCEL_BULK) {
    const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
    if (result && result.content) {
      state = Object.assign({}, state, {
        user_staff_import_result: result.content
      });
    }
    return state;
  }
  return state;
}

export default UserReducer;
