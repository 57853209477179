import * as Yup from 'yup';
import Translator from '../../../services/translate-factory';
import { UserRole } from '../../../store/constants/enums';

const validation = (T: Translator) => Yup.object().shape({
  user_id: Yup.string(),
  name: Yup.string().required(T.t('gen_cannot_leave_empty')),
  surname: Yup.string().required(T.t('gen_cannot_leave_empty')),
  username: Yup.string().when('role', {
    is: UserRole.STAFF,
    then: Yup.string(),
    otherwise: Yup.string().required(T.t('gen_cannot_leave_empty'))
  }),
  email: Yup.string().when('role', {
    is: UserRole.STAFF,
    then: Yup.string(),
    otherwise: Yup.string()
      .required(T.t('gen_cannot_leave_empty'))
      .email(T.t('gen_invalid_email')),
  }),
  expires_at: Yup.date()
    .nullable(true)
    .typeError(T.t('gen_invalid_date')),
  role: Yup.string().required(T.t('gen_cannot_leave_empty')),
  classroom_of_responsibilities: Yup.array().when('role', {
    is: UserRole.EVENT_USER,
    then: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.mixed().required(T.t('gen_cannot_leave_empty')),
          label: Yup.string().required(T.t('gen_cannot_leave_empty'))
        })
      )
      .min(1, T.t('gen_cannot_leave_empty'))
      .required(T.t('gen_cannot_leave_empty')),
    otherwise: Yup.array().nullable().default([])
  }),
  // password: Yup.string()
  // .when('user_id', {
  //   is: (id: string) => !!id,
  //   then: Yup.string()
  //     .notRequired()
  //     .nullable(true),
  //   otherwise: Yup.string()
  //     .nullable(false)
  //     .required(T.t('gen_cannot_leave_empty'))
  // }),
  password_confirmation: Yup.string()
    // .when('user_id', {
    //   is: (id: string) => !!id,
    //   then: Yup.string()
    //     .notRequired()
    //     .nullable(true),
    //   otherwise: Yup.string()
    //     .nullable(false)
    //     .required(T.t('gen_cannot_leave_empty'))
    // })
    .when('password', {
      is: (password: string) => !!password,
      then: Yup.string()
        .nullable(false)
        .required(T.t('gen_cannot_leave_empty'))
        .oneOf([Yup.ref('password'), null], T.t('gen_diffrent_passwords')),
      otherwise: Yup.string().notRequired()
    })
});
export default validation;
