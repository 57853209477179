import moment from 'moment';
import 'moment/locale/tr';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Announcement from '../../../assets_aplan/images/announcement.svg';
import Spinner from '../../../components/templates/spinner';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import {
  ProgramClassroomsInitialValues,
} from '../../../store/constants/exam-period-const';
import * as Types from '../../../store/types';
import ProgramListPage from './program-list-page';
import ClassroomAddModal from '../classroom-hours-add-modal';
import ClassroomProgramListPage from '../classroom-program-listpage';
import Translator from '../../../services/translate-factory';
import * as GT from '../../../tools/general-tools';
import { ProgramSearchInitialValues } from '../../../store/constants/program-const';


const T = Translator.create();
const Logger = Log.create('ClassroomPriority');

function getInitialState(): Types.ICoursePeriod_ProgramClassroom_TabState {
  const initialValues: Types.ICoursePeriod_ProgramClassroom_TabState = {
    model: Object.assign({}, ProgramClassroomsInitialValues),
    filters: Object.assign({}, ProgramSearchInitialValues),
    programIsSelected: false
  };
  return Object.assign({}, initialValues);
}

class ClassroomPriority extends Component<Types.ICoursePeriod_ProgramClassroomProps, Types.ICoursePeriod_ProgramClassroom_TabState> {
  state: Types.ICoursePeriod_ProgramClassroom_TabState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    moment.locale('tr');
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.model.term_id = id;
    this.state.filters.term_id = id;
    this.setState(this.state);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  onSelectProgram = (e?: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>, passedID?: number) => {
    const id: string = (e && e.currentTarget.dataset.id) || '';
    let numID = parseInt(id, 10);
    let theID = passedID && passedID !== -1 ? passedID : numID;

    const selectedProgramClassroom = this.props.programList && this.props.programList.programClassrooms && this.props.programList.programClassrooms.filter((item: any) => (item.program_id === theID));
    const selectedProgramInfo = this.props.programList && this.props.programList.programInfo && this.props.programList.programInfo.filter((item: any) => (item.program_id === theID))[0];

    this.state.model.program_classroom_list = selectedProgramClassroom;
    this.state.model.program_info = selectedProgramInfo;
    this.setState({
      ...this.state,
      model: {
        ...this.state.model,
        program_classroom_list: selectedProgramClassroom,
        program_info: selectedProgramInfo
      }
    });
  };

  static getDerivedStateFromProps(props: Types.ICoursePeriod_ProgramClassroomProps, state: Types.ICoursePeriod_ProgramClassroom_TabState) {
    let hasNewState: boolean = false;
    if (props.programList && props.programList.programInfo && props.programList.programInfo.length) {

      hasNewState = true;
      state.model.program_list = props.programList.programInfo;
    }

    if (props.all_ids && props.all_ids.length) {
      hasNewState = true;
      state.model.all_ids = props.all_ids;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    return <div className="row">
      <div className={'col-xl-6 col-lg-6 col-md-6'}>
        <Spinner name="ep-course-list" />
        <div className="generic-wrapper">
          <div className="row">
            <div className="col-12">
              <div className="tab-content left-bar-content" id="nav-tabContent4">
                <div
                  className="tab-pane fade show active"
                  id="action"
                  role="tabpanel"
                  aria-labelledby="action-tab"
                >
                  <ProgramListPage
                    compactTable
                    onSelect={this.onSelectProgram}
                    selectedProgramId={
                      this.state.model.selected_program && (this.state.model.selected_program && this.state.model.selected_program.program_id)
                    }
                    checkedProgramIds={this.state.model.checked_list}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'col-xl-6 col-lg-6 col-md-6 tw-sticky tw-top-20 tw-h-fit'}>
        <div className="generic-wrapper">
          {this.state.model && this.state.model.program_classroom_list && this.state.model.program_classroom_list.length > 0 ? (
            <React.Fragment>
              <div>
                <ClassroomProgramListPage
                  programClassroomList={this.state.model.program_classroom_list ? this.state.model.program_classroom_list : undefined}
                  programInfo={this.state.model.program_info ? this.state.model.program_info : undefined}
                />
              </div>
            </React.Fragment>
          ) : (
            <div>
              <img
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '40%'
                }}
                src={Announcement}
              />
              <br />
              <p style={{ textAlign: 'center' }}>
                {T.t('gen_no_records_found')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>;
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.ICoursePeriod_ProgramClassroomProps
): Types.ICoursePeriod_ProgramClassroomProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.ICoursePeriod_ProgramClassroomProps = Object.assign({}, ownProps, {
    programList:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.courseterm_program_classrooms &&
      store.state.examPeriodModal.courseterm_program_classrooms.program_list,
    filters: store.state.examPeriodModal && store.state.examPeriodModal.filters,
    all_ids:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.courseterm_program_classrooms &&
      store.state.examPeriodModal.courseterm_program_classrooms.all_ids,
    user: store.state.user
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ClassroomPriority);

export default container;
