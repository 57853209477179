import { FormikActions } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { ExamCalendarInitialValues, ExamTermTabs } from '../../store/constants/exam-period-const';
import * as Types from '../../store/types';
import Spinner from '../../components/templates/spinner';
import Schedule from './schedule';
import InstructorHours from './instructor-hours';
import ClassroomHours from './classroom-hours';
import CourseHours from './course-hours';
import Students from './student/students';
import DistributorListPage from '../distributor/distributor-list-page';
import Translator from '../../services/translate-factory';
import { allRestirectedRoles, instructorAndManagerRoles, isUserEquals } from '../../util/authorize';

const T = Translator.create();
const Logger = Log.create('ExamPeriodForm');

function getInitialState(): Types.IExamCalendarModalState {
  const initialValues: Types.IExamCalendarModalState = {
    model: Object.assign({}, ExamCalendarInitialValues)
  };
  return Object.assign({}, initialValues);
}

class ExamPeriodForm extends Component<Types.IExamCalendarModalProps, Types.IExamCalendarModalState> {
  state: Types.IExamCalendarModalState = getInitialState();
  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };
  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.getGeneralSettings();
    this.tabRedirection();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES, {
          reset: true
        })
      );
      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_SAVE_SCHEDULE, {
          reset: true
        })
      );
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  getGeneralSettings() {
    this.props.dispatch(Actions.ApiRequest(Constants.setting.GET_GENERAL_SETTINGS, 'general-settings-spin'));
  }

  tabReloaded = () => {
    if (this.state.tab_reloaded) {
      this.state.tab_reloaded = false;
      return true;
    }
    return false;
  };

  onFormSave = (model: Types.IExamCalendarModalItem, FormActions: FormikActions<Types.IExamCalendarModalItem>) => {
    // model.user_ids = this.state.model.user_ids;
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }
        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201) {
        this.setClose(true);
      }
    };

    if (this.props.examPeriodId) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_UPDATE, model, 'exam-period-form-spin', resultCallback)
      );
    } else {
      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_CREATE, model, 'exam-period-form-spin', resultCallback)
      );
    }
    FormActions.setSubmitting(false);
  };

  selectActiveTab = (tabName: string) => {
    let tabs =
      this.state.model.tabManager &&
      this.state.model.tabManager.map((tab) => {
        return tab.key == tabName ? { key: tab.key, value: true } : { key: tab.key, value: false };
      });

    let activeTab = tabs && tabs.find((item) => item.value == true);
    let tabConstant = activeTab && activeTab.key;
    this.state.tab_reloaded = true;
    this.state.model.tabManager = tabs;
    this.setState(this.state);

    if (tabConstant == 'EXAM_PERIOD_GET_PERIOD_DATES') {
      this.props.dispatch(
        Actions.ApiRequest(
          Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES,
          this.props.examPeriodId,
          'exam-period-modal-tab-spin'
        )
      );
    }

    if (tabConstant == 'EXAM_PERIOD_GET_INSTRUCTOR_HOURS') {
      let model: Types.IFilterExamPeriodCalendarModal = {
        term_id: this.props.examPeriodId,
        active_tab: 0,
        page: 1,
        total: -1,
        size: 10
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_INSTRUCTOR_HOURS, model, 'exam-period-modal-tab-spin')
      );
    }

    if (tabConstant == 'EXAM_PERIOD_GET_CLASSROOM_HOURS') {
      let model: Types.IFilterExamPeriodCalendarModal = {
        term_id: this.props.examPeriodId,
        page: 1,
        total: -1,
        size: 10
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS, model, 'exam-period-modal-tab-spin')
      );
    }

    if (tabConstant == 'EXAM_PERIOD_GET_COURSE_HOURS' || tabConstant == 'EXAM_PERIOD_GET_EXAM_HOURS') {
      let model: Types.IFilterExamPeriodCalendarModal = {
        term_id: this.props.examPeriodId,
        page: 1,
        total: -1,
        size: 10,
        status: [1]
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS, model, 'exam-period-modal-tab-spin')
      );
    }

    if (tabConstant == 'EXAM_PERIOD_GET_STUDENTS') {
      let model: Types.IFilterExamPeriodCalendarModal = {
        term_id: this.props.examPeriodId,
        page: 1,
        total: -1,
        size: 10
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_STUDENTS, model, 'exam-period-modal-tab-spin')
      );
    }

    if (tabConstant == 'DISTRIBUTOR:GET_DISTRIBUTION_LIST') {
      let model: Types.IFilterDistributor = {
        term_ids: [this.props.examPeriodId ? this.props.examPeriodId : -1],
        page: 1,
        total: -1,
        size: 10
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_GET_DISTRIBUTION_LIST, model, 'distribution-list-spin')
      );
    }
  };

  tabRedirection() {
    this.state.tab_number = Number(window.location.pathname.substr(window.location.pathname.lastIndexOf("/") - 1, 1));

    let selectedTab = this.state.tab_number == ExamTermTabs.ExamDates ?
      Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES :
      this.state.tab_number == ExamTermTabs.Classrooms ?
        Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS :
        this.state.tab_number == ExamTermTabs.Courses ?
          Constants.exam_period.EXAM_PERIOD_GET_COURSE_HOURS :
          this.state.tab_number == ExamTermTabs.Instructors ?
            Constants.exam_period.EXAM_PERIOD_GET_INSTRUCTOR_HOURS :
            this.state.tab_number == ExamTermTabs.ExamDatesAndClassrooms ?
              Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS :
              this.state.tab_number == ExamTermTabs.Students ?
                Constants.exam_period.EXAM_PERIOD_GET_STUDENTS :
                this.state.tab_number == ExamTermTabs.Distributor ?
                  Constants.exam_period.DISTRIBUTOR_GET_DISTRIBUTION_LIST : null;


    if (isUserEquals(this.props.user, instructorAndManagerRoles)) {
      if (this.props.general_settings && (this.props.general_settings.restrict_hours_type_instructors === 1 || this.props.general_settings.restrict_hours_type_program_managers === 1)) {
        selectedTab = Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS
      }
    }

    if (selectedTab != null) {
      let tabs =
        this.state.model.tabManager &&
        this.state.model.tabManager.map((tab) => {
          return tab.key == selectedTab ? { key: tab.key, value: true } : { key: tab.key, value: false };
        });
      this.state.model.tabManager = tabs;
      this.isActiveTab(selectedTab);
      this.selectActiveTab(selectedTab);
    }
  }

  isActiveTab = (tabName: string) => {
    let activeTab = this.state.model.tabManager && this.state.model.tabManager.find((item) => item.key == tabName);
    if (activeTab) {
      return activeTab.value;
    } else return false;
  };

  static getDerivedStateFromProps(props: Types.IExamCalendarModalProps, state: Types.IExamCalendarModalState) {
    let hasNewState: boolean = false;
    if (props.examPeriodId && props.examPeriodId != state.model.term_id) {
      state.model.term_id = props.examPeriodId;
      props.dispatch(
        Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES, {
          reset: true
        })
      );
      props.dispatch(
        Actions.ApiRequest(
          Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES,
          props.examPeriodId,
          'exam-period-modal-tab-spin'
        )
      );
      hasNewState = true;
    }

    if (state.model.name !== props.name) {
      hasNewState = true;
      state.model.name = props.name;
    }

    if (hasNewState) {
      return state;
    } else if (!props.examPeriodId && state.model.term_id) {
      return getInitialState();
    } else {
      return null;
    }
  }

  render() {
    return (
      <div
        className="main editor-screen-main"
        style={{
          display: this.props.formIsOpen ? 'block' : 'none'
        }}
      >
        <div
          id="editorScreen"
          className="white-container mt-4 editor-screen collapse editor-screen"
          style={{
            display: 'block',
            minHeight: '100vh',
            marginTop: '0 !important'
          }}
        >
          <div className="row">
            <div className="col-10">
              <h5 className="editor-title">
                <i className="material-icons mr-2" style={{ verticalAlign: 'sub' }}>
                  event_note
                </i>
                {this.state.model.name ? this.state.model.name : 'Yükleniyor...'}
              </h5>
            </div>
            <div className="col-2">
              <button
                type="button"
                data-toggle="collapse"
                data-target="#editorScreen"
                onClick={this.setCloseForm}
                aria-expanded="true"
                className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn float-right"
              >
                <i className="material-icons">close</i>
              </button>
            </div>
          </div>
          <div className="row">
            <Spinner name="exam-period-modal-tab-spin" />
            <div className="col-12">
              <nav className="inner-page-nav">
                <div className="nav nav-tabs nav-fill mb-4" id="nav-tab" role="tablist">
                  <a
                    className={
                      this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES)
                        ? 'nav-item nav-link active'
                        : 'nav-item nav-link'
                    }
                    id="dates-tab"
                    data-toggle="tab"
                    href="#dates"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    {T.t('gen_exam_dates')}
                  </a>
                  {this.props.user && (this.props.user.role == 'e' || this.props.user.role == 'd' || this.props.user.role === 'p' || this.props.user.role === 'r') ? null : (
                    <a
                      className={
                        this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS)
                          ? 'nav-item nav-link active'
                          : 'nav-item nav-link'
                      }
                      onClick={() => this.selectActiveTab(Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS)}
                      id="classrooms-tab"
                      data-toggle="tab"
                      href="#classrooms"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      {T.t('gen_classrooms')}
                    </a>
                  )}
                  {this.props.user && (this.props.user.role == 'e' || this.props.user.role == 'd' || this.props.user.role === 'p' || this.props.user.role === 'r') ? null : (
                    <a
                      className={
                        this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_COURSE_HOURS)
                          ? 'nav-item nav-link active'
                          : 'nav-item nav-link'
                      }
                      onClick={() => this.selectActiveTab(Constants.exam_period.EXAM_PERIOD_GET_COURSE_HOURS)}
                      id="course-tab"
                      data-toggle="tab"
                      href="#courses"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      {T.t('gen_courses')}
                    </a>
                  )}
                  {this.props.user && this.props.general_settings &&
                    (((this.props.user.role == 'e' || this.props.user.role == 'd' || this.props.user.role === 'p' || this.props.user.role === 'r') && this.props.general_settings.restrict_hours_type_instructors === 2) ||
                      ((this.props.user.role == 'a' || this.props.user.role == 'b') && this.props.general_settings.restrict_hours_type_program_managers === 2))
                    ? null
                    : (
                      <a
                        className={this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_INSTRUCTOR_HOURS)
                          ? 'nav-item nav-link active'
                          : 'nav-item nav-link'
                        }
                        onClick={() => this.selectActiveTab(Constants.exam_period.EXAM_PERIOD_GET_INSTRUCTOR_HOURS)}
                        id="personnals-tab"
                        data-toggle="tab"
                        href="#personnals"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                        style={{
                          display: isUserEquals(this.props.user, allRestirectedRoles) ? 'none' : ''
                        }}
                      >
                        {this.props.user && (this.props.user.role == 'e' || this.props.user.role == 'd' || this.props.user.role === 'p' || this.props.user.role === 'r') ? T.t('gen_calendar_my_avaliable_hours') : T.t('gen_instructors')}
                      </a>
                    )}
                  <a
                    className={this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS)
                      ? 'nav-item nav-link active'
                      : 'nav-item nav-link'
                    }
                    onClick={() => this.selectActiveTab(Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS)}
                    id="exam-tab"
                    data-toggle="tab"
                    href="#exams"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    {T.t('gen_calendar_my_exam_hours_and_classrooms')}
                  </a>
                  {this.props.user && this.props.user.role === 's' && (
                    <a
                      className={
                        this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_STUDENTS)
                          ? 'nav-item nav-link active'
                          : 'nav-item nav-link'
                      }
                      onClick={() => this.selectActiveTab(Constants.exam_period.EXAM_PERIOD_GET_STUDENTS)}
                      id="students-tab"
                      data-toggle="tab"
                      href="#students"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      {T.t('gen_students_lectures')}
                    </a>
                  )}
                  {this.props.user && this.props.user.role === 's' &&
                    (
                      <a
                        className={
                          this.isActiveTab(Constants.exam_period.DISTRIBUTOR_GET_DISTRIBUTION_LIST)
                            ? 'nav-item nav-link active'
                            : 'nav-item nav-link'
                        }
                        onClick={() => this.selectActiveTab(Constants.exam_period.DISTRIBUTOR_GET_DISTRIBUTION_LIST)}
                        id="distribute-exams-tab"
                        data-toggle="tab"
                        href="#distribute-exams"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('gen_distribute_exams')}
                      </a>
                    )}
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className={
                    this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES)
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  }
                  id="dates"
                  role="tabpanel"
                  aria-labelledby="dates-tab"
                >
                  <Schedule />
                </div>
                <div
                  className={
                    this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_INSTRUCTOR_HOURS)
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  }
                  id="personnals"
                  role="tabpanel"
                  aria-labelledby="personnals-tab"
                >
                  {this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_INSTRUCTOR_HOURS) ? (
                    <InstructorHours tabReloaded={this.tabReloaded} />
                  ) : null}
                </div>
                <div
                  className={
                    this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS)
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  }
                  id="classrooms"
                  role="tabpanel"
                  aria-labelledby="classrooms-tab"
                >
                  {this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS) ? (
                    <ClassroomHours />
                  ) : null}
                </div>
                <div
                  className={
                    this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_COURSE_HOURS)
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  }
                  id="courses"
                  role="tabpanel"
                  aria-labelledby="course-tab"
                >
                  {this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_COURSE_HOURS) ? (
                    <CourseHours calledFromCourseTab={true} />
                  ) : null}
                </div>
                <div
                  className={
                    this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS)
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  }
                  id="exams"
                  role="tabpanel"
                  aria-labelledby="exam-tab"
                >
                  {this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS) ? (
                    <CourseHours calledFromCourseTab={false} />
                  ) : null}
                </div>
                <div
                  className={
                    this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_STUDENTS)
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  }
                  id="students"
                  role="tabpanel"
                  aria-labelledby="students-tab"
                >
                  {this.isActiveTab(Constants.exam_period.EXAM_PERIOD_GET_STUDENTS) ? <Students /> : null}
                </div>
                <div
                  className={
                    this.isActiveTab(Constants.exam_period.DISTRIBUTOR_GET_DISTRIBUTION_LIST)
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  }
                  id="distribute-exams"
                  role="tabpanel"
                  aria-labelledby="distribute-exams-tab"
                >
                  {this.isActiveTab(Constants.exam_period.DISTRIBUTOR_GET_DISTRIBUTION_LIST) ? <DistributorListPage /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IExamCalendarModalProps
): Types.IExamCalendarModalProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IExamCalendarModalProps = Object.assign({}, ownProps, {
    model: store.state.examPeriodModal,
    name:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.exam_dates &&
      store.state.examPeriodModal.exam_dates.name,
    user: store.state.user,
    general_settings: store.state.general_settings,
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.examPeriodModal) {
    return (
      !!equal(
        prev.state.examPeriodModal,
        next.state.examPeriodModal) &&
      !!equal(
        prev.state && prev.state.general_settings,
        next.state && next.state.general_settings
      ));
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ExamPeriodForm);

export default container;
