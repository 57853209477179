import moment from "moment"
import { DateLocalizer, momentLocalizer } from "react-big-calendar"
import Translator, { Language } from "../services/translate-factory"
import { ISelectOption } from "../store/types"

export const getMomentLocale = (locale: string) => {
    return locale.split('-')[0]
}


export const getLocalizedDateLocalizer = (T: Translator): DateLocalizer => {

    moment.locale(getMomentLocale(T.getSelectedLanguage()), {
        week: {
            dow: 1,//
            doy: 4,// First day of year is Monday
        }
    })

    return momentLocalizer(moment)
}

export const getLocalizedDate = (date: Date, T: Translator) => {
    const selectedLang = T.getSelectedLanguage()
    return new Intl.DateTimeFormat(selectedLang, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    })
        .format(date)
        .toString();
}

export function formatDate(value: string | Date, T: Translator) {
    if (typeof value === 'string') {
        try {
            return getLocalizedDate(new Date(value), T)
        } catch (error) {
            return value;
        }

    } else if (value instanceof Date) {
        return getLocalizedDate(value, T)
    } else {
        return '';
    }
}
const sortType = (a: ISelectOption, b: ISelectOption) => {
    return a.label.localeCompare(b.label)
}

export const localizeSelectOptions = (selectOptions?: ISelectOption[] | null, localizeFile?: ISelectOption[] | null) => {
    if (selectOptions) {
        return selectOptions.map((item) => {
            const search = localizeFile && localizeFile.find((i) => i.value == item.value)
            return search ? search : item
        }).sort(sortType)
    }
    return []
}

export const getConstantVariants = (
    selectOptions: (T: Translator, opts?: any) => ISelectOption[],
    T: Translator
) => {
    const languages = Object.values(Language);

    return languages.flatMap(lang => {
        return selectOptions(T, { lng: lang })
    })

}