import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Log } from 'ng2-logger';
import { Modal, UncontrolledTooltip } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { SectionTypes, SectionTypeSolutionOptionExamTerm, SectionTypeSolutionOptionCourseTerm } from '../../store/constants/enums';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';
const T = Translator.create();

const L = Log.create('ReportsDownloadModal');

function getInitialState(): any {
  const initialValues: any = {
    selectedOptions: [],
    select_all: false,
    solution_id: -1,
    isPublished: false
  };
  return Object.assign({}, initialValues);
}

function removeArrayItem(selectedList: number[], value: number) {
  let index = selectedList.indexOf(value);
  selectedList.splice(index, 1);
  return selectedList;
}

function getAllSelections(term_type: number, role: string, isPublished: boolean) {
  let selectedList = Object.values(term_type === 0 ? SectionTypeSolutionOptionCourseTerm : SectionTypeSolutionOptionExamTerm).filter(value => typeof value === 'number');

  if (term_type === 0 && role !== 's') {
    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.CrossTable);
    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.Ois);
    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.ObsFormatOne);
    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.ObsFormatTwo);
    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.ObsFormatThree);
    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.UnipaFormatOne);
    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.UnipaFormatTwo);
    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.InstructorWeeklyCourseLoadZip);
    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.Sap);
    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.InstructorOverlappingCourses);
    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.ClassroomOverlappingCourses);
  }

  if (term_type === 1) {
    if (role !== 's') {
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.Obs);
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.Invigilators);
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.InstructorOverlappingExams);
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.InstructorConsecutiveExams);
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.Ois);
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.OisStudents);
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.UnipaStudents);
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.StudentExamCounts);
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.StudentExams);
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.StudentDensity);
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.StudentOverlappingExams);
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.StudentConsecutiveExams);
    } else if (!isPublished) {
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.StudentExamCounts);
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.StudentExams);
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.StudentDensity);
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.StudentOverlappingExams);
      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.StudentConsecutiveExams);
    }
  }

  return selectedList;
}

class ReportsDownloadModal extends Component<any, any> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }
  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onDownloadByOptions(e: React.MouseEvent<HTMLSpanElement>) {
    if (e && e.currentTarget) {
      if (this.state.selectedOptions.length > 0) {
        const model = {
          termId: this.props.term_id,
          sectionType: SectionTypes.Solution,
          sectionId: this.state.solution_id,
          parameters: this.state.selectedOptions
        };
        this.props.dispatch(Actions.ApiRequest(Constants.building.DOWNLOAD_ALL_DATA, model, ''));
        this.setCloseModal();
      } else {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_bulk_combine_exams_no_options_selected_title'),
            body: T.t('gen_bulk_combine_exams_no_options_selected_body'),
            name: 'bulk',
            icon: 'info_outline',
            iconColor: 'red',
            confirm: T.t('gen_ok'),
          })
        );
      }
    }
  }

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;

    if (props.isPublished && props.isPublished != state.isPublished) {
      hasNewState = true;
      state.isPublished = props.isPublished;
    }
    if (props.solution_id != state.solution_id) {
      hasNewState = true;
      state.solution_id = props.solution_id;
    }

    let selectedList = getAllSelections(props.term_type, props.user && props.user.role, state.isPublished);
    if (state.select_all && selectedList.length != state.selectedOptions.length) {
      hasNewState = true;
      state.select_all = false;
    } else if (!state.select_all && selectedList.length == state.selectedOptions.length) {
      hasNewState = true;
      state.select_all = true;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    return (
      <Modal className="pt-0" style={{ maxWidth: '100%', padding: '0 15px' }} isOpen={this.props.modalIsOpen} toggle={this.setCloseModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_download_detailed_reports')}
            </h6>
            <button id='button_close' type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.setCloseModal}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">

              {/* GROUPS STARTS HERE*/}
              {this.props.term_type === 1 && this.props.user.role === 's' && !this.state.isPublished ? (
                <div className="row ml-3">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-12 col-xs-12 text-center">
                        <h5 className="mt-4">{T.t('gen_notification_reports_download_unpublished_solution')}</h5>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              ) : null}

              {/* OPTIONS STARTS HERE*/}
              <div className="row">
                <div className="col-12">
                  <h6 id="report_options" className="col-3 mb-3 ml-3">{T.t('gen_report_options')} <i className="material-icons mb-1">info_outlined</i></h6>
                  <UncontrolledTooltip placement="top" target="report_options">
                    {T.t('gen_report_options_info')}
                  </UncontrolledTooltip>
                </div>
              </div>

              <div className="row ml-3">
                <div className="col-md-12">
                  <div className="tick-radio d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        id='select_all'
                        type="checkbox"
                        className="form-radio"
                        style={{ backgroundColor: 'blue', }}
                        checked={this.state.select_all}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedOptions;
                            if (e.currentTarget.checked) {
                              this.state.select_all = true;
                              selectedList = getAllSelections(this.props.term_type, this.props.user && this.props.user.role, this.state.isPublished);
                            } else {
                              this.state.select_all = false;
                              selectedList = [];
                            }
                            this.state.selectedOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;<b>{T.t('gen_select_all')}</b>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row ml-3">
                {this.props.term_type === 0 ? (
                  <React.Fragment>
                    <div className="col-md-6">
                      <div className="tick-radio d-block">
                        <label style={{ cursor: 'pointer' }}>
                          <input
                            id='option_data'
                            type="checkbox"
                            className="form-radio"
                            style={{ backgroundColor: 'blue' }}
                            checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                              item == SectionTypeSolutionOptionCourseTerm.Data) == SectionTypeSolutionOptionCourseTerm.Data ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (e && e.currentTarget) {
                                let selectedList = this.state.selectedOptions;
                                if (e.currentTarget.checked) {
                                  selectedList.push(SectionTypeSolutionOptionCourseTerm.Data);
                                } else {
                                  selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.Data);
                                }
                                this.state.selectedOptions = selectedList;
                                this.setState(this.state);
                              }
                            }}
                          />
                          &nbsp;{T.t('gen_course_report_data')}
                        </label>
                      </div>
                      <div className="tick-radio position-relative d-block">
                        <label style={{ cursor: 'pointer' }}>
                          <input
                            id='option_classroom_schedule'
                            type="checkbox"
                            className="form-radio"
                            style={{ backgroundColor: 'blue' }}
                            checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                              item == SectionTypeSolutionOptionCourseTerm.ClassroomSchedule) == SectionTypeSolutionOptionCourseTerm.ClassroomSchedule}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (e && e.currentTarget) {
                                let selectedList = this.state.selectedOptions;
                                if (e.currentTarget.checked) {
                                  selectedList.push(SectionTypeSolutionOptionCourseTerm.ClassroomSchedule);
                                } else {
                                  selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.ClassroomSchedule);
                                }
                                this.state.selectedOptions = selectedList;
                                this.setState(this.state);
                              }
                            }}
                          />
                          &nbsp;{T.t('gen_course_report_classroom_schedule')}
                        </label>
                      </div>
                      <div className="tick-radio position-relative d-block">
                        <label style={{ cursor: 'pointer' }}>
                          <input
                            id='option_instructor_workload_hourly'
                            type="checkbox"
                            className="form-radio"
                            style={{ backgroundColor: 'blue' }}
                            checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                              item == SectionTypeSolutionOptionCourseTerm.InstructorWorkloadHourly) == SectionTypeSolutionOptionCourseTerm.InstructorWorkloadHourly}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (e && e.currentTarget) {
                                let selectedList = this.state.selectedOptions;
                                if (e.currentTarget.checked) {
                                  selectedList.push(SectionTypeSolutionOptionCourseTerm.InstructorWorkloadHourly);
                                } else {
                                  selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.InstructorWorkloadHourly);
                                }
                                this.state.selectedOptions = selectedList;
                                this.setState(this.state);
                              }
                            }}
                          />
                          &nbsp;{T.t('gen_course_report_instructor_workload_hourly')}
                        </label>
                      </div>
                      <div className="tick-radio position-relative d-block">
                        <label style={{ cursor: 'pointer' }}>
                          <input
                            type="checkbox"
                            id="option_classroom_usage_weekly"
                            className="form-radio"
                            style={{ backgroundColor: 'blue' }}
                            checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                              item == SectionTypeSolutionOptionCourseTerm.ClassroomUsageWeekly) == SectionTypeSolutionOptionCourseTerm.ClassroomUsageWeekly}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (e && e.currentTarget) {
                                let selectedList = this.state.selectedOptions;
                                if (e.currentTarget.checked) {
                                  selectedList.push(SectionTypeSolutionOptionCourseTerm.ClassroomUsageWeekly);
                                } else {
                                  selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.ClassroomUsageWeekly);
                                }
                                this.state.selectedOptions = selectedList;
                                this.setState(this.state);
                              }
                            }}
                          />
                          &nbsp;{T.t('gen_course_report_classroom_usage_weekly')}
                        </label>
                      </div>
                      {
                        this.props.user && this.props.user.role === 's' ? (
                          <div className="tick-radio position-relative d-block">
                            <label style={{ cursor: 'pointer' }}>
                              <input
                                id='option_ois'
                                type="checkbox"
                                className="form-radio"
                                style={{ backgroundColor: 'blue' }}
                                checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                  item == SectionTypeSolutionOptionCourseTerm.Ois) == SectionTypeSolutionOptionCourseTerm.Ois}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (e && e.currentTarget) {
                                    let selectedList = this.state.selectedOptions;
                                    if (e.currentTarget.checked) {
                                      selectedList.push(SectionTypeSolutionOptionCourseTerm.Ois);
                                    } else {
                                      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.Ois);
                                    }
                                    this.state.selectedOptions = selectedList;
                                    this.setState(this.state);
                                  }
                                }}
                              />
                              &nbsp;{T.t('gen_course_report_ois')}
                            </label>
                          </div>) : null
                      }
                      {
                        this.props.user && this.props.user.role === 's' ? (
                          <div className="tick-radio position-relative d-block">
                            <label style={{ cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                id="option_unipa_format_one"
                                className="form-radio"
                                style={{ backgroundColor: 'blue' }}
                                checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                  item == SectionTypeSolutionOptionCourseTerm.UnipaFormatOne) == SectionTypeSolutionOptionCourseTerm.UnipaFormatOne}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (e && e.currentTarget) {
                                    let selectedList = this.state.selectedOptions;
                                    if (e.currentTarget.checked) {
                                      selectedList.push(SectionTypeSolutionOptionCourseTerm.UnipaFormatOne);
                                    } else {
                                      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.UnipaFormatOne);
                                    }
                                    this.state.selectedOptions = selectedList;
                                    this.setState(this.state);
                                  }
                                }}
                              />
                              &nbsp;{T.t('gen_course_report_unipa_format_one')}
                            </label>
                          </div>) : null
                      }
                      {
                        this.props.user && this.props.user.role === 's' ? (
                          <div className="tick-radio position-relative d-block">
                            <label style={{ cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                id="option_unipa_format_two"
                                className="form-radio"
                                style={{ backgroundColor: 'blue' }}
                                checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                  item == SectionTypeSolutionOptionCourseTerm.UnipaFormatTwo) == SectionTypeSolutionOptionCourseTerm.UnipaFormatTwo}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (e && e.currentTarget) {
                                    let selectedList = this.state.selectedOptions;
                                    if (e.currentTarget.checked) {
                                      selectedList.push(SectionTypeSolutionOptionCourseTerm.UnipaFormatTwo);
                                    } else {
                                      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.UnipaFormatTwo);
                                    }
                                    this.state.selectedOptions = selectedList;
                                    this.setState(this.state);
                                  }
                                }}
                              />
                              &nbsp;{T.t('gen_course_report_unipa_format_two')}
                            </label>
                          </div>) : null
                      }
                      {
                        this.props.user && this.props.user.role === 's' ? (
                          <div className="tick-radio position-relative d-block">
                            <label style={{ cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                id="option_instructor_overlapping_courses"
                                className="form-radio"
                                style={{ backgroundColor: 'blue' }}
                                checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                  item == SectionTypeSolutionOptionCourseTerm.InstructorOverlappingCourses) == SectionTypeSolutionOptionCourseTerm.InstructorOverlappingCourses}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (e && e.currentTarget) {
                                    let selectedList = this.state.selectedOptions;
                                    if (e.currentTarget.checked) {
                                      selectedList.push(SectionTypeSolutionOptionCourseTerm.InstructorOverlappingCourses);
                                    } else {
                                      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.InstructorOverlappingCourses);
                                    }
                                    this.state.selectedOptions = selectedList;
                                    this.setState(this.state);
                                  }
                                }}
                              />
                              &nbsp;{T.t('gen_course_instructor_overlapping_courses')}
                            </label>
                          </div>) : null
                      }
                      {
                        this.props.user && this.props.user.role === 's' ? (
                          <div className="tick-radio position-relative d-block">
                            <label style={{ cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                id="option_classroom_overlapping_courses"
                                className="form-radio"
                                style={{ backgroundColor: 'blue' }}
                                checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                  item == SectionTypeSolutionOptionCourseTerm.ClassroomOverlappingCourses) == SectionTypeSolutionOptionCourseTerm.ClassroomOverlappingCourses}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (e && e.currentTarget) {
                                    let selectedList = this.state.selectedOptions;
                                    if (e.currentTarget.checked) {
                                      selectedList.push(SectionTypeSolutionOptionCourseTerm.ClassroomOverlappingCourses);
                                    } else {
                                      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.ClassroomOverlappingCourses);
                                    }
                                    this.state.selectedOptions = selectedList;
                                    this.setState(this.state);
                                  }
                                }}
                              />
                              &nbsp;{T.t('gen_course_classroom_overlapping_courses')}
                            </label>
                          </div>) : null
                      }
                    </div>

                    <div className="col-md-6">
                      <div className="tick-radio d-block">
                        <label style={{ cursor: 'pointer' }}>
                          <input
                            id="option_student_density"
                            type="checkbox"
                            className="form-radio"
                            style={{ backgroundColor: 'blue' }}
                            checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                              item == SectionTypeSolutionOptionCourseTerm.StudentDensity) == SectionTypeSolutionOptionCourseTerm.StudentDensity}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (e && e.currentTarget) {
                                let selectedList = this.state.selectedOptions;
                                if (e.currentTarget.checked) {
                                  selectedList.push(SectionTypeSolutionOptionCourseTerm.StudentDensity);
                                } else {
                                  selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.StudentDensity);
                                }
                                this.state.selectedOptions = selectedList;
                                this.setState(this.state);
                              }
                            }}
                          />
                          &nbsp;{T.t('gen_course_report_student_density')}
                        </label>
                      </div>
                      <div className="tick-radio position-relative d-block">
                        <label style={{ cursor: 'pointer' }}>
                          <input
                            id="option_classroom_usage_daily"
                            type="checkbox"
                            className="form-radio"
                            style={{ backgroundColor: 'blue' }}
                            checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                              item == SectionTypeSolutionOptionCourseTerm.ClassroomUsageDaily) == SectionTypeSolutionOptionCourseTerm.ClassroomUsageDaily}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (e && e.currentTarget) {
                                let selectedList = this.state.selectedOptions;
                                if (e.currentTarget.checked) {
                                  selectedList.push(SectionTypeSolutionOptionCourseTerm.ClassroomUsageDaily);
                                } else {
                                  selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.ClassroomUsageDaily);
                                }
                                this.state.selectedOptions = selectedList;
                                this.setState(this.state);
                              }
                            }}
                          />
                          &nbsp;{T.t('gen_course_report_classroom_usage_daily')}
                        </label>
                      </div>
                      <div className="tick-radio position-relative d-block">
                        <label style={{ cursor: 'pointer' }}>
                          <input
                            id="option_instructor_density"
                            type="checkbox"
                            className="form-radio"
                            style={{ backgroundColor: 'blue' }}
                            checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                              item == SectionTypeSolutionOptionCourseTerm.InstructorDensity) == SectionTypeSolutionOptionCourseTerm.InstructorDensity}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (e && e.currentTarget) {
                                let selectedList = this.state.selectedOptions;
                                if (e.currentTarget.checked) {
                                  selectedList.push(SectionTypeSolutionOptionCourseTerm.InstructorDensity);
                                } else {
                                  selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.InstructorDensity);
                                }
                                this.state.selectedOptions = selectedList;
                                this.setState(this.state);
                              }
                            }}
                          />
                          &nbsp;{T.t('gen_course_report_instructor_density')}
                        </label>
                      </div>
                      {
                        this.props.user && this.props.user.role === 's' ? (
                          <div className="tick-radio position-relative d-block">
                            <label style={{ cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                id="option_cross_table"
                                className="form-radio"
                                style={{ backgroundColor: 'blue' }}
                                checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                  item == SectionTypeSolutionOptionCourseTerm.CrossTable) == SectionTypeSolutionOptionCourseTerm.CrossTable}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (e && e.currentTarget) {
                                    let selectedList = this.state.selectedOptions;
                                    if (e.currentTarget.checked) {
                                      selectedList.push(SectionTypeSolutionOptionCourseTerm.CrossTable);
                                    } else {
                                      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.CrossTable);
                                    }
                                    this.state.selectedOptions = selectedList;
                                    this.setState(this.state);
                                  }
                                }}
                              />
                              &nbsp;{T.t('gen_course_report_cross_table')}
                            </label>
                          </div>) : null
                      }
                      {
                        this.props.user && this.props.user.role === 's' ? (
                          <div className="tick-radio position-relative d-block">
                            <label style={{ cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                id="option_obs_format_one"
                                className="form-radio"
                                style={{ backgroundColor: 'blue' }}
                                checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                  item == SectionTypeSolutionOptionCourseTerm.ObsFormatOne) == SectionTypeSolutionOptionCourseTerm.ObsFormatOne}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (e && e.currentTarget) {
                                    let selectedList = this.state.selectedOptions;
                                    if (e.currentTarget.checked) {
                                      selectedList.push(SectionTypeSolutionOptionCourseTerm.ObsFormatOne);
                                    } else {
                                      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.ObsFormatOne);
                                    }
                                    this.state.selectedOptions = selectedList;
                                    this.setState(this.state);
                                  }
                                }}
                              />
                              &nbsp;{T.t('gen_course_report_obs_format_one')}
                            </label>
                          </div>) : null
                      }
                      {
                        this.props.user && this.props.user.role === 's' ? (
                          <div className="tick-radio position-relative d-block">
                            <label style={{ cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                id="option_obs_format_two"
                                className="form-radio"
                                style={{ backgroundColor: 'blue' }}
                                checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                  item == SectionTypeSolutionOptionCourseTerm.ObsFormatTwo) == SectionTypeSolutionOptionCourseTerm.ObsFormatTwo}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (e && e.currentTarget) {
                                    let selectedList = this.state.selectedOptions;
                                    if (e.currentTarget.checked) {
                                      selectedList.push(SectionTypeSolutionOptionCourseTerm.ObsFormatTwo);
                                    } else {
                                      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.ObsFormatTwo);
                                    }
                                    this.state.selectedOptions = selectedList;
                                    this.setState(this.state);
                                  }
                                }}
                              />
                              &nbsp;{T.t('gen_course_report_obs_format_two')}
                            </label>
                          </div>) : null
                      }
                      {
                        this.props.user && this.props.user.role === 's' ? (
                          <div className="tick-radio position-relative d-block">
                            <label style={{ cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                id="option_obs_format_three"
                                className="form-radio"
                                style={{ backgroundColor: 'blue' }}
                                checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                  item == SectionTypeSolutionOptionCourseTerm.ObsFormatThree) == SectionTypeSolutionOptionCourseTerm.ObsFormatThree}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (e && e.currentTarget) {
                                    let selectedList = this.state.selectedOptions;
                                    if (e.currentTarget.checked) {
                                      selectedList.push(SectionTypeSolutionOptionCourseTerm.ObsFormatThree);
                                    } else {
                                      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.ObsFormatThree);
                                    }
                                    this.state.selectedOptions = selectedList;
                                    this.setState(this.state);
                                  }
                                }}
                              />
                              &nbsp;{T.t('gen_course_report_obs_format_three')}
                            </label>
                          </div>) : null
                      }
                      {
                        this.props.user && this.props.user.role === 's' ? (
                          <div className="tick-radio position-relative d-block">
                            <label style={{ cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                id="option_sap"
                                className="form-radio"
                                style={{ backgroundColor: 'blue' }}
                                checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                  item == SectionTypeSolutionOptionCourseTerm.Sap) == SectionTypeSolutionOptionCourseTerm.Sap}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (e && e.currentTarget) {
                                    let selectedList = this.state.selectedOptions;
                                    if (e.currentTarget.checked) {
                                      selectedList.push(SectionTypeSolutionOptionCourseTerm.Sap);
                                    } else {
                                      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.Sap);
                                    }
                                    this.state.selectedOptions = selectedList;
                                    this.setState(this.state);
                                  }
                                }}
                              />
                              &nbsp;{T.t('gen_course_report_sap')}
                            </label>
                          </div>) : null
                      }
                      {
                        this.props.user && this.props.user.role === 's' ? (
                          <div className="tick-radio position-relative d-block">
                            <label style={{ cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                id="option_instructor_weekly_course_load"
                                className="form-radio"
                                style={{ backgroundColor: 'blue' }}
                                checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                  item == SectionTypeSolutionOptionCourseTerm.InstructorWeeklyCourseLoadZip) == SectionTypeSolutionOptionCourseTerm.InstructorWeeklyCourseLoadZip}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (e && e.currentTarget) {
                                    let selectedList = this.state.selectedOptions;
                                    if (e.currentTarget.checked) {
                                      selectedList.push(SectionTypeSolutionOptionCourseTerm.InstructorWeeklyCourseLoadZip);
                                    } else {
                                      selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionCourseTerm.InstructorWeeklyCourseLoadZip);
                                    }
                                    this.state.selectedOptions = selectedList;
                                    this.setState(this.state);
                                  }
                                }}
                              />
                              &nbsp;{T.t('gen_course_instructor_weekly_course_load')}
                            </label>
                          </div>) : null
                      }
                    </div>
                  </React.Fragment>
                ) : (
                    <React.Fragment>
                      <div className="col-md-6">
                        <div className="tick-radio d-block">
                          <label style={{ cursor: 'pointer' }}>
                            <input
                              id='option_data'
                              type="checkbox"
                              className="form-radio"
                              style={{ backgroundColor: 'blue' }}
                              checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                item == SectionTypeSolutionOptionExamTerm.Data) == SectionTypeSolutionOptionExamTerm.Data}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e && e.currentTarget) {
                                  let selectedList = this.state.selectedOptions;
                                  if (e.currentTarget.checked) {
                                    selectedList.push(SectionTypeSolutionOptionExamTerm.Data);
                                  } else {
                                    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.Data);
                                  }
                                  this.state.selectedOptions = selectedList;
                                  this.setState(this.state);
                                }
                              }}
                            />
                            &nbsp;{T.t('gen_exam_report_data')}
                          </label>
                        </div>
                        <div className="tick-radio position-relative d-block">
                          <label style={{ cursor: 'pointer' }}>
                            <input
                              id='option_invigilator_list_total'
                              type="checkbox"
                              className="form-radio"
                              style={{ backgroundColor: 'blue' }}
                              checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                item == SectionTypeSolutionOptionExamTerm.InvigilatorListTotal) == SectionTypeSolutionOptionExamTerm.InvigilatorListTotal}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e && e.currentTarget) {
                                  let selectedList = this.state.selectedOptions;
                                  if (e.currentTarget.checked) {
                                    selectedList.push(SectionTypeSolutionOptionExamTerm.InvigilatorListTotal);
                                  } else {
                                    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.InvigilatorListTotal);
                                  }
                                  this.state.selectedOptions = selectedList;
                                  this.setState(this.state);
                                }
                              }}
                            />
                            &nbsp;{T.t('gen_exam_report_invigilator_list_total')}
                          </label>
                        </div>
                        <div className="tick-radio position-relative d-block">
                          <label style={{ cursor: 'pointer' }}>
                            <input
                              type="checkbox"
                              id="option_invigilator_list_daily"
                              className="form-radio"
                              style={{ backgroundColor: 'blue' }}
                              checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                item == SectionTypeSolutionOptionExamTerm.InvigilatorListDaily) == SectionTypeSolutionOptionExamTerm.InvigilatorListDaily}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e && e.currentTarget) {
                                  let selectedList = this.state.selectedOptions;
                                  if (e.currentTarget.checked) {
                                    selectedList.push(SectionTypeSolutionOptionExamTerm.InvigilatorListDaily);
                                  } else {
                                    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.InvigilatorListDaily);
                                  }
                                  this.state.selectedOptions = selectedList;
                                  this.setState(this.state);
                                }
                              }}
                            />
                            &nbsp;{T.t('gen_exam_report_invigilator_list_daily')}
                          </label>
                        </div>
                        <div className="tick-radio position-relative d-block">
                          <label style={{ cursor: 'pointer' }}>
                            <input
                              type="checkbox"
                              id="option_instructor_workload_hourly"
                              className="form-radio"
                              style={{ backgroundColor: 'blue' }}
                              checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                item == SectionTypeSolutionOptionExamTerm.InstructorWorkloadHourly) == SectionTypeSolutionOptionExamTerm.InstructorWorkloadHourly}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e && e.currentTarget) {
                                  let selectedList = this.state.selectedOptions;
                                  if (e.currentTarget.checked) {
                                    selectedList.push(SectionTypeSolutionOptionExamTerm.InstructorWorkloadHourly);
                                  } else {
                                    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.InstructorWorkloadHourly);
                                  }
                                  this.state.selectedOptions = selectedList;
                                  this.setState(this.state);
                                }
                              }}
                            />
                            &nbsp;{T.t('gen_exam_report_instructor_workload_hourly')}
                          </label>
                        </div>
                        <div className="tick-radio position-relative d-block">
                          <label style={{ cursor: 'pointer' }}>
                            <input
                              id="option_cross_table_acc_programs"
                              type="checkbox"
                              className="form-radio"
                              style={{ backgroundColor: 'blue' }}
                              checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                item == SectionTypeSolutionOptionExamTerm.CrossTable_AccPrograms) == SectionTypeSolutionOptionExamTerm.CrossTable_AccPrograms}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e && e.currentTarget) {
                                  let selectedList = this.state.selectedOptions;
                                  if (e.currentTarget.checked) {
                                    selectedList.push(SectionTypeSolutionOptionExamTerm.CrossTable_AccPrograms);
                                  } else {
                                    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.CrossTable_AccPrograms);
                                  }
                                  this.state.selectedOptions = selectedList;
                                  this.setState(this.state);
                                }
                              }}
                            />
                            &nbsp;{T.t('gen_exam_report_cross_table_acc_programs')}
                          </label>
                        </div>
                        <div className="tick-radio position-relative d-block">
                          <label style={{ cursor: 'pointer' }}>
                            <input
                              id="option_cross_table_acc_programs_without_invigilators"
                              type="checkbox"
                              className="form-radio"
                              style={{ backgroundColor: 'blue' }}
                              checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                item == SectionTypeSolutionOptionExamTerm.CrossTable_AccProgramsWithoutInvigilators) == SectionTypeSolutionOptionExamTerm.CrossTable_AccProgramsWithoutInvigilators}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e && e.currentTarget) {
                                  let selectedList = this.state.selectedOptions;
                                  if (e.currentTarget.checked) {
                                    selectedList.push(SectionTypeSolutionOptionExamTerm.CrossTable_AccProgramsWithoutInvigilators);
                                  } else {
                                    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.CrossTable_AccProgramsWithoutInvigilators);
                                  }
                                  this.state.selectedOptions = selectedList;
                                  this.setState(this.state);
                                }
                              }}
                            />
                            &nbsp;{T.t('gen_exam_report_cross_table_acc_programs_without_invigilators')}
                          </label>
                        </div>
                        <div className="tick-radio position-relative d-block">
                          <label style={{ cursor: 'pointer' }}>
                            <input
                              id="option_cross_table_acc_instructors"
                              type="checkbox"
                              className="form-radio"
                              style={{ backgroundColor: 'blue' }}
                              checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                item == SectionTypeSolutionOptionExamTerm.CrossTable_AccInstructors) == SectionTypeSolutionOptionExamTerm.CrossTable_AccInstructors}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e && e.currentTarget) {
                                  let selectedList = this.state.selectedOptions;
                                  if (e.currentTarget.checked) {
                                    selectedList.push(SectionTypeSolutionOptionExamTerm.CrossTable_AccInstructors);
                                  } else {
                                    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.CrossTable_AccInstructors);
                                  }
                                  this.state.selectedOptions = selectedList;
                                  this.setState(this.state);
                                }
                              }}
                            />
                            &nbsp;{T.t('gen_exam_report_cross_table_acc_instructors')}
                          </label>
                        </div>
                        {
                          this.props.user && this.props.user.role === 's' ? (
                            <div className="tick-radio position-relative d-block">
                              <label style={{ cursor: 'pointer' }}>
                                <input
                                  type="checkbox"
                                  id="option_invigilators"
                                  className="form-radio"
                                  style={{ backgroundColor: 'blue' }}
                                  checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                    item == SectionTypeSolutionOptionExamTerm.Invigilators) == SectionTypeSolutionOptionExamTerm.Invigilators}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e && e.currentTarget) {
                                      let selectedList = this.state.selectedOptions;
                                      if (e.currentTarget.checked) {
                                        selectedList.push(SectionTypeSolutionOptionExamTerm.Invigilators);
                                      } else {
                                        selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.Invigilators);
                                      }
                                      this.state.selectedOptions = selectedList;
                                      this.setState(this.state);
                                    }
                                  }}
                                />
                                &nbsp;{T.t('gen_exam_report_invigilators')}
                              </label>
                            </div>) : null
                        }
                        {
                          this.props.user && this.props.user.role === 's' ? (
                            <div className="tick-radio position-relative d-block">
                              <label style={{ cursor: 'pointer' }}>
                                <input
                                  type="checkbox"
                                  id="option_instructor_overlapping_exams"
                                  className="form-radio"
                                  style={{ backgroundColor: 'blue' }}
                                  checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                    item == SectionTypeSolutionOptionExamTerm.InstructorOverlappingExams) == SectionTypeSolutionOptionExamTerm.InstructorOverlappingExams}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e && e.currentTarget) {
                                      let selectedList = this.state.selectedOptions;
                                      if (e.currentTarget.checked) {
                                        selectedList.push(SectionTypeSolutionOptionExamTerm.InstructorOverlappingExams);
                                      } else {
                                        selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.InstructorOverlappingExams);
                                      }
                                      this.state.selectedOptions = selectedList;
                                      this.setState(this.state);
                                    }
                                  }}
                                />
                                &nbsp;{T.t('gen_exam_report_instructor_overlapping_exams')}
                              </label>
                            </div>) : null
                        }
                        {
                          this.props.user && this.props.user.role === 's' ? (
                            <div className="tick-radio position-relative d-block">
                              <label style={{ cursor: 'pointer' }}>
                                <input
                                  type="checkbox"
                                  id="option_instructor_consecutive_exams"
                                  className="form-radio"
                                  style={{ backgroundColor: 'blue' }}
                                  checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                    item == SectionTypeSolutionOptionExamTerm.InstructorConsecutiveExams) == SectionTypeSolutionOptionExamTerm.InstructorConsecutiveExams}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e && e.currentTarget) {
                                      let selectedList = this.state.selectedOptions;
                                      if (e.currentTarget.checked) {
                                        selectedList.push(SectionTypeSolutionOptionExamTerm.InstructorConsecutiveExams);
                                      } else {
                                        selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.InstructorConsecutiveExams);
                                      }
                                      this.state.selectedOptions = selectedList;
                                      this.setState(this.state);
                                    }
                                  }}
                                />
                                &nbsp;{T.t('gen_exam_report_instructor_consecutive_exams')}
                              </label>
                            </div>) : null
                        }
                        {
                          this.props.user && this.props.user.role === 's' ? (
                            <div className="tick-radio position-relative d-block">
                              <label style={{ cursor: 'pointer' }}>
                                <input
                                  type="checkbox"
                                  id="option_student_density"
                                  className="form-radio"
                                  style={{ backgroundColor: (this.state.isPublished ? 'blue' : 'gray') }}
                                  disabled={!this.state.isPublished}
                                  checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                    item == SectionTypeSolutionOptionExamTerm.StudentDensity) == SectionTypeSolutionOptionExamTerm.StudentDensity}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e && e.currentTarget) {
                                      let selectedList = this.state.selectedOptions;
                                      if (e.currentTarget.checked) {
                                        selectedList.push(SectionTypeSolutionOptionExamTerm.StudentDensity);
                                      } else {
                                        selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.StudentDensity);
                                      }
                                      this.state.selectedOptions = selectedList;
                                      this.setState(this.state);
                                    }
                                  }}
                                />
                                &nbsp;{T.t('gen_exam_report_student_density')}
                              </label>
                            </div>) : null
                        }
                        {
                          this.props.user && this.props.user.role === 's' ? (
                            <div className="tick-radio position-relative d-block">
                              <label style={{ cursor: 'pointer' }}>
                                <input
                                  id='option_student_exam_counts'
                                  type="checkbox"
                                  className="form-radio"
                                  style={{ backgroundColor: (this.state.isPublished ? 'blue' : 'gray') }}
                                  disabled={!this.state.isPublished}
                                  checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                    item == SectionTypeSolutionOptionExamTerm.StudentExamCounts) == SectionTypeSolutionOptionExamTerm.StudentExamCounts}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e && e.currentTarget) {
                                      let selectedList = this.state.selectedOptions;
                                      if (e.currentTarget.checked) {
                                        selectedList.push(SectionTypeSolutionOptionExamTerm.StudentExamCounts);
                                      } else {
                                        selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.StudentExamCounts);
                                      }
                                      this.state.selectedOptions = selectedList;
                                      this.setState(this.state);
                                    }
                                  }}
                                />
                                &nbsp;{T.t('gen_exam_report_student_exam_counts')}
                              </label>
                            </div>) : null
                        }
                        {
                          this.props.user && this.props.user.role === 's' ? (
                            <div className="tick-radio position-relative d-block">
                              <label style={{ cursor: 'pointer' }}>
                                <input
                                  type="checkbox"
                                  id="option_student_exams"
                                  className="form-radio"
                                  style={{ backgroundColor: (this.state.isPublished ? 'blue' : 'gray') }}
                                  disabled={!this.state.isPublished}
                                  checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                    item == SectionTypeSolutionOptionExamTerm.StudentExams) == SectionTypeSolutionOptionExamTerm.StudentExams}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e && e.currentTarget) {
                                      let selectedList = this.state.selectedOptions;
                                      if (e.currentTarget.checked) {
                                        selectedList.push(SectionTypeSolutionOptionExamTerm.StudentExams);
                                      } else {
                                        selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.StudentExams);
                                      }
                                      this.state.selectedOptions = selectedList;
                                      this.setState(this.state);
                                    }
                                  }}
                                />
                                &nbsp;{T.t('gen_exam_report_student_exams')}
                              </label>
                            </div>) : null
                        }
                      </div>

                      <div className="col-md-6">
                        <div className="tick-radio position-relative d-block">
                          <label style={{ cursor: 'pointer' }}>
                            <input
                              id="option_cross_table_acc_classrooms"
                              type="checkbox"
                              className="form-radio"
                              style={{ backgroundColor: 'blue' }}
                              checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                item == SectionTypeSolutionOptionExamTerm.CrossTable_AccClassrooms) == SectionTypeSolutionOptionExamTerm.CrossTable_AccClassrooms}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e && e.currentTarget) {
                                  let selectedList = this.state.selectedOptions;
                                  if (e.currentTarget.checked) {
                                    selectedList.push(SectionTypeSolutionOptionExamTerm.CrossTable_AccClassrooms);
                                  } else {
                                    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.CrossTable_AccClassrooms);
                                  }
                                  this.state.selectedOptions = selectedList;
                                  this.setState(this.state);
                                }
                              }}
                            />
                            &nbsp;{T.t('gen_exam_report_cross_table_acc_classrooms')}
                          </label>
                        </div>
                        <div className="tick-radio position-relative d-block">
                          <label style={{ cursor: 'pointer' }}>
                            <input
                              id="option_cross_table_acc_classrooms_vertical"
                              type="checkbox"
                              className="form-radio"
                              style={{ backgroundColor: 'blue' }}
                              checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                item == SectionTypeSolutionOptionExamTerm.CrossTable_AccClassroomsVertical) == SectionTypeSolutionOptionExamTerm.CrossTable_AccClassroomsVertical}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e && e.currentTarget) {
                                  let selectedList = this.state.selectedOptions;
                                  if (e.currentTarget.checked) {
                                    selectedList.push(SectionTypeSolutionOptionExamTerm.CrossTable_AccClassroomsVertical);
                                  } else {
                                    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.CrossTable_AccClassroomsVertical);
                                  }
                                  this.state.selectedOptions = selectedList;
                                  this.setState(this.state);
                                }
                              }}
                            />
                            &nbsp;{T.t('gen_exam_report_cross_table_acc_programs_vertical')}
                          </label>
                        </div>
                        <div className="tick-radio position-relative d-block">
                          <label style={{ cursor: 'pointer' }}>
                            <input
                              id="option_cross_table_acc_invigilators"
                              type="checkbox"
                              className="form-radio"
                              style={{ backgroundColor: 'blue' }}
                              checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                item == SectionTypeSolutionOptionExamTerm.CrossTable_AccInvigilators) == SectionTypeSolutionOptionExamTerm.CrossTable_AccInvigilators}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e && e.currentTarget) {
                                  let selectedList = this.state.selectedOptions;
                                  if (e.currentTarget.checked) {
                                    selectedList.push(SectionTypeSolutionOptionExamTerm.CrossTable_AccInvigilators);
                                  } else {
                                    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.CrossTable_AccInvigilators);
                                  }
                                  this.state.selectedOptions = selectedList;
                                  this.setState(this.state);
                                }
                              }}
                            />
                            &nbsp;{T.t('gen_exam_report_cross_table_acc_invigilators')}
                          </label>
                        </div>
                        <div className="tick-radio position-relative d-block">
                          <label style={{ cursor: 'pointer' }}>
                            <input
                              id="option_instructor_exams"
                              type="checkbox"
                              className="form-radio"
                              style={{ backgroundColor: 'blue' }}
                              checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                item == SectionTypeSolutionOptionExamTerm.InstructorExams) == SectionTypeSolutionOptionExamTerm.InstructorExams}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e && e.currentTarget) {
                                  let selectedList = this.state.selectedOptions;
                                  if (e.currentTarget.checked) {
                                    selectedList.push(SectionTypeSolutionOptionExamTerm.InstructorExams);
                                  } else {
                                    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.InstructorExams);
                                  }
                                  this.state.selectedOptions = selectedList;
                                  this.setState(this.state);
                                }
                              }}
                            />
                            &nbsp;{T.t('gen_exam_report_instructor_exams')}
                          </label>
                        </div>
                        <div className="tick-radio position-relative d-block">
                          <label style={{ cursor: 'pointer' }}>
                            <input
                              id="option_instructor_counts"
                              type="checkbox"
                              className="form-radio"
                              style={{ backgroundColor: 'blue' }}
                              checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                item == SectionTypeSolutionOptionExamTerm.InstructorCounts) == SectionTypeSolutionOptionExamTerm.InstructorCounts}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e && e.currentTarget) {
                                  let selectedList = this.state.selectedOptions;
                                  if (e.currentTarget.checked) {
                                    selectedList.push(SectionTypeSolutionOptionExamTerm.InstructorCounts);
                                  } else {
                                    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.InstructorCounts);
                                  }
                                  this.state.selectedOptions = selectedList;
                                  this.setState(this.state);
                                }
                              }}
                            />
                            &nbsp;{T.t('gen_exam_report_instructor_counts')}
                          </label>
                        </div>
                        <div className="tick-radio position-relative d-block">
                          <label style={{ cursor: 'pointer' }}>
                            <input
                              id="option_student_counts"
                              type="checkbox"
                              className="form-radio"
                              style={{ backgroundColor: 'blue' }}
                              checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                item == SectionTypeSolutionOptionExamTerm.StudentCounts) == SectionTypeSolutionOptionExamTerm.StudentCounts}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e && e.currentTarget) {
                                  let selectedList = this.state.selectedOptions;
                                  if (e.currentTarget.checked) {
                                    selectedList.push(SectionTypeSolutionOptionExamTerm.StudentCounts);
                                  } else {
                                    selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.StudentCounts);
                                  }
                                  this.state.selectedOptions = selectedList;
                                  this.setState(this.state);
                                }
                              }}
                            />
                            &nbsp;{T.t('gen_exam_report_student_counts')}
                          </label>
                        </div>
                        {
                          this.props.user && this.props.user.role === 's' ? (
                            <div className="tick-radio position-relative d-block">
                              <label style={{ cursor: 'pointer' }}>
                                <input
                                  type="checkbox"
                                  id="option_ois"
                                  className="form-radio"
                                  style={{ backgroundColor: 'blue' }}
                                  checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                    item == SectionTypeSolutionOptionExamTerm.Ois) == SectionTypeSolutionOptionExamTerm.Ois}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e && e.currentTarget) {
                                      let selectedList = this.state.selectedOptions;
                                      if (e.currentTarget.checked) {
                                        selectedList.push(SectionTypeSolutionOptionExamTerm.Ois);
                                      } else {
                                        selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.Ois);
                                      }
                                      this.state.selectedOptions = selectedList;
                                      this.setState(this.state);
                                    }
                                  }}
                                />
                                &nbsp;{T.t('gen_exam_report_ois')}
                              </label>
                            </div>) : null
                        }
                        {
                          this.props.user && this.props.user.role === 's' ? (
                            <div className="tick-radio position-relative d-block">
                              <label style={{ cursor: 'pointer' }}>
                                <input
                                  type="checkbox"
                                  id="option_ois_students"
                                  className="form-radio"
                                  style={{ backgroundColor: 'blue' }}
                                  checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                    item == SectionTypeSolutionOptionExamTerm.OisStudents) == SectionTypeSolutionOptionExamTerm.OisStudents}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e && e.currentTarget) {
                                      let selectedList = this.state.selectedOptions;
                                      if (e.currentTarget.checked) {
                                        selectedList.push(SectionTypeSolutionOptionExamTerm.OisStudents);
                                      } else {
                                        selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.OisStudents);
                                      }
                                      this.state.selectedOptions = selectedList;
                                      this.setState(this.state);
                                    }
                                  }}
                                />
                                &nbsp;{T.t('gen_exam_report_ois_students')}
                              </label>
                            </div>) : null
                        }
                        {
                          this.props.user && this.props.user.role === 's' ? (
                            <div className="tick-radio d-block">
                              <label style={{ cursor: 'pointer' }}>
                                <input
                                  id='option_obs'
                                  type="checkbox"
                                  className="form-radio"
                                  style={{ backgroundColor: 'blue' }}
                                  checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                    item == SectionTypeSolutionOptionExamTerm.Obs) == SectionTypeSolutionOptionExamTerm.Obs}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e && e.currentTarget) {
                                      let selectedList = this.state.selectedOptions;
                                      if (e.currentTarget.checked) {
                                        selectedList.push(SectionTypeSolutionOptionExamTerm.Obs);
                                      } else {
                                        selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.Obs);
                                      }
                                      this.state.selectedOptions = selectedList;
                                      this.setState(this.state);
                                    }
                                  }}
                                />
                                &nbsp;{T.t('gen_exam_report_obs')}
                              </label>
                            </div>) : null
                        }
                        {
                          this.props.user && this.props.user.role === 's' ? (
                            <div className="tick-radio position-relative d-block">
                              <label style={{ cursor: 'pointer' }}>
                                <input
                                  type="checkbox"
                                  id="option_unipa_students"
                                  className="form-radio"
                                  style={{ backgroundColor: 'blue' }}
                                  checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                    item == SectionTypeSolutionOptionExamTerm.UnipaStudents) == SectionTypeSolutionOptionExamTerm.UnipaStudents}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e && e.currentTarget) {
                                      let selectedList = this.state.selectedOptions;
                                      if (e.currentTarget.checked) {
                                        selectedList.push(SectionTypeSolutionOptionExamTerm.UnipaStudents);
                                      } else {
                                        selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.UnipaStudents);
                                      }
                                      this.state.selectedOptions = selectedList;
                                      this.setState(this.state);
                                    }
                                  }}
                                />
                                &nbsp;{T.t('gen_exam_report_unipa_students')}
                              </label>
                            </div>) : null
                        }
                        {
                          this.props.user && this.props.user.role === 's' ? (
                            <div className="tick-radio position-relative d-block">
                              <label style={{ cursor: 'pointer' }}>
                                <input
                                  type="checkbox"
                                  id="option_student_overlapping_exams"
                                  className="form-radio"
                                  style={{ backgroundColor: (this.state.isPublished ? 'blue' : 'gray') }}
                                  disabled={!this.state.isPublished}
                                  checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                    item == SectionTypeSolutionOptionExamTerm.StudentOverlappingExams) == SectionTypeSolutionOptionExamTerm.StudentOverlappingExams}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e && e.currentTarget) {
                                      let selectedList = this.state.selectedOptions;
                                      if (e.currentTarget.checked) {
                                        selectedList.push(SectionTypeSolutionOptionExamTerm.StudentOverlappingExams);
                                      } else {
                                        selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.StudentOverlappingExams);
                                      }
                                      this.state.selectedOptions = selectedList;
                                      this.setState(this.state);
                                    }
                                  }}
                                />
                                &nbsp;{T.t('gen_exam_report_student_overlapping_exams')}
                              </label>
                            </div>) : null
                        }
                        {
                          this.props.user && this.props.user.role === 's' ? (
                            <div className="tick-radio position-relative d-block">
                              <label style={{ cursor: 'pointer' }}>
                                <input
                                  type="checkbox"
                                  id="option_student_consecutive_exams"
                                  className="form-radio"
                                  style={{ backgroundColor: (this.state.isPublished ? 'blue' : 'gray') }}
                                  disabled={!this.state.isPublished}
                                  checked={this.state.selectedOptions && this.state.selectedOptions.find((item: number) =>
                                    item == SectionTypeSolutionOptionExamTerm.StudentConsecutiveExams) == SectionTypeSolutionOptionExamTerm.StudentConsecutiveExams}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e && e.currentTarget) {
                                      let selectedList = this.state.selectedOptions;
                                      if (e.currentTarget.checked) {
                                        selectedList.push(SectionTypeSolutionOptionExamTerm.StudentConsecutiveExams);
                                      } else {
                                        selectedList = removeArrayItem(selectedList, SectionTypeSolutionOptionExamTerm.StudentConsecutiveExams);
                                      }
                                      this.state.selectedOptions = selectedList;
                                      this.setState(this.state);
                                    }
                                  }}
                                />
                                &nbsp;{T.t('gen_exam_report_student_consecutive_exams')}
                              </label>
                            </div>) : null
                        }
                      </div>
                    </React.Fragment>
                  )}

                <hr />
                <div className="col-12">
                  <button
                    id='button_download'
                    type="button"
                    className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2 float-right ml-3 mr-3"
                    style={{ margin: '5px' }}
                    onClick={(e) => this.onDownloadByOptions(e)}
                  >
                    <i className="material-icons mr-2" style={{ color: 'white' }}>cloud_download</i>
                    <span>{T.t('gen_download')}</span>
                  </button>
                </div>
              </div>
              <hr />
              <div className="row"></div>
              <hr />
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICoursePageProps): Types.ICoursePageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ICoursePageProps = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    term_type: store.state.term_type,
    user: store.state.user,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ReportsDownloadModal);

export default container;
