import XLSX from 'xlsx'

export function formatExcel<T>(e: any, headerMapping: Record<string, string[]>) {

    const normalizeString = (str: string): string => {
        const pattern = /[.,:;\t\r\n ]/g;
        const turkishToEnglishMap: Record<string, string> = {
            'ç': 'c',
            'ğ': 'g',
            'ı': 'i',
            'İ': 'i',
            'ö': 'o',
            'ş': 's',
            'ü': 'u',
            'Ç': 'c',
            'Ğ': 'g',
            'Ö': 'o',
            'Ş': 's',
            'Ü': 'u'
        };
        const replaceTurkishChars = (input: string): string => {
            return input
                .split('')
                .map(char => turkishToEnglishMap[char] || char)
                .join('');
        };
        return replaceTurkishChars(str.replace(pattern, '')).toLowerCase();
    }

    // Normalize header mapping
    let normalizedHeaderMapping: Record<string, string[]> = {};
    for (let key in headerMapping) {
        const normalizedKeys = headerMapping[key].map(normalizeString);
        normalizedKeys.forEach((normalizedKey) => {
            if (!normalizedHeaderMapping[normalizedKey]) {
                normalizedHeaderMapping[normalizedKey] = [];
            }
            normalizedHeaderMapping[normalizedKey].push(key);
        });
    }

    let bstr = e.target.result;
    let wb = XLSX.read(bstr, { type: 'binary' });
    let wsname = wb.SheetNames[0];
    let ws = wb.Sheets[wsname];

    let jsonData = XLSX.utils.sheet_to_json(ws, { header: 1 });

    // remove empty rows
    jsonData = jsonData.filter((row: any) => {
        return row.some((cell: any) => cell !== undefined && cell !== null && cell.toString().trim() !== '');
    });

    let headersFromExcel = jsonData[0] as string[];

    // normalize headers
    let normalizedHeadersFromExcel = headersFromExcel.map(normalizeString)

    let headerIndices: { [key: string]: number } = {};
    for (let i = 0; i < normalizedHeadersFromExcel.length; i++) {
        let header = normalizedHeadersFromExcel[i];
        if (normalizedHeaderMapping[header]) {
            normalizedHeaderMapping[header].forEach((key) => {
                headerIndices[key] = i;
            });
        }
    }

    let data = jsonData.slice(1).map((row: any) => {
        let rowData: { [key: string]: any } = {};
        for (let key in headerIndices) {
            const cellValue = row[headerIndices[key]];
            rowData[key] = cellValue != null ? cellValue.toString() : ""
        }
        return rowData;
    }) as Array<T>;

    return data;
}
