import { ConfigProvider, Image } from "antd";
import React from "react";

interface ImagePopupProps {
    open: boolean,
    setClose: Function,
    imageArray: string[]
}

const ImagePopup: React.FC<ImagePopupProps> = ({ open, setClose, imageArray }) => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Image: {
                        zIndexPopup: 1500,
                        zIndexPopupBase: 1500,
                        zIndexBase: 1500
                    }
                }
            }}
        >
            <Image.PreviewGroup preview={{
                visible: open, onVisibleChange: () => { setClose(false) },
            }}>
                {
                    imageArray.map(
                        it => <Image
                            key={it}
                            preview={{
                                width: 200,
                                src: it
                            }}
                        />
                    )
                }
            </Image.PreviewGroup>
        </ConfigProvider>
    );
}

export default ImagePopup