import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

export const constants = {
  BUILDING_LIST_SEARCH: 'BUILDING:LIST_SEARCH',
  BUILDING_CREATE: 'BUILDING:CREATE',
  BUILDING_UPDATE: 'BUILDING:UPDATE',
  BUILDING_DELETE: 'BUILDING:DELETE',
  BUILDING_GET_BY_ID: 'BUILDING:GET_BY_ID',
  BUILDING_GET_SELECT_OPTIONS: 'BUILDING:GET_SELECT_OPTIONS',
  BUILDING_CREATE_EXCEL_BULK: 'BUILDING:CREATE_EXCEL_BULK',
  BUILDING_GET_DISTANCES: 'BUILDING:GET_DISTANCES',
  BUILDING_UPDATE_DISTANCES: 'BUILDING:POST_DISTANCES',
  BUILDING_IMPORT_LIST_SEARCH: 'BUILDING:IMPORT_LIST_SEARCH',
  BUILDING_IMPORT_GET_SELECT_OPTIONS: 'BUILDING:IMPORT_GET_SELECT_OPTIONS',
  BUILDING_IMPORT_CREATE: 'BUILDING:IMPORT_CREATE',
  DOWNLOAD_ALL_DATA: 'DOWNLOAD_ALL_DATA',
};

export const BuildingSearchInitialValues: Types.IFilterBuilding = {
  building_code: '',
  order_by: '',
  name: '',
  status: [],
  campuses: [],
  select_all: false,
  campus_ids: [],
  building_functionality_ids: [],
  building_functionalities: [],
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const BuildingFormInitialValues: Types.IBuildingItem = {
  building_id: undefined,
  building_code: '',
  name: '',
  address: '',
  description: '',
  status: 1,
  floor_count: 0,
  floors: [],
};

export const BuildingFunctionalityOptions = (T: Translator, opts?: any) => [
  { label: T.t('gen_academic', opts), value: 1 },
  { label: T.t('gen_administrative', opts), value: 2 },
  { label: T.t('gen_dormitory', opts), value: 3 },
  { label: T.t('gen_housing', opts), value: 4 },
  { label: T.t('gen_research_and_development', opts), value: 5 },
  { label: T.t('gen_laboratory', opts), value: 6 },
  { label: T.t('gen_library', opts), value: 7 },
  { label: T.t('gen_gymnasium', opts), value: 8 },
  { label: T.t('gen_security', opts), value: 9 },
  { label: T.t('gen_classroom', opts), value: 10 },
  { label: T.t('gen_dining_hall', opts), value: 11 },
  { label: T.t('gen_cafeteria', opts), value: 12 },
  { label: T.t('gen_auditorium', opts), value: 13 },
  { label: T.t('gen_other', opts), value: 14 },
];

export const ImportingKeySelectOptions = (T: Translator, opts?: any) => [
  { label: T.t('gen_building_code', opts), value: 'BUILDING_CODE' },
  { label: T.t('gen_do_not_check', opts), value: 'NONE' }
];