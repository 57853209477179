import * as Types from '../types';

import Translator from '../../services/translate-factory';

export const actions = {
  SETTING_RESET_PASSWORD: 'SETTING:RESET_PASSWORD',
  GET_SETTINGS: 'SETTING:GET_SETTINGS',
  GET_GENERAL_SETTINGS: 'SETTING:GET_GENERAL_SETTINGS',
  UPDATE_SETTINGS: 'SETTING:SET_SETTINGS'
};

export const EmailServiceSelectOptions = (T: Translator, opts?: any) => [
  { label: 'Gmail', value: 'Gmail' },
  { label: 'Mandrill', value: 'Mandrill' },
  { label: 'Outlook', value: 'Outlook' },
  { label: 'Yandex', value: 'Yandex' },
  { label: T.t('gen_other', opts), value: 'other' }
];

export const ClassroomInvigilatorCountSettings = (T: Translator, opts?: any) => [
  { label: T.t('settings_classroom_invigilator_count_settings_opt1', opts), value: 0 },
  { label: T.t('settings_classroom_invigilator_count_settings_opt2', opts), value: 1 },
  { label: T.t('settings_classroom_invigilator_count_settings_opt3', opts), value: 2 }
];

export const InvigilatorAssignTypes = (T: Translator, opts?: any) => [
  { label: T.t('settings_invigilator_assign_type_opt1', opts), value: 0 },
  { label: T.t('settings_invigilator_assign_type_opt2', opts), value: 1 }
];

export const TermExamTypes = (T: Translator, opts?: any) => [
  { label: T.t('gen_quiz', opts), value: 1 },
  { label: T.t('gen_midterm_exam', opts), value: 2 },
  { label: T.t('gen_midterm_makeup_exam', opts), value: 3 },
  { label: T.t('gen_final_exam', opts), value: 4 },
  { label: T.t('gen_final_makeup_exam', opts), value: 5 },
  { label: T.t('gen_practice_or_presentation', opts), value: 6 },
  { label: T.t('gen_makeup_exam', opts), value: 7 },
  { label: T.t('gen_single_course_exam', opts), value: 8 }
];

export const TermExamTypesLabel = (termExamTypeValue: number, T: Translator): string => {
  const termExamTypeObject = TermExamTypes(T).find(item => item.value === termExamTypeValue);

  return termExamTypeObject ? termExamTypeObject.label : '-';
}

export const InstructorTitles = (T: Translator, opts?: any) => [
  { label: T.t('gen_academic_exp', opts), value: 1 },
  { label: T.t('gen_coach', opts), value: 2 },
  { label: T.t('gen_ra', opts), value: 3 },
  { label: T.t('gen_ra_phd', opts), value: 4 },
  { label: T.t('gen_researcher', opts), value: 5 },
  { label: T.t('gen_marine_instructor', opts), value: 6 },
  { label: T.t('gen_language_instructor', opts), value: 7 },
  { label: T.t('gen_director', opts), value: 8 },
  { label: T.t('gen_assoc', opts), value: 9 },
  { label: T.t('gen_assoc_dr', opts), value: 10 },
  { label: T.t('gen_dr', opts), value: 11 },
  { label: T.t('gen_dr_instr', opts), value: 12 },
  { label: T.t('gen_education_consultant', opts), value: 13 },
  { label: T.t('gen_med_phys_spc', opts), value: 14 },
  { label: T.t('gen_lectr', opts), value: 15 },
  { label: T.t('gen_instr', opts), value: 16 },
  { label: T.t('gen_instr_dr', opts), value: 17 },
  { label: T.t('gen_instr_lecturer', opts), value: 18 },
  { label: T.t('gen_staff', opts), value: 19 },
  { label: T.t('gen_prof', opts), value: 20 },
  { label: T.t('gen_prof_dr', opts), value: 21 },
  { label: T.t('gen_project_adv', opts), value: 22 },
  { label: T.t('gen_responsible', opts), value: 23 },
  { label: T.t('gen_technical_exp', opts), value: 24 },
  { label: T.t('gen_thesis_adv', opts), value: 25 },
  { label: T.t('gen_exp', opts), value: 26 },
  { label: T.t('gen_exp_dr', opts), value: 27 },
  { label: T.t('gen_exp_psyc', opts), value: 28 }
];

export const InstructorTitlesLabel = (titleValue: number, T: Translator): string => {
  const titleObject = InstructorTitles(T).find(title => title.value === titleValue);

  return titleObject ? titleObject.label : "-";
}

export const ActivityTypes = (T: Translator, opts?: any) => [
  { label: T.t('gen_theoric', opts), value: 1 },
  { label: T.t('gen_application', opts), value: 2 },
  { label: T.t('gen_laboratory', opts), value: 3 },
  { label: T.t('gen_problem_solving', opts), value: 4 },
  { label: T.t('gen_internship', opts), value: 5 },
  { label: T.t('gen_practice', opts), value: 6 },
  { label: T.t('gen_break', opts), value: 7 },
  { label: T.t('gen_thesis', opts), value: 8 },
  { label: T.t('gen_seminar', opts), value: 9 },
  { label: T.t('gen_preparatory_class', opts), value: 10 },
  { label: T.t('gen_scientific_preparation', opts), value: 11 },
  { label: T.t('gen_thesis_research', opts), value: 12 },
  { label: T.t('gen_phd_qualification', opts), value: 13 },
  { label: T.t('gen_project', opts), value: 14 }
].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));;

export const ActivityTypesLabel = (activityTypeValue: number, T: Translator): string => {
  const activityTypeObject = ActivityTypes(T).find(activityType => activityType.value === activityTypeValue);

  return activityTypeObject ? activityTypeObject.label : '-';
}

export const ClassroomFeatures = (T: Translator, opts?: any) => [
  { label: T.t('gen_projector', opts), value: 1 },
  { label: T.t('gen_smartboard', opts), value: 2 },
  { label: T.t('gen_dvd', opts), value: 3 },
  { label: T.t('gen_sound_system', opts), value: 4 },
  { label: T.t('gen_computer', opts), value: 5 },
  { label: T.t('gen_internet_access', opts), value: 6 },
  { label: T.t('gen_fixed_curtain', opts), value: 7 },
  { label: T.t('gen_video', opts), value: 8 },
  { label: T.t('gen_air_conditioner', opts), value: 9 }
].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));;


export const ClassroomTypesLabel = (classroomTypeValue: Types.ISelectOption, T: Translator): string => {
  const classroomTypeObject = ClassroomTypes(T).find(classroomType => classroomType.value === classroomTypeValue.value);

  return classroomTypeObject ? classroomTypeObject.label : classroomTypeValue.label;
}

export const LocalizedClassroomTypes = (classroomTypeValue: Types.ISelectOption | number | string | undefined, T: Translator): Types.ISelectOption | undefined => {
  if (!classroomTypeValue) return //undefined

  if (typeof classroomTypeValue === 'number' || typeof classroomTypeValue === 'string') {
    // only search for classroomTypeValue in ClassroomTypes
    return ClassroomTypes(T).find(classroomType => classroomType.value === classroomTypeValue) || { label: '-', value: classroomTypeValue }
  }
  // classroomTypeValue is ISelectOption
  return {
    label: ClassroomTypesLabel(classroomTypeValue, T),
    value: classroomTypeValue.value
  }
}

export const ClassroomTypes = (T: Translator, opts?: any) => [
  { label: T.t('gen_safety_room', opts), value: -56 },
  { label: T.t('gen_projection_room', opts), value: -55 },
  { label: T.t('gen_post_office', opts), value: -54 },
  { label: T.t('gen_playing_room', opts), value: -53 },
  { label: T.t('gen_coffee_bar', opts), value: -52 },
  { label: T.t('gen_therapy_room', opts), value: -51 },
  { label: T.t('gen_store', opts), value: -50 },
  { label: T.t('gen_bookshop', opts), value: -49 },
  { label: T.t('gen_library', opts), value: -48 },
  { label: T.t('gen_first_aid', opts), value: -47 },
  { label: T.t('gen_logistic_lab', opts), value: -46 },
  { label: T.t('gen_photo_lab', opts), value: -45 },
  { label: T.t('gen_medical_room', opts), value: -44 },
  { label: T.t('gen_music_studio', opts), value: -43 },
  { label: T.t('gen_recording_room', opts), value: -42 },
  { label: T.t('gen_relax_room', opts), value: -41 },
  { label: T.t('gen_sound_studio', opts), value: -40 },
  { label: T.t('gen_theater_hall', opts), value: -39 },
  { label: T.t('gen_image_processing_lab', opts), value: -38 },
  { label: T.t('gen_gym', opts), value: -37 },
  { label: T.t('gen_exam_hall', opts), value: -36 },
  { label: T.t('gen_cinema_hall', opts), value: -35 },
  { label: T.t('gen_computer_room', opts), value: -34 },
  { label: T.t('gen_club_room', opts), value: -33 },
  { label: T.t('gen_3D_lab', opts), value: -32 },
  { label: T.t('gen_workshop', opts), value: -31 },
  { label: T.t('gen_washing_room', opts), value: -30 },
  { label: T.t('gen_robotics_lab', opts), value: -29 },
  { label: T.t('gen_wc', opts), value: -28 },
  { label: T.t('gen_technical_room', opts), value: -27 },
  { label: T.t('gen_study_room', opts), value: -26 },
  { label: T.t('gen_studio_appartment', opts), value: -25 },
  { label: T.t('gen_storage', opts), value: -24 },
  { label: T.t('gen_stepped_room', opts), value: -23 },
  { label: T.t('gen_staircase', opts), value: -22 },
  { label: T.t('gen_sports_facility', opts), value: -21 },
  { label: T.t('gen_seminar_room', opts), value: -20 },
  { label: T.t('gen_printer_room', opts), value: -19 },
  { label: T.t('gen_other', opts), value: -18 },
  { label: T.t('gen_open_space', opts), value: -17 },
  { label: T.t('gen_office', opts), value: -16 },
  { label: T.t('gen_meeting_room', opts), value: -15 },
  { label: T.t('gen_lobby', opts), value: -14 },
  { label: T.t('gen_living_room', opts), value: -13 },
  { label: T.t('gen_kitchen', opts), value: -12 },
  { label: T.t('gen_flat', opts), value: -11 },
  { label: T.t('gen_elevator', opts), value: -10 },
  { label: T.t('gen_dining', opts), value: -9 },
  { label: T.t('gen_corridor', opts), value: -8 },
  { label: T.t('gen_common_room', opts), value: -7 },
  { label: T.t('gen_changing_room', opts), value: -6 },
  { label: T.t('gen_bedroom', opts), value: -5 },
  { label: T.t('gen_bathroom', opts), value: -4 },
  { label: T.t('gen_basement', opts), value: -3 },
  { label: T.t('gen_balcony', opts), value: -2 },
  { label: T.t('gen_attic', opts), value: -1 },
  { label: T.t('gen_only_classroom', opts), value: 1 },
  { label: T.t('gen_laboratory', opts), value: 2 },
]

export const ClassroomFeaturesLabel = (classroomFeatureValue: number, T: Translator): string => {
  const classroomFeatureObject = ClassroomFeatures(T).find(classroomFeature => classroomFeature.value === classroomFeatureValue);

  return classroomFeatureObject ? classroomFeatureObject.label : '-';
}

export const ResetPasswordFormInitialValues: Types.IResetPassword = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirm: ''
};

export const SettingsPageRoutes = {
  ChangePassword: '/password'
};

export enum SettingsEventModuleStatus {
  Blocked = -1,
  Passive = 0,
  Active = 1,
}
