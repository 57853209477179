//#region Campus
export const campusExcelMapping = {
    campusCode: [
        "Kampüs Kodu",
        "Campus Code",
        "Kodu",
        "Kod",
        "Code",
    ],
    campusName: [
        "Kampüs Adı",
        "Campus Name",
        "Adı",
        "Name",
        "Kampus Adı",
        "Campus Title"
    ],
    address: [
        "Adres",
        "Address",
        "Location",
        "Address Line",
        "Adres Bilgisi"
    ],
    description: [
        "Açıklama",
        "Description",
        "Info",
        "Details",
        "Bilgi",
        "Detay"
    ],
    status: [
        "Aktiflik",
        "Durum",
        "Active Status",
        "State",
        "Status",
        "Aktif Durum"
    ]
};

//#region Building
export const buildingExcelMapping = {
    buildingCode: [
        "Building Code",
        "Bina Kodu",
        "Kod",
        "Code",
        "Kodu"
    ],
    buildingName: [
        "Building Name",
        "Bina Adı",
        "Adı",
        "Name",
        "Bina İsmi",
        "Building Title",
        "Başlık"
    ],
    campusCode: [
        "Campus Code",
        "Kampüs Kodu",
        "Kampus Kodu",
    ],
    description: [
        "Description",
        "Açıklama",
        "Details",
        "Bilgi",
        "Detay",
        "Info",
        "Building Description",
        "Bina Açıklaması"
    ],
    buildingFunctionality: [
        "BuildingFunctionality",
        "Bina Fonksiyonları",
        "BİNA FONKSİYONU",
        "Fonksiyon",
        "Functionality",
        "Building Use",
        "Kullanım Amacı",
        "Bina İşlevi"
    ]
};

//#region Faculty
export const facultyExcelMapping = {
    facultyCode: [
        "Fakülte/Yüksek Okul Kodu",
        "FAKÜLTE/ENSTİTÜ/YÜKSEKOKUL KODU",
        "Faculty/Institute/College Code",
        "Faculty/College Code",
        "Faculty Code",
        "College Code",
        "Fakülte Kodu",
        "Enstitü Kodu",
        "Yüksekokul Kodu",
        "Code",
        "Kodu",
    ],
    facultyName: [
        "Fakülte/Yüksek Okul Adı",
        "FAKÜLTE/ENSTİTÜ/YÜKSEKOKUL ADI",
        "Faculty/College Name",
        "Faculty Name",
        "College Name",
        "Fakülte Adı",
        "Enstitü Adı",
        "Yüksekokul Adı",
        "Adı",
        "Name",
        "Title"
    ],
    description: [
        "Açıklama",
        "Description",
        "Details",
        "Bilgi",
        "Detay",
        "Info",
        "Faculty Description",
        "Fakülte Açıklaması",
        "Açıklamalar"
    ]
};


//#region Instructor
export const courseInstructorExcelMapping = {
    instructor_code: [
        "Code",
        "Kod",
        "ÖĞRETİM ELEMANI KODU",
        "Instructor Code",
        "Eğitmen Kodu",
        "Kodu",
    ],
    faculty_code_of_duty: [
        "Faculty/College Code of Duty",
        "Görev Yeri Fakülte/Yüksek Okul Kodu",
        "Duty Faculty Code",
        "Görev Fakülte Kodu",
        "Faculty Code",
        "GÖREV YERİ FAKÜLTE/YÜKSEKOKUL KODU",
    ],
    program_code_of_duty: [
        "Department/Program Code of Duty",
        "Görev Yeri Bölüm/Program Kodu",
        "Duty Program Code",
        "Program Kodu",
        "GÖREV YERİ PROGRAM KODU",
        "Department Code",
    ],
    invigilator_level: [
        "Gözetmenlik Düzeyi",
        "Invigilator Level",
        "Gözetmenlik Seviyesi",
        "Level",
        "Gözetmenlik Düzeyi",
        "GÖZETMENLİK DERECESİ",
    ],
    invigilation_faculty_codes: [
        "GÖZETMENLİK YERİ FAKÜLTE KODU",
        "Gözetmenlik Fakülte Kodu",
        "Faculty Code of Invigilation Place",
        "Faculty Code for Supervision",
        "Supervision Faculty Code",
        "Invigilation Faculty Code",
        "Faculty Code",
        "Fakülte Kodu",
    ],
    invigilation_program_codes: [
        "GÖZETMENLİK YERİ PROGRAM KODU",
        "Program Kodu",
        "Gözetmenlik Program Kodu",
        "Invigilation Program Name",
        "Program Name",
        "Supervision Program Name",
        "Program Title",
        "Gözetmenlik Yeri Program Kodu",
        "Yeri Program Kodu"
    ],
    invigilation_campus_codes: [
        "GÖZETMENLİK YERİ KAMPÜS KODU",
        "Kampüs Kodu",
        "Gözetmenlik Kampüs Kodu",
        "Invigilation Campus Name",
        "Campus Name",
        "Supervision Campus Name",
        "Campus Title",
        "Gözetmenlik Yeri Kampüs Kodu",
        "Yeri Kampüs Kodu"
    ],
    title: [
        "Title",
        "Unvan",
        "ÜNVAN",
        "Pozisyon",
        "Görev Unvanı",
        "Position Title"
    ],
    name: [
        "Full Name",
        "Ad Soyad",
        "İsim Soyisim",
        "Adı Soyadı",
        "Name Surname",
        "Name"
    ],
    status: [
        "Activity",
        "Aktiflik",
        "Durum",
        "Status",
        "Active Status",
        "Etkinlik Durumu"
    ],
    staff_type: [
        "Position",
        "Kadro",
        "Görev Türü",
        "Staff Type",
        "Role",
        "Job Title"
    ],
    daily_max_class: [
        "Maximum Daily Classes",
        "Günlük Verebileceği Maksimum Ders",
        "Max Daily Classes",
        "Günlük Ders Limiti"
    ],
    weekly_max_day: [
        "Weekly Maximum Day Count",
        "Haftalık Maksimum Ders Günü Sayısı",
        "Max Weekly Days",
        "Haftalık Gün Limiti"
    ],
    email: [
        "Email Address",
        "Eposta Adresi",
        "E-posta",
        "Mail",
        "E-posta Adresi",
        "Email"
    ],
    mobile: [
        "Mobile Phone",
        "Cep Telefonu",
        "Telefon",
        "Mobile",
        "Telefon Numarası",
        "Contact Number"
    ],
    description: [
        "Description",
        "Açıklama",
        "Detay",
        "Bilgi",
        "Notes",
        "Details"
    ],
    administrative_positions: [
        "Administrative Positions",
        "Administrative Position",
        "İdari Görevleri",
        "İdari Görevler",
        "İdari Görevi",
        "İdari Görev"
    ],
    contractual_workload: [
        "Contractual Workload",
        "Workload",
        "Contractual Course Load",
        "Course Load",
        "Sözleşmeye Esas İş Yükü",
        "İş Yükü",
        "Sözleşmeye Esas Ders Yükü",
        "Ders Yükü"
    ],
    max_invigilation_duty: [
        'GÖZETMENLİK MAKSİMUM SAYI',
        'GÖZETMENLİK MAKSİMUM SAYISI',
        'GÖZETMENLİK MAX SAYISI',
        'GÖZETMENLİK MAX SAYI',
        'MAX INVIGILATION DUTY',
        'MAX INVIGILATION COUNT',
        'MAX INVIGILATION NUMBER',
    ],
    daily_max_duty: [
        'GÜNLÜK MAKSİMUM GÖREV SÜRESİ',
        'GÜNLÜK GÖREV SAYISI',
        'GÜNLÜK GÖREV LİMİTİ',
        'DAILY MAX DUTY',
        'DAILY DUTY LIMIT',
        'DAILY DUTY COUNT',
        'DAILY DUTY NUMBER',
    ]
}
export const planningInstructorHoursMapping = {
    instructorCode: [
        "ÖĞRETİM ELEMANI KODU",
        "Instructor Code",
        "Eğitmen Kodu",
        "Öğretmen Kodu",
        "Kod",
    ],
    date: [
        "TARİH",
        "Date",
        "Tarih Bilgisi",
        "Exam Date",
        "Schedule Date"
    ],
    day: [
        "GÜN",
        "Day",
        "Hafta Günü",
        "Gün Adı",
        "Weekday"
    ],
    startHour: [
        "BAŞLANGIÇ SAATİ",
        "Start Hour",
        "Başlangıç Zamanı",
        "Start Time",
        "Lesson Start Time"
    ],
    endHour: [
        "BİTİŞ SAATİ",
        "End Hour",
        "Bitiş Zamanı",
        "End Time",
        "Lesson End Time"
    ]
};


//#region Course
export const courseCourseActivityExcelMapping = {
    activityNo: [
        "Aktivite No",
        "Activity No",
        "Etkinlik No",
        "Event No",
        "Activity Number",
        "Etkinlik Numarası",
        "Aktivite Numarası"
    ],
    campusCode: [
        "Kampüs Kodu",
        "Campus Code",
        "Kodu",
        "Kod",
        "Code",
    ],
    courseCode: [
        "Ders Kodu",
        "Course Code",
        "Kod",
        "Code",
        "Lesson Code",
        "Eğitim Kodu",
    ],
    courseId: [
        "Ders No",
        'DERS ID',
        "Course ID",
        "ID",
        "Lesson ID",
        "Eğitim ID",
    ],
    examDuration: [
        'SINAV SÜRESİ (SLOT SAYISI)',
        "Exam Duration (Slot Count)",
        "Exam Duration",
        "Sınav Süresi",
    ],
    invigilatorGapAfterExam: [
        'GÖZETMENLİK SONRASI BOŞLUK SAYISI (SLOT SAYISI)',
        'GÖZETMENLİK SONRASI BOŞLUK SAYISI',
        'Gözetmenlik Sonrası Boşluk Süresi (Slot Sayısı)',
        'Gözetmenlik Sonrası Boşluk Süresi',
        "Invigilator Gap After Exam (Slot Count)",
        "Invigilator Gap After Exam",
    ],
    invigilatorGapBeforeExam: [
        'GÖZETMENLİK ÖNCESİ BOŞLUK SAYISI (SLOT SAYISI)',
        'Gözetmenlik Öncesi Boşluk Sayisi',
        'Gözetmenlik Öncesi Boşluk Süresi (Slot Sayısı)',
        'Gözetmenlik Öncesi Boşluk Süresi',
        "Invigilator Gap Before Exam (Slot Count)",
        "Invigilator Gap Before Exam",
    ],
    classroomCombineStatus: [
        'Derslik Birleşiklik Durumu',
        "Classroom Merged Status",
        "Merged Status",
        "Classroom Merged",
        "Derslik Birleşiklik",
        "Birleşiklik Durumu",
    ],
    examType: [
        'Sınav Türü',
        "Exam Type",
        "Type of Exam",
        "Exam Mode",
        "Sınav Modu",
    ],
    examEnvironment: [
        'Sınav Ortamı',
        "Exam Environment",
        "Environment of Exam",
        "Sınav Ortamı",
    ],
    combinedExamCode: [
        'BİRLEŞTİRİLMİŞ DERS KODU',
        'Birleştirilmiş Sınav Kodu',
        'Birleşik Sınav Kodu',
        "Combined Exam Code",
        "Exam Code",
        "Sınav Kodu",
        "Birleşik Sınav",
    ],
    examSession: [
        'OTURUM SAYISI',
        'Session Count',
        'Session Number',
        'Session',
        'Oturum Sayısı',
    ],
    assistantStaffCodes: [
        'DERSİN ASİSTANININ ÖĞRETİM ÜYESİ KODU',
        'DERSİN ASİSTANININ ÖĞRETİM ÜYESİ KOD',
        'Assistant Instructor Code',
        'Assistant Staff Code',
    ],
    conjugateCourseCode: [
        'Eşlenik Ders Kodu',
        'Eşlenik Ders Kod',
        "Conjugate Course Code",
        "Eşlenik Kod",
        "Conjugate Code",
        "Conjugate Lesson Code",
        "Eşlenik Ders Kodu",
    ],
    courseName: [
        "Ders Adı",
        "Course Name",
        "Ders İsmi",
        "Course Title",
        "Name of Course",
        "Name",
        "Adı",
        "Lesson Name",
        "Lesson Title"
    ],
    activityType: [
        "Aktivite Türü",
        "Activity Type",
        "Etkinlik Türü",
        "Event Type",
        "Type of Activity",
        "Lesson Type",
        "Eğitim Türü"
    ],
    lessonCount: [
        "Ders Sayısı",
        "Course Count",
        "Lesson Count",
        "Number of Lessons",
        "Lesson Total",
        "Toplam Ders",
        "Lesson Quantity",
        "Ders Miktarı"
    ],
    lectureLocationCode: [
        "Dersin Yeri",
        "Course Location",
        "Lecture Location",
        "Derslik Kodu",
        "Classroom Code",
        "Location",
        "Yer",
        "Room Code",
        "Lecture Place"
    ],
    educationType: [
        "Öğretim Türü",
        "Öğretim Tipi",
        "Type of Education",
        "Education Type",
        "Eğitim Türü",
        "Mode of Study",
        "Study Type",
        "Lesson Type"
    ],
    facultyCode: [
        "Fakülte/Yüksekokul Kodu",
        "Fakülte/Yüksekokul/Enstitü Kodu",
        'FAKÜLTE/ENSTİTÜ/YÜKSEKOKUL KODU',
        "Faculty/College/Institute Code",
        "Fakülte Kodu",
        "Faculty Code",
        "Institute Code",
        "Department Faculty Code",
        "Enstitü Kodu",
        "Yüksekokul Kodu"
    ],
    programCode: [
        "Bölüm/Program Kodu",
        "Bölüm/Program Kodları",
        "Department/Program Code",
        "Department/Program Codes",
        "Department Code",
        "Department Codes",
        "Program Kodu",
        "Program Kodları",
        "Lesson Program Code",
        "Lesson Program Codes",
    ],
    programCodes: [
        "Bölüm/Program Kodu",
        "Bölüm/Program Kodları",
        "Department/Program Code",
        "Department/Program Codes",
        "Department Code",
        "Department Codes",
        "Program Kodu",
        "Program Kodları",
        "Lesson Program Code",
        "Lesson Program Codes",
    ],
    class: [
        "Sınıf",
        "Sınıf Düzeyi",
        "Class",
        "Grade",
        "Class Level",
        "Education Level",
        "Lesson Class",
        "Classroom",
        "Grade Level"
    ],
    section: [
        "ŞUBE",
        "Branch",
        "Section Code",
        "Subsection",
        "Group",
        "Section",
        "Grup"
    ],
    lectureStaffCodes: [
        'ÖĞRETİM ELEMANI KODU',
        "Dersin Öğretim Üyesi Kodu",
        "Sınavın Öğretim Üyesi Kodu",
        "Öğretim Elemanları Kodu",
        "Öğretim Elemanları Kodları",
        "Instructor Code",
        "Instructor Codes",
        "Staff Code",
        "Staff Codes",
        "Eğitmen Kodu",
        "Eğitmen Kodları",
        "Lecture Staff Code",
        "Lecture Staff Codes",
    ],
    studentCount: [
        "Öğrenci Sayısı",
        'SINAVA GİRECEK ÖĞRENCİ SAYILARI',
        'Sınava Girecek Öğrenci Sayısı',
        'Derse Girecek Öğrenci Sayısı',
        'Derse Girecek Öğrenci Sayıları',
        "Number of Students",
        "Student Count",
        "Total Students",
        "Students",
        "Lesson Students",
        "Toplam Öğrenci"
    ],
    week: [
        "Hafta",
        "Haftalar",
        "Week",
        "Weeks",
        "Hafta Numarası",
        "Week Number",
        "Hafta Numaraları",
        "Week Numbers",
        "Eğitim Haftası"
    ],
    courseType: [
        "Ders Türü",
        "Course Type",
        "Lesson Type",
        "Education Type",
        "Type of Course",
        "Type of Lesson"
    ],
    courseEnvironment: [
        "Ders Ortamı",
        "Course Environment",
        "Learning Environment",
        "Education Environment",
        "Classroom Environment",
        "Lesson Environment",
        "Eğitim Ortamı"
    ],
    classroomTypeName: [
        "Derslik Tipi",
        "Classroom Type",
        "Room Type",
        "Type of Classroom",
        "Type of Room",
        "Derslik Türü",
        "Class Type"
    ],
    courseLanguageName: [
        "Ders Dili",
        "Course Language",
        "Language of Course",
        "Language",
        "Education Language",
        "Lesson Language",
        "Dil",
        "Eğitim Dili"
    ],
    packageName: [
        "Paket Adı",
        "Package Name",
        "Course Package",
        "Package Title",
        "Bundle Name",
        "Bundle Title",
        "Ders Paketi"
    ],
    description: [
        "Açıklama",
        "Description",
        "Details",
        "Info",
        "Remarks",
        "Notes",
        "Lesson Description",
        "Ders Açıklaması"
    ],
    status: [
        "Aktiflik",
        "Active Status",
        "Status",
        "State",
        "Activation",
        "Lesson State",
        "Durum"
    ],
    courseOpenedId: [
        "Ders Açılan Id",
        "Course Offered ID",
        "Course Opened ID",
        "Offered Course ID",
        "Opened Course ID",
        "Offered ID",
        "Opened ID",
        "Course Opening ID",
        "Lesson Offered ID"
    ]
};


export const planningCourseHoursExcelMapping = {
    courseId: [
        "DERS ID",
        'AKTİVİTE NUMARASI',
        'AKTİVİTE Id',
        'AKTİVİTE',
        'AKTİVİTE No',
        'Activity',
        'Activity No',
        'Activity Number',
        'Activity Id',
        "Course ID",
        "Lesson ID",
        "ID",
        "Ders Identifier",
        "Course Identifier"
    ],
    date: [
        "TARİH",
        "Date",
        "Tarih Bilgisi",
        "Lesson Date",
        "Schedule Date",
        "Planning Date",
        "Plan Tarihi"
    ],
    day: [
        "GÜN",
        "Day",
        "Hafta Günü",
        "Gün Adı",
        "Lesson Day",
        "Weekday",
        "Hafta Gün"
    ],
    startHour: [
        "BAŞLANGIÇ SAATİ",
        "Start Hour",
        "Başlangıç Zamanı",
        "Start Time",
        "Lesson Start Time",
        "Başlama Saati"
    ],
    endHour: [
        "BİTİŞ SAATİ",
        "End Hour",
        "Bitiş Zamanı",
        "End Time",
        "Lesson End Time",
        "Bitiş Saati"
    ]
};


//#region Program
export const programExcelMapping = {
    programCode: [
        "Program Kodu",
        "Program Code",
        "Kod",
        "Code",
        "Program Kod"
    ],
    programName: [
        "Program Adı",
        "Program Name",
        "Adı",
        "Name",
        "Program Title",
        "Title",
        "Program Başlığı"
    ],
    facultyCode: [
        "Fakülte Kodu",
        "Faculty Code",
        "Fakülte/Enstitü/Yüksekokul Kodu",
        "Faculty/Institute/College Code",
        "Fakülte Kod",
        "Department Code"
    ],
    educationType: [
        "Öğretim Türü",
        "Type of Education",
        "Education Type",
        "Eğitim Türü",
        "Mode of Study",
        "Study Type",
        "Lesson Type"
    ],
    class: [
        "Sınıf",
        "Class",
        "Grade",
        "Class Level",
        "Grade Level",
        "Education Level",
        "Lesson Class"
    ],
    DailyMaxClass: [
        "Günlük Maks. Ders",
        "Daily Max. Course",
        "Max Daily Lessons",
        "Daily Max Lessons",
        "Günlük Ders Limiti",
        "Maximum Daily Classes"
    ],
    maxGapBetweenCourses: [
        "Dersler Arası Maks. Boşluk",
        "Max. Gap Between Courses",
        "Course Gap",
        "Max Gap",
        "Ders Arası Boşluk",
        "Maximum Gap Between Courses"
    ],
    MinClassIntervalBwCampuses: [
        "Kampüsler Arası Min. Boşluk",
        "Min. Gap Between Campuses",
        "Campus Gap",
        "Min Campus Interval",
        "Minimum Interval Between Campuses",
        "Kampüsler Arası Boşluk"
    ],
    WeeklyMaxCourseDays: [
        "Haftalık Maks. Ders Günü",
        "Haftalık Maks. Ders Günü Sayısı",
        "Haftalık Maksimum Ders Günü",
        "Haftalık Maksimum Ders Günü Sayısı",
        "Haftalık En Fazla Ders Günü",
        "Haftalık En Fazla Ders Günü Sayısı",
        "Weekly Max. Course Days",
        "Weekly Max. Course Day",
        "Weekly Max. Course Days Count",
        "Weekly Max. Course Day Count",
        "Weekly Maximum Course Days",
        "Weekly Maximum Course Day",
        "Weekly Maximum Course Days Count",
        "Weekly Maximum Course Day Count",
        "Number of Weekly Max. Course Days",
        "Number of Weekly Max. Course Day",
        "Number of Weekly Maximum Course Days",
        "Number of Weekly Maximum Course Day"
    ],
    description: [
        "Açıklama",
        "Description",
        "Details",
        "Info",
        "Program Description",
        "Program Details",
        "Bilgi",
        "Detay"
    ]
};

// #region Classroom
export const classroomExcelMapping = {
    classroomCode: [
        "DERSLİK KODU",
        "CLASSROOM CODE",
        "DERSLİK/ODA KODU",
        "Kod",
        "Code",
        "Room Code",
        "Sınıf Kodu",
        "Kodu"
    ],
    classroomName: [
        "DERSLİK ADI",
        "CLASSROOM NAME",
        "DERSLİK/ODA ADI",
        "Adı",
        "Name",
        "Room Name",
        "Room Title",
        "Classroom Title",
        "Derslik İsmi",
        "Derslik Başlığı"
    ],
    buildingCode: [
        "BİNA KODU",
        "BUILDING CODE",
        "Kod",
        "Code",
        "Bina Kodu"
    ],
    buildingFloor: [
        "KAT",
        "FLOOR",
        "Floor Number",
        "Kat No",
        "Kat Numarası",
        "Level"
    ],
    doorOrder: [
        "SIRA",
        "ORDER",
        "Kapı Sırası",
        "Door Order",
        "Door Sequence",
        "Sıra No"
    ],
    lectureCapacity: [
        "DERS KAPASİTESİ",
        "CLASS CAPACITY",
        "Derslik Kapasitesi",
        "Lecture Capacity",
        "Class Capacity",
        "Capacity"
    ],
    examCapacity: [
        "SINAV KAPASİTESİ",
        "EXAM CAPACITY",
        "Sınav Kapasitesi",
        "Exam Room Capacity",
        "Exam Capacity",
        "Capacity for Exams"
    ],
    classroomType: [
        "DERSLİK TİPİ",
        "CLASSROOM TYPE",
        "Room Type",
        "Type of Classroom",
        "Class Type",
        "Classroom Category",
        "Derslik Türü"
    ],
    classroomFeatures: [
        "ÖZELLİKLER",
        "FEATURES",
        "Classroom Features",
        "Room Features",
        "Equipment",
        "Facilities",
        "Özellik"
    ],
    description: [
        "Açıklama",
        "Description",
        "AÇIKLAMA",
        "Details",
        "Info",
        "Notes",
        "Room Description",
        "Classroom Description",
        "Detay"
    ],
    invigilatorCount: [
        "GÖZETMEN SAYISI",
        "Gözetmen Sayısı",
        "Number of Invigilators",
        "Invigilator Count",
        "Supervisors Count",
        "Supervision Staff Count",
        "Gözetmen Numarası",
        "Gözetmen Adedi",
        "Gözetim Personel Sayısı"
    ],
    classroomCategory: [
        'KATEGORİ',
        'CATEGORY',
        'Classroom Category',
        'Room Category',
        'Derslik Kategorisi',
        'Oda Kategorisi',
    ],
    classroomAttribute: [
        'Nitelik',
        'ATTRIBUTE',
        'Classroom Attribute',
        'Room Attribute',
        'Derslik Niteliği',
        'Oda Niteliği',
    ],
    classroomUsageState: [
        'KULLANIM DURUMU',
        'USAGE STATE',
        'Classroom Usage State',
        'Room Usage State',
        'Derslik Kullanım Durumu',
        'Oda Kullanım Durumu',
    ],
    classroomSeatingType: [
        'OTURMA TİPİ',
        'SEATING TYPE',
        'Classroom Seating Type',
        'Room Seating Type',
        'Derslik Oturma Tipi',
        'Oda Oturma Tipi',
    ],
    classroomSeatingArrangement: [
        'OTURMA DÜZENİ',
        'SEATING ARRANGEMENT',
        'Classroom Seating Arrangement',
        'Room Seating Arrangement',
        'Derslik Oturma Düzeni',
        'Oda Oturma Düzeni',
    ],
    classroomUserType: [
        'KULLANICI TİPİ',
        'USER TYPE',
        'Classroom User Type',
        'Room User Type',
        'Derslik Kullanıcı Tipi',
        'Oda Kullanıcı Tipi',
    ],
    classroomSpecialUsingState: [
        'ÖZEL KULLANIM DURUMU',
        'SPECIAL USING STATE',
        'Classroom Special Using State',
        'Room Special Using State',
        'Derslik Özel Kullanım Durumu',
        'Oda Özel Kullanım Durumu',
    ],
    statusEventPlanning: [
        'ETKİNLİK PLANLAMADA KULLANIM',
        'ETKİNLİK PLANLAMA KULLANIM',
        'ETKİNLİK PLANLAMADA KULLANIM DURUMU',
        'ETKİNLİK PLANLAMA KULLANIM DURUMU',
        'USAGE IN EVENT PLANNING',
        'USAGE IN EVENT PLANNING STATUS',
        'STATUS OF USAGE IN EVENT PLANNING',
        'Event Planning Usage',
        'Status of Event Planning Usage',
    ],
    userCapacity: [
        'KİŞİ KAPASİTESİ',
        'Maksimum Kişi Kapasitesi',
        'USER CAPACITY',
        'PERSON CAPACITY',
        'Maximum Person Capacity',
    ],
    roomUser: [
        'ODA KULLANICISI',
        'ODA KULLANICILARI',
        'DERSLİK KULLANICISI',
        'DERSLİK KULLANICILARI',
        'ROOM USER',
        'ROOM USERS',
        'CLASSROOM USER',
        'CLASSROOM USERS',
    ],
    area: [
        'ALAN',
        'AREA',
        'Classroom Area',
        'Room Area',
        'Derslik Alanı',
        'Oda Alanı',
    ],
    volume: [
        'HACİM',
        'VOLUME',
        'Classroom Volume',
        'Room Volume',
        'Derslik Hacmi',
        'Oda Hacmi',
    ],
    countWindows: [
        'PENCERE SAYISI',
        'COUNT WINDOWS',
        'Classroom Count Windows',
        'Room Count Windows',
        'Derslik Pencere Sayısı',
        'Oda Pencere Sayısı',
    ],
    statusFurnished: [
        'MOBİLYA DURUMU',
        'FURNISHED STATUS',
        'Classroom Furnished Status',
        'Room Furnished Status',
        'Derslik Mobilya Durumu',
        'Oda Mobilya Durumu',
    ],
    statusSinkWater: [
        'LAVABO/SU DURUMU',
        'SINK/WATER STATUS',
        'Classroom Sink/Water Status',
        'Room Sink/Water Status',
        'Derslik Lavabo/Su Durumu',
        'Oda Lavabo/Su Durumu',
    ],
    statusHeating: [
        'ISITMA DURUMU',
        'HEATING STATUS',
        'Classroom Heating Status',
        'Room Heating Status',
        'Derslik Isıtma Durumu',
        'Oda Isıtma Durumu',
    ]
};



export const planningClassroomHoursExcelMapping = {
    classroomCode: [
        "DERSLİK KODU",
        "Classroom Code",
        "Room Code",
        "Kod",
        "Code",
        "Sınıf Kodu"
    ],
    date: [
        "TARİH",
        "Date",
        "Lesson Date",
        "Planning Date",
        "Schedule Date",
        "Plan Tarihi",
        "Tarih Bilgisi",
        "Exam Date",
        "Eğitim Tarihi"
    ],
    day: [
        "GÜN",
        "Day",
        "Hafta Günü",
        "Weekday",
        "Day Name",
        "Gün Adı",
        "Hafta Gün"
    ],
    startHour: [
        "BAŞLANGIÇ SAATİ",
        "Start Hour",
        "Start Time",
        "Başlangıç Zamanı",
        "Lesson Start Time",
        "Başlama Saati",
        "Plan Start Hour"
    ],
    endHour: [
        "BİTİŞ SAATİ",
        "End Hour",
        "End Time",
        "Bitiş Zamanı",
        "Lesson End Time",
        "Bitiş Saati",
        "Plan End Hour"
    ]
};


//#region Student
export const studentExcelMapping = {
    personUniqueId: [
        "Benzersiz Numara",
        'TC Kimlik No',
        'TC',
        'TC No',
        'Kimlik Numarası',
        'Kimlik No',
        'Kimlik',
        "Unique Number",
        "Unique ID",
        "ID",
        "Öğrenci Benzersiz Numarası",
        "Student Unique Identifier"
    ],
    studentNumber: [
        "Öğrenci No",
        "Student Number",
        "Numara",
        "Öğrenci Numarası",
    ],
    name: [
        "Adı Soyadı",
        "Adı",
        "İsim Soyisim",
        "Ad Soyad",
        "Name Surname",
        "Full Name",
        "Name",
        "Student Name"
    ],
    enrolledProgramCode: [
        "Kayıtlı Olduğu Program Kodu",
        "Kayıtlı Olduğu Program Kodu",
        "Enrolled Program Code",
        "Program Code",
        "Program Kodu"
    ],
    class: [
        "Dersin Sınıfı",
        "Class",
        "Grade",
        "Lesson Class",
        "Sınıf",
        "Education Level",
        "Class Level"
    ],
    courseCode: [
        "Ders Kodu",
        "Ders Kod",
        "Course Code",
        "Kod",
        "Code",
        "Lesson Code",
    ],
    group: [
        "Ders Şube",
        "Ders Şubesi",
        "Group",
        "Branch",
        "Section",
        "Şube",
        "Class Group"
    ],
    courseProgramCode: [
        "Dersi Aldığı Program Kodu",
        "Course Program Code",
        "Program Kodu",
        "Lesson Program Code",
    ],
    courseFacultyCode: [
        "Dersi Aldığı Fakülte Kodu",
        "Course Faculty Code",
        "Faculty Code",
        "Fakülte Kodu",
    ],
    description: [
        "Açıklama",
        "Description",
        "Details",
        "Info",
        "Notes",
        "Student Description",
        "Detay"
    ]
};


//#region User
export const userExcelMapping = {
    userName: [
        "Kullanıcı Adı",
        "User Name",
        "Username",
        "Kullanıcı İsmi",
        "Kullanıcı"
    ],
    facultyCode: [
        "Fakülte Kodu",
        "Faculty Code",
        "Department Code",
    ],
    programCode: [
        "Program Kodu",
        "Program Code",
        "Department Code",
        "Lesson Program Code",
    ],
    classroomCode: [
        "Kullandığı Derslik/Oda Kodu",
        "Classroom Code",
        "Room Code",
        "Used Classroom Code",
        "Used Room Code",
        "Kullanılan Derslik"
    ],
    responsibleClassroomCode: [
        "Sorumlu Olduğu Derslik/Oda Kodu",
        "Responsible Classroom Code",
        "Responsible Room Code",
        "Sorumlu Derslik",
        "Assigned Classroom Code"
    ]
};

export const userStaffExcelMapping = {
    name: [
        "Ad",
        "Adı",
        "İsim",
        "İsmi",
        "Name"
    ],
    surname: [
        "Soyad",
        "Soyadı",
        "Surname"
    ],
    userName: [
        "Kullanıcı Adı",
        "User Name",
        "Username",
        "Kullanıcı İsmi",
        "Kullanıcı"
    ],
    password: [
        "Şifre",
        "Password"
    ],
    email: [
        "Eposta",
        "Eposta Adresi",
        "E-posta",
        "E-posta Adresi",
        "Email",
        "Email Address",
        "E-mail",
        "E-mail Address",
    ],
    responsibleClassroomCode: [
        "Sorumlu Olduğu Derslik/Oda Kodu",
        "Sorumlu Olduğu Derslik Kodu",
        "Sorumlu Olduğu Oda Kodu",
        "Sorumlu Olduğu Derslik",
        "Sorumlu Olduğu Oda ",
        "Responsible Classroom Code",
        "Responsible Room Code",
        "Responsible Classroom",
        "Responsible Room"
    ],
    usedClassroomCode: [
        "Kullandığı Derslik/Oda Kodu",
        "Kullandığı Derslik Kodu",
        "Kullandığı Oda Kodu",
        "Kullandığı Derslik",
        "Kullandığı Oda",
        "Used Classroom Code",
        "Used Room Code",
        "Used Classroom",
        "Used Room"
    ],
    positionTitle: [
        "Görevi",
        "Görev",
        "Pozisyon",
        "Pozisyonu",
        "Position",
        "Title",
        "Position Title"
    ],
    staffType: [
        "Kadro",
        "Kadro Tipi",
        "Kadro Türü",
        "Staff Type",
        "Job Title"
    ]
};
