import React from 'react';
import { connect } from 'react-redux';

import MainLayout from '../layouts/main-layout';
import APlanHeader from '../../components/templates/aplan-header';
import EventCalendarPure, { EventCalendarProps } from './event-calendar-pure';

const EventCalendarIn: React.FC<EventCalendarProps> = (props) => {
    return (
        <MainLayout header={<APlanHeader />}>
            <EventCalendarPure {...props} />
        </MainLayout>
    )
}

const dispatchProps = (dispatch: any) => ({
    dispatch
});

const EventCalendar = connect(undefined, dispatchProps)(EventCalendarIn);

export default EventCalendar