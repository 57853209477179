import { Formik, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import MultiSelectionTable from '../components/multi-selection-table';
import Translator from '../../../services/translate-factory';
import * as GT from '../../../tools/general-tools';
import {
  ActivityTermLectureLocations,
  ActivityTypes,
  EducationTypeSelectOptions,
  ActivityHoursSearchInitialValues
} from '../../../store/constants/activity-const';

const T = Translator.create();
const L = Log.create('CourseDublicationModal');

function getInitialState(): Types.IDublicationModalState {
  const initialValues: Types.IDublicationModalState = {
    dublicate_list: [],
    lesson_count_validation: false,
    save_validation: false,
    lesson_count_sum: 0
  };
  return Object.assign({}, initialValues);
}

class CourseDublicationModal extends Component<any, Types.IDublicationModalState> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.createDefaultDatas();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onFormSave = () => {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (status === 200 || status === 201) {
        this.setClose(true);
      }
    };
    let model = {
      dublicate_list: this.state.dublicate_list,
      activity_no: this.props.courseInfo.activity_no
    }
    this.props.dispatch(
      Actions.ApiRequest(Constants.activity.ACTIVITY_DUBLICATION, model, 'activity-dublication-spin', resultCallback)
    );
  };

  createDefaultDatas = () => {
    let fields: Types.IActivityItem = {
      activity_type: 0,
      lesson_count: 0,
      education_type: '',
      lecture_location_id: 0,
      lecture_location: undefined,
      instructor_ids: [],
      instructors: [],
      student_count: 0,
    };
    let courseInfo = this.props.courseInfo ? this.props.courseInfo : [];
    fields = {
      activity_type: courseInfo ? courseInfo.activity_type : '',
      lesson_count: 0,
      education_type: courseInfo ? courseInfo.education_type : '',
      lecture_location_id: courseInfo ? courseInfo.lecture_location_id : 0,
      lecture_location: courseInfo ? courseInfo.lecture_location : undefined,
      instructor_ids: courseInfo ? courseInfo.instructor_ids : [],
      instructors: courseInfo ? courseInfo.instructors : [],
      student_count: courseInfo ? courseInfo.student_count : 0
    };
    let fields1 = {
      activity_type: courseInfo ? courseInfo.activity_type : '',
      lesson_count: 0,
      education_type: courseInfo ? courseInfo.education_type : '',
      lecture_location_id: courseInfo ? courseInfo.lecture_location_id : 0,
      lecture_location: courseInfo ? courseInfo.lecture_location : undefined,
      instructor_ids: courseInfo ? courseInfo.instructor_ids : [],
      instructors: courseInfo ? courseInfo.instructors : [],
      student_count: courseInfo ? courseInfo.student_count : 0
    };
    this.state.save_validation = true;
    this.setState(this.state);
    this.state.dublicate_list.push(fields);
    this.state.dublicate_list.push(fields1);
  };

  addNewActvity = () => {
    let fields: Types.IActivityItem = {
      activity_type: 0,
      lesson_count: 0,
      education_type: '',
      lecture_location_id: 0,
      lecture_location: undefined,
      instructor_ids: [],
      instructors: [],
      student_count: 0,
    };
    let courseInfo = this.props.courseInfo ? this.props.courseInfo : [];
    fields = {
      activity_type: courseInfo ? courseInfo.activity_type : '',
      lesson_count: 0,
      education_type: courseInfo ? courseInfo.education_type : '',
      lecture_location_id: courseInfo ? courseInfo.lecture_location_id : 0,
      lecture_location: courseInfo ? courseInfo.lecture_location : undefined,
      instructor_ids: courseInfo ? courseInfo.instructor_ids : [],
      instructors: courseInfo ? courseInfo.instructors : [],
      student_count: courseInfo ? courseInfo.student_count : 0
    };
    this.state.dublicate_list.push(fields);
    this.setState(this.state);
  };

  disableSaveButton() {
    this.state.dublicate_list.forEach((item: any) => {
      if (item.lesson_count == 0)
        this.state.save_validation = true;
      else if (item.student_count == 0)
        this.state.save_validation = true;
      else if (item.instructors.length == 0)
        this.state.save_validation = true;
    });
  };

  static getDerivedStateFromProps(props: any, state: Types.IDublicationModalState) {
    let hasNewState: boolean = false;

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    this.disableSaveButton();
    const campusOptions = this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const CampusSelectOptions = [...ActivityTermLectureLocations(T), ...campusOptions].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));

    let activityTypes = ActivityTypes(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_activity_types && this.props.selectOptions.additional_activity_types.length > 0) {
      activityTypes = [...ActivityTypes(T), ...this.props.selectOptions.additional_activity_types].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '1700px', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_dublicate_activity')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <Formik
                initialValues={this.state}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                }}
              >
                {(props: FormikProps<any>) => {

                  return (
                    <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                      <thead>
                      </thead>
                      <tbody>
                        {this.state.dublicate_list.map((item: any, index: any) => {
                          const courseLocation = CampusSelectOptions.find((option) => option.value == item.lecture_location_id);
                          return (
                            <tr key={'status-history-detail-' + index}>
                              <td style={{ width: '5%', verticalAlign: 'center' }} className="text-center" data-label={T.t('gen_number')}>
                                {index + 1}
                              </td>
                              <td data-label={T.t('gen_details')}>
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_activity_type')}</label>
                                      <Select
                                        id='select_activity_type'
                                        className="react-select"
                                        isMulti={false}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={activityTypes}
                                        placeholder={T.t('gen_select_activity_type')}
                                        value={
                                          item.activity_type != undefined && item.activity_type != null
                                            ? activityTypes.find((option) => option.value == item.activity_type)
                                            : null
                                        }
                                        onChange={(option: any) => {
                                          let value = option && option.value;
                                          this.state.dublicate_list[index].activity_type = value;
                                          this.setState(this.state);
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_activity_type')}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_education_type')}</label>
                                      <Select
                                        id='select_education_type'
                                        className="react-select"
                                        isMulti={false}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={EducationTypeSelectOptions(T)}
                                        placeholder={T.t('gen_select_education_type')}
                                        value={
                                          item.education_type != undefined && item.education_type != null
                                            ? T.t_options(EducationTypeSelectOptions(T)).find((option) => option.value == item.education_type)
                                            : null
                                        }
                                        onChange={(option: any) => {
                                          let value = option && option.value;
                                          this.state.dublicate_list[index].education_type = value;
                                          this.setState(this.state);
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_lecture_location')}</label>
                                      <Select
                                        id='select_location'
                                        className="react-select"
                                        isMulti={false}
                                        filterOption={(option: any, query: any) =>
                                          option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={T.t_options(CampusSelectOptions)}
                                        placeholder={T.t('gen_select_location')}
                                        value={
                                          item.lecture_location ? item.lecture_location : courseLocation
                                            ? courseLocation
                                            : null
                                        }
                                        onChange={(option: any) => {
                                          this.state.dublicate_list[index].lecture_location = option;
                                          this.state.dublicate_list[index].lecture_location_id = option && option.value;
                                          this.setState(this.state);
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_instructors')}</label>
                                      <Select
                                        id='select_instructor'
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={false}
                                        options={
                                          this.props.selectOptions && this.props.selectOptions.instructors
                                            ? this.props.selectOptions.instructors
                                            : []
                                        }
                                        placeholder={T.t('gen_select_instructor')}
                                        value={item.instructors}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];

                                          if (list.length != 0) {
                                            this.state.save_validation = false;
                                          }
                                          this.state.dublicate_list[index].instructors = list;
                                          this.state.dublicate_list[index].instructor_ids = list.map((item) => item.value);
                                          this.setState(this.state);
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                      />
                                      {this.state.dublicate_list[index].instructors.length == 0 && (
                                        <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-1 form-input form-group with-icon mt-3">
                                    <input
                                      id="lesson_count"
                                      name="lesson_count"
                                      value={item.lesson_count}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        item.lesson_count = event.currentTarget.value;
                                        this.state.dublicate_list[index].lesson_count = Number(event.target.value);
                                        this.setState(this.state);

                                        this.state.lesson_count_sum = this.state.dublicate_list.map(item => item.lesson_count).reduce(function (a, b) {
                                          return a + b;
                                        });

                                        if (this.state.lesson_count_sum != this.props.courseInfo.lesson_count) {
                                          this.state.lesson_count_validation = true;
                                        } else {
                                          this.state.lesson_count_validation = false;
                                          if (item.lesson_count > 0) {
                                            this.state.save_validation = false;
                                          }
                                        }
                                      }}
                                      type="number"
                                    />
                                    <span className="highlight" />
                                    <span className="bar" />
                                    <label htmlFor="lesson_count">{T.t('gen_lesson_count')}</label>
                                    {this.state.dublicate_list[index].lesson_count == 0 && (
                                      <div className="error">{T.t('gen_must_be_greater_than_zero')}</div>
                                    )}
                                  </div>
                                  <div className="col-md-1 form-input form-group with-icon mt-3">
                                    <input
                                      id="student_count"
                                      name="student_count"
                                      value={item.student_count}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        item.student_count = event.currentTarget.value;
                                        this.state.dublicate_list[index].student_count = Number(event.target.value);
                                        if (Number(event.target.value) > 0) {
                                          this.state.save_validation = false;
                                        }
                                        this.setState(this.state);
                                      }}
                                      type="number"
                                    />
                                    <span className="highlight" />
                                    <span className="bar" />
                                    <label htmlFor="student_count">{T.t('gen_student_count')}</label>
                                    {this.state.dublicate_list[index].student_count == 0 && (
                                      <div className="error">{T.t('gen_must_be_greater_than_zero')}</div>
                                    )}
                                  </div>
                                  <hr />
                                </div >
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  );
                }}
              </Formik >
            </div>
            <div className="row">
              <div className="col-6">
                <button
                  id='button_add_activity'
                  type="button"
                  aria-label="alert-success"
                  data-dismiss="modal"
                  className="btn btn-blue ml-3 mr-3"
                  style={{ margin: '15px' }}
                  disabled={this.state.lesson_count_sum == this.props.courseInfo.lesson_count ? true : false
                    || this.state.dublicate_list.length >= this.props.courseInfo.lesson_count ? true : false}
                  onClick={this.addNewActvity}
                >
                  <i className="material-icons mr-2">add_box</i> {T.t('gen_add_new_activities')}
                </button>
              </div>
              <div className="col-6 text-right">
                {this.state.lesson_count_validation && (
                  <div className="error">{T.t('gen_lesson_count_not_equal').replaceAll('{0}', this.state.lesson_count_sum).replaceAll('{1}', this.props.courseInfo.lesson_count)}</div>
                )}
                <button
                  id='button_save'
                  type="button"
                  aria-label="alert-success"
                  data-dismiss="modal"
                  className="btn btn-green float-right ml-3 mr-3"
                  style={{ margin: '15px' }}
                  disabled={this.state.lesson_count_validation || this.state.save_validation}
                  onClick={this.onFormSave}
                >
                  <i className="material-icons mr-2">save</i> {T.t('gen_save_activities')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICoursePageProps): Types.ICoursePageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ICoursePageProps = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    selectOptions: store.state.select_options && store.state.select_options.activityPage,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseDublicationModal);

export default container;